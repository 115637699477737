import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DealState} from "../deal.state";
import {dealStoreFeatureKey, EMPTY_DEAL_COMPANY_RECORD, EMPTY_DEAL_FUND_RECORD, EMPTY_SELLER_POSITION} from "../deal.reducer";
import {DealFundRecord} from "../../models/deal-fund-record";
import {ScenarioType} from "../../../shared/model/scenario-type";
import {DateTime} from "luxon";
import {DealCashflowsUtil} from "../../utils/deal-cashflows-util";
import {EMPTY_TRACEABLE} from "../../../shared/model/traceable";
import {DealScenario} from "../../models/deal-scenario";
import {DealCompanyRecord} from "../../models/deal-company-record";
import {DealCompany} from "../../models/deal-company";
import {TraceableFormatPipe} from "../../../shared/pipes/traceable-format/traceable-format.pipe";

export const selectDealState = createFeatureSelector<DealState>(dealStoreFeatureKey);

export const selectDealForms = createSelector(
    selectDealState,
    (state) => state.dealForms
);

export const selectIsAnyDealFormInEditMode = createSelector(
    selectDealForms,
    (dealForms) => Object.values(dealForms).some((isEditable) => isEditable)
);

export const selectDeals = createSelector(
    selectDealState,
    (state) => state.deals.data
);

export const selectSelectedDeal = createSelector(
    selectDealState,
    (state) => state.selectedDeal.data
);

export const selectSelectedDealId = createSelector(
    selectSelectedDeal,
    (state) => state.id
);

export const selectSelectedDealName = createSelector(
    selectSelectedDeal,
    selectDealForms,
    (state, dealForms) => ({
        name: state.projectName,
        dealNameIsEditable: dealForms.dealNameIsEditable
    })
);

export const selectSelectedDealClosingDate = createSelector(
    selectSelectedDeal,
    (state) => state.closingDate
);

export const selectDealFunds = createSelector(
    selectDealState,
    (state) => state.dealFunds.data
);

export const selectDealFundsOverview = createSelector(
    selectDealFunds,
    selectDealForms,
    selectSelectedDeal,
    (dealFund, dealForms, deal) => {
        const tableColumns: string[] = [
            "fundName",
            "fundSize",
            "fundCurrency",
            "exchangeRate",
            "fundVintageYear",
            "fundEndYear",
            "reportDate",
            "closingDate",
            "sellerStake",
            "sellerCommitment",
            "sellerCommitmentDealCurrency",
            "buyerStake",
            "buyerCommitment",
            "discountToNav",
            "bidPriceBuyerStake",
            "navBuyerStake",
            "unfundedBuyerStake",
            "adjustedCommitment"
        ];
        const tableDatasource: DealFundRecord[] = [];
        dealFund.dealFundRecords.forEach(df => tableDatasource.push(df));

        const bidPriceAdjustmentRow = Object.assign({}, EMPTY_DEAL_FUND_RECORD);
        bidPriceAdjustmentRow.fundName = {...EMPTY_TRACEABLE, text: "Bid Price Adjustment"};
        bidPriceAdjustmentRow.bidPriceBuyerStake = deal.bidPriceAdjustment;
        tableDatasource.push(bidPriceAdjustmentRow);

        const totalRow = {
            ...EMPTY_DEAL_FUND_RECORD,
            fundName: {...EMPTY_DEAL_FUND_RECORD.fundName, text: "Total"},
            fundSize: dealFund.totalFundSize,
            sellerPosition: {
                ...EMPTY_SELLER_POSITION,
                sellerCommitment: dealFund.totalSellerCommitment,
                buyerCommitment: dealFund.totalBuyerCommitment
            },
            sellerCommitmentDealCurrency: dealFund.totalSellerCommitmentDealCurrency,
            discountToNav: dealFund.totalDiscountToNav,
            bidPriceBuyerStake: dealFund.totalBidPriceBuyerStake,
            navBuyerStake: dealFund.totalNavBuyerStake,
            unfundedBuyerStake: dealFund.totalUnfundedBuyerStake,
            adjustedCommitment: dealFund.totalAdjustedCommitment
        };
        tableDatasource.push(totalRow);

        return {
            tableDatasource,
            tableColumns,
            dealFundsIsEditable: dealForms.dealFundsIsEditable,
            sellerPositions: dealFund.dealFundRecords.map(df => df.sellerPosition)
        };
    }
);

export const selectDealCompanies = createSelector(
    selectDealState,
    (state) => state.dealCompanies.data
);

export const selectDealCompaniesOverview = createSelector(
    selectDealCompanies,
    (dealCompany) => {
        const tableColumns: string[] = [
            "company", "fundName", "country", "gpIndication", "investmentDate", "exitDateAssumption",
            "remainingCost", "gpNAV", "percentageOfTotalNAV", "selectedBid", "percentageOfTotalBid",
            "cashflowLow", "percentageOfTotalCfLow", "cashflowBase", "percentageOfTotalCfBase", "cashflowHigh", "percentageOfTotalCfHigh"
        ];
        const netCurrentAssetsColumns: string[] = [
            "netCurrentAssets", "empty", "empty", "empty", "empty", "empty",
            "empty", "netCurrentAssetsGpNAV", "empty", "empty", "empty", "netCurrentAssetsLow", "empty", "netCurrentAssetsBase", "empty", "netCurrentAssetsHigh", "empty"
        ];
        const gpCarryReserveColumns: string[] = [
            "gpCarryReserve", "empty", "empty", "empty", "empty", "empty",
            "empty", "gpCarryReserveGpNAV", "empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"
        ];
        const bidPriceAdjustmentColumns: string[] = [
            "bidPriceAdjustment", "empty", "empty", "empty", "empty", "empty",
            "empty", "empty", "empty", "bidPriceAdjustmentSelectedBid", "empty", "empty", "empty", "empty", "empty", "empty", "empty"
        ];
        const totalRowColumns: string[] = [
            "total", "empty", "empty", "empty", "empty", "empty",
            "remainingCost", "totalGpNAV", "empty", "totalSelectedBid", "empty", "totalCashflowLow", "empty", "totalCashflowBase", "empty", "totalCashflowHigh", "empty"
        ];

        const tableDatasource: DealCompanyRecord[] = [];
        dealCompany.dealCompanyRecords.forEach(dc => tableDatasource.push(dc));

        const netCurrentAssetsRow = Object.assign({}, EMPTY_DEAL_COMPANY_RECORD);
        const updatedNetCurrentAssetsRow = {
            ...netCurrentAssetsRow,
            company: {...netCurrentAssetsRow.company, text: "Net Current Assets"},
            gpNAV: dealCompany.totalNetCurrentAssets,
            cashflowLow: dealCompany.totalNetCurrentAssets,
            cashflowBase: dealCompany.totalNetCurrentAssets,
            cashflowHigh: dealCompany.totalNetCurrentAssets
        };

        const gpCarryReserveRow = Object.assign({}, EMPTY_DEAL_COMPANY_RECORD);
        const updatedGpCarryReserveRow = {
            ...gpCarryReserveRow,
            company: {...gpCarryReserveRow.company, text: "GP Carry Reserve"},
            gpNAV: dealCompany.totalGpCarryReserve,
        };

        const bidPriceAdjustmentRow = Object.assign({}, EMPTY_DEAL_COMPANY_RECORD);
        const updatedBidPriceAdjustmentRow = {
            ...bidPriceAdjustmentRow,
            company: {...bidPriceAdjustmentRow.company, text: "Bid Price Adjustment"},
            selectedBid: dealCompany.totalBidPriceAdjustments,
        };

        const totalRow = Object.assign({}, EMPTY_DEAL_COMPANY_RECORD);
        const updatedTotalRow = {
            ...totalRow,
            company: {...totalRow.company, text: "Total"},
            gpNAV: dealCompany.totalGpNAV,
            selectedBid: dealCompany.totalBid,
            remainingCost: dealCompany.totalRemainingCost,
            cashflowLow: dealCompany.totalExitCashflowsLow,
            cashflowBase: dealCompany.totalExitCashflowsBase,
            cashflowHigh: dealCompany.totalExitCashflowsHigh
        };

        return {
            tableDatasource: tableDatasource.sort((a, b) => (b.gpNAV.amount ?? 0) - (a.gpNAV.amount ?? 0)),
            tableColumns,
            netCurrentAssetsRow: updatedNetCurrentAssetsRow,
            netCurrentAssetsColumns,
            gpCarryReserveRow: updatedGpCarryReserveRow,
            gpCarryReserveColumns,
            bidPriceAdjustmentRow: updatedBidPriceAdjustmentRow,
            bidPriceAdjustmentColumns,
            totalRow: updatedTotalRow,
            totalRowColumns
        };
    }
);

export const selectDealCompaniesForTreemap = (traceableFormatPipe: TraceableFormatPipe) => createSelector(
    selectDealCompanies,
    (dealCompany: DealCompany) => dealCompany.dealCompanyRecords.map((record: DealCompanyRecord) => ({
        ...record,
        fundName: record.fundName.text,
        companyName: record.company.text,
        gpNAV: traceableFormatPipe.transform(record.gpNAV),
        percentageOfTotalNAV: traceableFormatPipe.transform(record.percentageOfTotalNAV),
        remainingCost: traceableFormatPipe.transform(record.remainingCost),
    }))
);

export const selectSelectedDealScenarios = createSelector(
    selectDealState,
    (state) => state.selectedDealScenarios.data
);

export const selectDealScenarioIdFromType = (scenarioType: ScenarioType) => createSelector(
    selectSelectedDealScenarios,
    (state) =>
        state.find(s => s.scenario.code === scenarioType)?.id
);

export const selectSelectedDealCashFlows = createSelector(
    selectDealState,
    (state) => state.selectedDealCashFlows
);

export const selectSelectedDealCashFlowsForScenarioType = (scenarioType: ScenarioType) => createSelector(
    selectSelectedDealCashFlows,
    (cashflows) => ({
        aggregatedDealCashFlows: cashflows.find(c => c.scenarioType === scenarioType)?.aggregatedDealCashFlows
    })
);

export const selectDealDetails = createSelector(
    selectDealState,
    selectDealForms,
    (state, forms) => ({
        deal: state.selectedDeal.data,
        dealFunds: state.dealFunds.data,
        dealDetailsIsEditable: forms.dealDetailsIsEditable
    })
);

export const selectDealCashflowsGraph = createSelector(
    selectSelectedDealCashFlowsForScenarioType("LOW"),
    selectSelectedDealCashFlowsForScenarioType("BASE"),
    selectSelectedDealCashFlowsForScenarioType("HIGH"),
    (cashflowsLow, cashflowsBase, cashflowsHigh) => ({
        labels: DealCashflowsUtil.getCumulativeGpGross(cashflowsBase).quarters.map(dateStr => DateTime.fromISO(dateStr).toFormat("MMM yyyy")),
        datasets: [
            {
                label: "Low case",
                data: DealCashflowsUtil.getCumulativeGpGross(cashflowsLow).cumulativeGpGross,
                borderWidth: 1,
                backgroundColor: "red",
                borderColor: "red"
            },
            {
                label: "Base case",
                data: DealCashflowsUtil.getCumulativeGpGross(cashflowsBase).cumulativeGpGross,
                borderWidth: 1,
                backgroundColor: "orange",
                borderColor: "orange"
            },
            {
                label: "High case",
                data: DealCashflowsUtil.getCumulativeGpGross(cashflowsHigh).cumulativeGpGross,
                borderWidth: 1,
                backgroundColor: "green",
                borderColor: "green"
            }
        ]
    })
);


export const selectLowScenarioDealCashflowsGraph = createSelector(
    selectSelectedDealCashFlowsForScenarioType("LOW"),
    (cashflows) => DealCashflowsUtil.createChartData(cashflows, "red")
);

export const selectBaseScenarioDealCashflowsGraph = createSelector(
    selectSelectedDealCashFlowsForScenarioType("BASE"),
    (cashflows) => DealCashflowsUtil.createChartData(cashflows, "orange")
);

export const selectHighScenarioDealCashflowsGraph = createSelector(
    selectSelectedDealCashFlowsForScenarioType("HIGH"),
    (cashflows) => DealCashflowsUtil.createChartData(cashflows, "green")
);

export const selectDealReturnSummary = createSelector(
    selectDealState,
    (state) => state.returnSummary.data
);

export const selectDealReturnSummaryTable = createSelector(
    selectDealReturnSummary,
    (dealReturnSummary) => {
        const tableColumns: string[] = [
            "case",
            "probability",
            "netPerformanceTvpi",
            "netPerformanceIrr",
            "grossPerformanceTvpi",
            "grossPerformanceIrr",
            "navUplift",
        ];
        const tableDatasource: DealScenario[] = [];

        const lowScenario = dealReturnSummary.scenarios.find(s => s.scenario.code === "LOW");
        const baseScenario = dealReturnSummary.scenarios.find(s => s.scenario.code === "BASE");
        const highScenario = dealReturnSummary.scenarios.find(s => s.scenario.code === "HIGH");

        if (!!lowScenario && !!baseScenario && !!highScenario) {
            tableDatasource.push(lowScenario, baseScenario, highScenario);
        }

        return {
            tableColumns,
            tableDatasource,
            cashflowWeightedCase: dealReturnSummary.dealCashflowWeightedCase
        };
    }
);
