/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of} from "rxjs";
import {SearchActions} from "./search.actions";
import {SearchService} from "../services/search.service";

export const performSearch = createEffect(
    (actions$ = inject(Actions), searchService = inject(SearchService)) => {
        return actions$.pipe(
            ofType(SearchActions.load),
            exhaustMap((action) => {
                const searchQuery = action.query;

                return searchService.search(searchQuery).pipe(
                    map((searchResults) => SearchActions.loaded({ searchResults })),
                    catchError((error: { message: string }) =>
                        of(SearchActions.loaderror({ errorMsg: error.message }))
                    )
                );
            })
        );
    },
    { functional: true }
);
