import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {LoginService} from "./login.service";
import {map} from "rxjs/operators";
import {AuthenticationService} from "./authentication.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {

    constructor(private readonly authService: AuthenticationService,
                private readonly loginService: LoginService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.authService.isAuthenticated) {
            return this.loginService.showLoginPage(state.url).pipe(map(() => false));
        }
        return of(true);
    }

}
