import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {Code} from "../model/code";

export const SharedCodeActions = createActionGroup({
    source: "Shared Actions",
    events: {
        loadCodeTables: emptyProps(),
        loadedCodeTables: props<{codeTables: Code[]}>(),
        loadedCodes: props<{codes: Code[]}>(),
        loadError: props<{ errorMsg: string }>()
    }
});
