<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Shareholder Structure as of
            <ng-container *ngIf="!editingDate; else editDate">
                {{reportDate | date:'mediumDate'}}
                <button mat-icon-button *ngIf="!datepickerDisabled" (click)="toggleEdit()" color="primary">
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-container>
            <ng-template #editDate>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="reportDate" (dateChange)="dateChanged($event)"/>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </ng-template>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <ng-container *ngIf="(shareholderStructure$ | async) as shareholderStructure">
            <mat-card-subtitle>
                <valumize-info-panel *ngIf="shareholderStructure.status === 'ERROR'" [appearance]="'ERROR'" [message]="shareholderStructure.errorMessage"></valumize-info-panel>
                <valumize-info-panel *ngIf="shareholderStructure.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Shareholders successfully saved'"></valumize-info-panel>
                <mat-progress-spinner *ngIf="shareholderStructure.status === 'LOADING'"></mat-progress-spinner>
                <valumize-info-panel *ngIf="calculateTotalStake() > 100" [appearance]="'ERROR'"
                                     [message]="'No more than 100% of the shares can be allocated in total'"></valumize-info-panel>
            </mat-card-subtitle>
            <form [formGroup]="shareholdersForm" *ngIf="shareholdersForm" style="flex-direction: column">
                <div formArrayName="shareholders">
                    <table mat-table [dataSource]="tableDataSource">
                        <ng-container matColumnDef="shareholderName">
                            <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                            <td mat-cell *matCellDef="let shareholder; let i = index" [formGroupName]="i">
                                <input matInput formControlName="shareholderName"
                                       *ngIf="(shareholderStructureIsEditable$ | async) && shareholder.get('source').value === 'MANUAL' ">
                                <ng-container *ngIf="(shareholderStructureIsEditable$ | async) === false || shareholder.get('source').value !== 'MANUAL'">
                                <span [style.font-weight]="shareholder.get('source').value !== 'MANUAL' ? 'bold' : 'none'">
                                    {{shareholder.get('shareholderName').value}}
                                </span>
                                </ng-container>
                            </td>
                            <td mat-footer-cell *matFooterCellDef> Adjustment</td>
                        </ng-container>

                        <ng-container matColumnDef="stake">
                            <th mat-header-cell *matHeaderCellDef> Stake</th>
                            <td mat-cell *matCellDef="let shareholder; let i = index" [formGroupName]="i">
                                <input matInput formControlName="stake" *ngIf="(shareholderStructureIsEditable$ | async) && shareholder.get('source').value === 'MANUAL' ">
                                <ng-container *ngIf="(shareholderStructureIsEditable$ | async) === false || shareholder.get('source').value !== 'MANUAL'">
                                    {{shareholder.get('stake').value}}%
                                </ng-container>
                            </td>
                            <td mat-footer-cell *matFooterCellDef>{{getStakeGap() | number:'1.0-2'}}%</td>
                        </ng-container>

                        <ng-container matColumnDef="progressBar">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let shareholder">
                                <mat-progress-bar mode="determinate" [value]="shareholder.get('stake').value "></mat-progress-bar>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let shareholder; let i = index">
                                <button mat-icon-button color="primary" *ngIf="(shareholderStructureIsEditable$ | async)  && shareholder.get('source').value === 'MANUAL' "
                                        (click)="removeShareholder(i)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [formGroupName]="i"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns" [class.hidden-footer]="getStakeGap() === 0"></tr>
                    </table>
                </div>
            </form>

            <button mat-raised-button color="primary" type="button" (click)="addShareholder()" *ngIf="(shareholderStructureIsEditable$ | async)">Add Shareholder</button>

        </ng-container>

        <mat-expansion-panel class="mat-expansion-panel">
            <mat-expansion-panel-header class="mat-expansion-panel-header">
                All shareholders
            </mat-expansion-panel-header>
            <mat-card-subtitle *ngIf="(shareholderStructureIsEditable$ | async)">
                Click + to copy a shareholder over to the current selected date
            </mat-card-subtitle>
            <ng-container *ngIf="allShareholders$ | async as allShareholders">
                <table mat-table [dataSource]="allShareholders">
                    <ng-container matColumnDef="shareholderName">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element" style="height: 48px;">{{element.shareholderName | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="stake">
                        <th mat-header-cell *matHeaderCellDef>Stake</th>
                        <td mat-cell *matCellDef="let element" style="height: 48px;">{{element.stake | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>Date</th>
                        <td mat-cell *matCellDef="let element" style="height: 48px;">{{element.date | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="add">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" style="height: 48px; padding: 0">
                            <button mat-icon-button *ngIf="(shareholderStructureIsEditable$ | async)" color="primary" (click)="copyShareholderToCurrentDate(element)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="['shareholderName', 'stake', 'date', 'add']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['shareholderName', 'stake', 'date','add'];"></tr>
                </table>
            </ng-container>
        </mat-expansion-panel>
    </mat-card-content>

    <mat-card-actions class="detail-card-actions">
        <button mat-icon-button
                color="primary"
                data-test="edit-button"
                (click)="editMode()"
                *ngIf="(shareholderStructureIsEditable$ | async) === false"
                [disabled]="!!(isEditDisabled$ | async)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="primary" data-test="save-button" (click)="save()" *ngIf="(shareholderStructureIsEditable$ | async)" [disabled]="calculateTotalStake() > 100">
            <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button color="primary" data-test="cancel-button" (click)="cancel()" *ngIf="(shareholderStructureIsEditable$ | async)">
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>
