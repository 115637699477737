import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FundInvestment} from "../models/fund-investement";
import {FundApiService} from "../generated-sources/api";
import {PartnershipInvestment} from "../models/partnership-investment";

@Injectable({
    providedIn: "root"
})
export class FundInvestmentService {

    constructor(private readonly fundApiService: FundApiService) {
    }

    public getInvestmentsForFundReport(fundId: number, fundReportId: number): Observable<FundInvestment[]> {
        return this.fundApiService.getInvestmentsForFundReport(fundId, fundReportId);
    }

    public updateFundInvestments(fundId: number, fundReportId: number, investments: FundInvestment[]): Observable<FundInvestment[]> {
        return this.fundApiService.updateFundInvestments(fundId, fundReportId, investments);
    }

    public getFundInvestment(fundId: number, fundReportId: number, investmentId: number): Observable<FundInvestment> {
        return this.fundApiService.getFundInvestment(fundId, fundReportId, investmentId);
    }

    public updateFundInvestment(fundId: number, fundReportId: number, investmentId: number, investment: FundInvestment): Observable<FundInvestment> {
        return this.fundApiService.updateFundInvestment(fundId, fundReportId, investmentId, investment);
    }

    public calcFundInvestment(fundId: number, fundReportId: number, investmentId: number, investment: FundInvestment): Observable<FundInvestment> {
        return this.fundApiService.calculateFundInvestment(fundId, fundReportId, investmentId, investment);
    }

    public getPartnershipInvestment(fundId: number, fundReportId: number, fundValuationId?: number): Observable<PartnershipInvestment> {
        return this.fundApiService.getPartnershipInvestments(fundId, fundReportId, fundValuationId);
    }
}
