import {Inject, Injectable, Optional} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Principal, PrincipalResponse} from "../../model/login/principal";
import {LocalStorageAccessTokenService} from "./local-storage-access-token.service";
import {AUTH_CONFIGURATION_TOKEN} from "../../model/login/auth-configuration-token";
import {AuthModuleConfiguration} from "../../model/login/auth-module-configuration";
import {DEFAULT_AUTH_CONFIGURATION} from "./auth-configuration.module";
import {ApiUtil} from "../../utils/api-util";

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {

    private readonly user = new BehaviorSubject<Principal | null>(null);
    private readonly config: AuthModuleConfiguration;

    constructor(private readonly http: HttpClient,
                private readonly accessTokenService: LocalStorageAccessTokenService,
                @Optional() @Inject(AUTH_CONFIGURATION_TOKEN) readonly authModuleConfiguration: AuthModuleConfiguration) {
        this.config = {...DEFAULT_AUTH_CONFIGURATION, ...authModuleConfiguration};
    }

    get currentUser(): Principal | null {
        return this.user.value;
    }

    get currentUser$(): Observable<Principal | null> {
        return this.user.asObservable();
    }

    get isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    verify(): Observable<any> {
        return new Observable((subscriber) => {
            if (!this.config.sendVerificationRequestWithoutAccessToken && !this.accessTokenService.getAccessToken()) {
                this.logout();
                subscriber.complete();
            } else {
                this.http.get<PrincipalResponse>(ApiUtil.toApiPath("principal")).subscribe({
                    next: res => {
                        this.user.next(Principal.fromPrincipalResponse(res));
                        subscriber.complete();
                    },
                    error: () => {
                        this.logout();
                        subscriber.complete();
                    }
                });
            }
        });
    }

    login(userName: string, password: string): Observable<void> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = new HttpHeaders({"Content-Type": "application/x-www-form-urlencoded"});
        const content = `username=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}`;

        this.accessTokenService.clearAccessToken();
        return this.http.post<PrincipalResponse>(ApiUtil.toApiPath("authentication"), content, {headers})
            .pipe(
                map((response: PrincipalResponse) => this.user.next(Principal.fromPrincipalResponse(response))),
                catchError((e) => {
                        this.logout();
                        throw e;
                    }
                )
            );
    }

    async logout(): Promise<void> {
        this.accessTokenService.clearAccessToken();
        this.user.next(null);
    }
}
