<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Financial History</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="(selectedAssetFinancialHistory$ | async) as assetHistory">
        <mat-card-subtitle>
            <valumize-info-panel *ngIf="assetHistory.status === 'ERROR'" [appearance]="'ERROR'" [message]="assetHistory.errorMessage"></valumize-info-panel>
            <valumize-info-panel *ngIf="assetHistory.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Financial History successfully saved'"></valumize-info-panel>
            <mat-progress-spinner *ngIf="assetHistory.status === 'LOADING'"></mat-progress-spinner>
        </mat-card-subtitle>

        <mat-card-content class="detail-card-content">
            <section class="table-container" tabindex="0" style="overflow-x: auto; overflow-y: hidden;">
                <div *ngIf="selectedAssetFinancialHistoryData.length === 0 && !isEditable" style="color: grey">
                    No financial history records available. Start editing to add a new one.
                </div>

                <div *ngIf="selectedAssetFinancialHistoryData.length > 0">
                    <mat-chip-listbox aria-label="Metrics selection" multiple>
                        <mat-chip-option *ngFor="let metric of metrics" (click)="toggleMetric(metric)" [selected]="isSelected(metric)">
                            {{metric.displayName}}
                        </mat-chip-option>
                    </mat-chip-listbox>

                    <table mat-table [dataSource]="fieldNames">
                        <ng-container matColumnDef="rowName" sticky>
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let fieldName">{{fieldDisplayNames[fieldName] || fieldName}}</td>
                        </ng-container>

                        <ng-container *ngFor="let record of selectedAssetFinancialHistoryData; let i = index" matColumnDef="{{record.period.keyDate}}{{record.period.type}}">
                            <th mat-header-cell *matHeaderCellDef>
                                <div *ngIf="!isEditable">{{record.period | traceableFormat}}</div>
                                <div *ngIf="isEditable" (click)="openPeriodSelectorDialog(ACTION_TYPE.Edit, i)" style="cursor: pointer; color: #4949CB;">
                                    {{getPeriodFromFormGroup(i) | traceableFormat}}
                                </div>
                            </th>

                            <td mat-cell *matCellDef="let fieldName">
                                <ng-container *ngIf="isFieldEditable(fieldName)">
                                    <ng-container *ngFor="let baselineRecord of baselineAssetFinancialHistoryData">
                                        <ng-container
                                            *ngIf="record.period.type === baselineRecord.period.type && record.period.keyDate === baselineRecord.period.keyDate && isDataset">
                                            <mat-chip class="chip"
                                                      (click)="overwriteValue(
                                                    isEditable,
                                                    getFormControl(record.period.keyDate, record.period.type, fieldName),
                                                    getFieldValue(baselineRecord.period.keyDate, baselineRecord.period.type, fieldName, true))"
                                                      [ngClass]="getChipColor(
                                                    getFormControl(record.period.keyDate, record.period.type, fieldName),
                                                    getFieldValue(baselineRecord.period.keyDate, baselineRecord.period.type, fieldName, true),
                                                    getFieldValue(record.period.keyDate, record.period.type, fieldName, false))">
                                                {{getFieldValue(baselineRecord.period.keyDate, baselineRecord.period.type, fieldName, true) | traceableFormat}}
                                            </mat-chip>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <span *ngIf="!isEditable">
                                    {{getFieldValue(record.period.keyDate, record.period.type, fieldName, false) | traceableFormat}}
                                </span>
                                <ng-container *ngIf="isEditable">
                                    <input *ngIf="isFieldEditable(fieldName)" class="input-field"
                                           [formControl]="getFormControl(record.period.keyDate, record.period.type, fieldName)"
                                           type="text"
                                           (change)="calc(record.period.keyDate, record.period.type, fieldName)">

                                    <span *ngIf="!isFieldEditable(fieldName)">
                                        {{getFieldValue(record.period.keyDate, record.period.type, fieldName, false) | traceableFormat}}
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let fieldName; columns: displayedColumns;"
                            [class.row-important]="styleRowImportant(fieldName) === 'row-important'"></tr>
                    </table>
                    <div class="info-left">(!) calculation of growth metrics from one period to another.</div>
                </div>

                <div class="add-record-button" *ngIf="isEditable">
                    <button (click)="openPeriodSelectorDialog(ACTION_TYPE.Add)" mat-raised-button color="primary">Add New Record</button>
                </div>
            </section>
        </mat-card-content>

        <mat-card-actions class="detail-card-actions">
            <div class="last-modified" data-test="modification-date">
                Last modified: {{ getLastModified() | timestamp }}
            </div>
            <button (click)="edit()"
                    *ngIf="!isEditable"
                    [disabled]="(isEditDisabled$ | async)!"
                    color="primary"
                    data-test="edit-button"
                    mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>

            <button (click)="save()"
                    *ngIf="isEditable"
                    color="primary"
                    data-test="save-button"
                    mat-icon-button>
                <mat-icon>save</mat-icon>
            </button>
            <button (click)="cancel()"
                    *ngIf="isEditable"
                    color="primary"
                    data-test="cancel-button"
                    mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-card-actions>
    </ng-container>
</mat-card>
