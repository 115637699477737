/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, concatMap, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {DealService} from "../../services/deal.service";
import {
    DealActions,
    DealAggregatedCashflowAction,
    DealCompanyActions,
    DealDetailsAction,
    DealFundActions,
    DealListActions,
    DealNameActions,
    DealReturnSummaryActions,
    DealScenarioActions,
    SellerActions,
    SellerPositionActions
} from "../deal.actions";
import {Store} from "@ngrx/store";
import {selectSelectedSeller} from "../seller/seller.selectors";
import {EMPTY_DEAL} from "../deal.reducer";
import {selectSelectedDeal} from "./deal.selectors";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";

export const initDealOverview = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealListActions.open),
            switchMap(() => of(
                DealActions.cleardealstate(),
                DealListActions.load()
            )));
    },
    {functional: true}
);

export const initDealDetailsPage = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealActions.open),
            switchMap((action) =>
                !!action.sellerId
                    ? of(DealActions.load({dealId: action.dealId}), SellerActions.load({sellerId: action.sellerId}))
                    : of(DealActions.cleardealstate(), DealActions.load({dealId: action.dealId}))
            ));
    },
    {functional: true}
);

export const loadDealList = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealListActions.load),
            exhaustMap(() =>
                dealService.getDeals().pipe(
                    map((deals) => DealListActions.loaded({deals})),
                    catchError((error: { message: string }) =>
                        of(DealListActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadDeal = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.load),
            exhaustMap((action) => {
                const dealId = action.dealId;
                return dealService.getDeal(dealId).pipe(
                    switchMap((dealWithSeller) => of(
                        DealActions.loaded({deal: dealWithSeller.deal}),
                        DealFundActions.load({dealId: action.dealId}),
                        DealCompanyActions.load({dealId: action.dealId}),
                        DealScenarioActions.loadall({dealId: action.dealId}),
                        !!dealWithSeller.seller
                            ? SellerActions.loaded({seller: dealWithSeller.seller})
                            : SellerActions.loaderror({errorMsg: "Seller does not exist for deal with id = " + action.dealId}),
                        DealReturnSummaryActions.calc({dealId: action.dealId})
                    )),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const createDeal = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.create),
            exhaustMap((action) => {
                return dealService.save({
                        ...EMPTY_DEAL,
                        ...{
                            projectName: {
                                ...EMPTY_DEAL.projectName,
                                text: action.dealName
                            },
                            closingDate: {
                                ...EMPTY_DEAL.closingDate,
                                date: action.closingDate
                            },
                            currencyIso: {
                                ...EMPTY_DEAL.currencyIso,
                                code: action.currencyIso
                            }
                        }
                    }
                ).pipe(
                    map((deal) => DealActions.loaded({deal})),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveDealName = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealNameActions.save),
            withLatestFrom(store$.select(selectSelectedDeal), store$.select(selectSelectedSeller)),
            exhaustMap(([action, selectedDeal, selectedSeller]) => {
                return dealService.save({
                        ...selectedDeal,
                        ...{
                            projectName: {
                                ...selectedDeal.projectName,
                                text: action.dealName
                            }
                        }
                    },
                    selectedSeller.id
                ).pipe(
                    map((deal) => DealActions.loaded({deal})),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveSellerOnDeal = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.savesellerondeal),
            withLatestFrom(store$.select(selectSelectedDeal)),
            exhaustMap(([action, selectedDeal]) => {
                return dealService.save(
                    selectedDeal,
                    action.sellerId
                ).pipe(
                    map((deal) =>
                        !!deal.id
                            ? DealActions.load({dealId: deal.id})
                            : DealActions.loaderror({errorMsg: "Deal could not be loaded"})
                    ),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDealFunds = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealFundActions.load),
            exhaustMap((action) => {
                return dealService.getDealFunds(action.dealId).pipe(
                    map((dealFund) => DealFundActions.loaded({dealFund})),
                    catchError((error: { message: string }) =>
                        of(DealFundActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDealCompanies = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealCompanyActions.load),
            exhaustMap((action) => {
                return dealService.getDealCompanies(action.dealId).pipe(
                    map((dealCompany) => DealCompanyActions.loaded({dealCompany})),
                    catchError((error: { message: string }) =>
                        of(DealCompanyActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveDealFunds = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealFundActions.save),
            exhaustMap((action) => of(
                DealActions.savebidpriceadjustment({bidPriceAdjustment: action.bidPriceAdjustment}),
                SellerPositionActions.saveall({dealId: action.dealId, sellerPositions: action.sellerPositions})
            ))
        );
    },
    {functional: true}
);

export const saveBidPriceAdjustment = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.savebidpriceadjustment),
            withLatestFrom(store$.select(selectSelectedDeal)),
            exhaustMap(([action, selectedDeal]) => {
                const updatedDeal = {
                    ...selectedDeal,
                    bidPriceAdjustment: {
                        ...selectedDeal.bidPriceAdjustment,
                        amount: action.bidPriceAdjustment
                    }
                };
                return dealService.save(updatedDeal).pipe(
                    map((deal) => DealActions.loaded({deal})),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDealScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealScenarioActions.loadall),
            exhaustMap((action) => {
                if (!!action.dealId) {
                    return dealService.getScenariosForDeal(action.dealId).pipe(
                        map((scenarios) => DealScenarioActions.loadedall({scenarios})),
                        catchError((error: { message: string }) =>
                            of(DealScenarioActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(DealScenarioActions.loaderror({errorMsg: "Deal id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadDealAggregatedCashflow = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealAggregatedCashflowAction.load),
            concatMap((action) => {
                if (!!action.dealId) {
                    return dealService.getAggregatedDealCashflows(action.dealId, action.scenarioId).pipe(
                        map((aggregatedCashflows) =>
                            DealAggregatedCashflowAction.loaded({aggregatedCashFlows: aggregatedCashflows, scenarioType: action.scenarioType})
                        ),
                        catchError((error: any) =>
                            of(DealAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: error.error.expectedException?.message ?? error.message}))
                        )
                    );
                } else {
                    return of(DealAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: "Deal id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveDealDetails = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealDetailsAction.save),
            withLatestFrom(store$.select(selectSelectedSeller)),
            exhaustMap(([action, selectedSeller]) => {
                if (!!action.deal.id) {
                    const selectedDeal = action.deal;
                    return dealService.save({
                        ...selectedDeal,
                        ...{
                            secondaryCarry: {
                                ...selectedDeal.secondaryCarry,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryCarry ?? undefined)
                            },
                            secondaryHurdle: {
                                ...selectedDeal.secondaryHurdle,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryHurdle ?? undefined)
                            },
                            currencyIso: {
                                ...selectedDeal.currencyIso,
                                code: action.currencyIso
                            },
                            source: {
                                ...selectedDeal.source,
                                text: action.source ?? undefined
                            },
                            processType: {
                                ...selectedDeal.processType,
                                code: action.processType
                            },
                            dealCaptain: {
                                ...selectedDeal.dealCaptain,
                                text: action.dealCaptain ?? undefined
                            },
                        }
                    }, selectedSeller.id).pipe(
                        switchMap((deal) => of(
                            DealActions.loaded({deal}),
                            DealFundActions.load({dealId: deal.id as number})
                        )),
                        catchError((error: { message: string }) =>
                            of(DealActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(DealActions.loaderror({errorMsg: "Deal id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcDealReturnSummary = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealReturnSummaryActions.calc),
            exhaustMap((action) => {
                if (action.dealId === undefined) {
                    return of(DealReturnSummaryActions.calcerror({errorMsg: "Deal Id is required"}));
                }
                return dealService.calcDealReturnSummary(action.dealId).pipe(
                    map((returnSummary) => DealReturnSummaryActions.calcsuccess({returnSummary})),
                    catchError((error: { message: string }) =>
                        of(DealReturnSummaryActions.calcerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);
