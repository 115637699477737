/* eslint-disable no-underscore-dangle */
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from "@angular/core";
import {Chart, ChartType, registerables, Tooltip, TooltipPositionerMap} from "chart.js";
import {TreemapController, TreemapElement} from "chartjs-chart-treemap";
import {map, Observable, Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
    selector: "valumize-deals-companies-treemap",
    templateUrl: "./deals-companies-treemap.component.html",
    styleUrls: ["./deals-companies-treemap.component.scss"]
})
export class DealsCompaniesTreemapComponent implements AfterViewInit, OnDestroy {

    @ViewChild("dealsTreemap") dealsTreemap: ElementRef | undefined;
    @Input() groupedDealCompanies$!: Observable<any>;
    @Input() dealId?: number;

    subscriptions: Subscription[] = [];
    private chart: Chart | undefined;

    constructor(private readonly router: Router) {
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(
            this.groupedDealCompanies$.pipe(map(dealCompanies => {
                if (this.chart) {
                    this.chart.destroy();
                }
                if (dealCompanies.length > 0 && this.dealsTreemap) {
                    this.chart = this.createTreemap(dealCompanies, this.dealsTreemap.nativeElement);
                }
            })).subscribe());
    }

    createTreemap(data: any[], canvasElement: HTMLCanvasElement): Chart {
        Chart.register(Tooltip, TreemapController, TreemapElement, ...registerables);

        const config = {
            type: "treemap" as ChartType,
            data: {
                datasets: [{
                    tree: data,
                    key: "gpNAV",
                    groups: ["fundName", "companyName"],
                    borderWidth: 0,
                    borderRadius: 6,
                    spacing: 1,
                    backgroundColor: (item: any) => this.colorFromRaw(item),
                    labels: {
                        align: "center",
                        display: true,
                        formatter: (item: any) => {
                            const ctx = item.chart.ctx;
                            const fontSize = Math.min(Math.max(10, Math.sqrt(item.raw.a)), 20);
                            ctx.font = `${fontSize}px bold Arial`;
                            const textWidth = ctx.measureText(item.raw._data.companyName).width;

                            if (textWidth > item.raw.w) {
                                return ""; // Return empty string if text overflows
                            }
                            return item.raw._data.companyName;
                        },
                        color: ["black"],
                        font: (ctx: any) => {
                            const area = ctx.raw.a;
                            if (area > 20000) {
                                return {size: 16, weight: "bold"};
                            }
                            if (area > 10000) {
                                return {size: 14, weight: "bold"};
                            }
                            if (area > 5000) {
                                return {size: 12, weight: "bold"};
                            }
                            return {size: 10, weight: "bold"};
                        }
                    },
                    data: []
                }]
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        position: "average" as keyof TooltipPositionerMap,
                        callbacks: {
                            title: (tooltipItems: any) => {
                                const raw = tooltipItems[0].raw._data;
                                return raw.companyName ? raw.companyName : raw.fundName;
                            },
                            label: (item: any) => {
                                const obj = item.raw._data;
                                if (obj.companyName) {
                                    // Company level
                                    return [
                                        `${obj.companyName}`,
                                        `GP NAV: ${obj.gpNAV}`,
                                        `Remaining Cost: ${obj.children[0].remainingCost}`,
                                        `Percentage of Total NAV: ${obj.children[0].percentageOfTotalNAV}`
                                    ];
                                } else {
                                    // Fund level
                                    return [`Fund: ${obj.fundName}`, `GP NAV: ${obj.gpNAV.toFixed(1)}`];
                                }
                            }
                        }
                    }
                }
            }
        };

        const chart = new Chart(canvasElement, config);

        canvasElement.onclick = (event: MouseEvent) => {
            const elements = chart.getElementsAtEventForMode(event, "nearest", {intersect: true}, true);
            if (elements.length) {
                const element = elements[0] as any;
                if (element?.element?.$context?.raw._data) {
                    const assetData = element.element.$context.raw._data.children[0];
                    if (assetData) {
                        if (element.element.$context.raw._data.companyName) {
                            this.router.navigate(["../../assets/", assetData.assetId], {
                                queryParams: {
                                    fundId: assetData.fundId,
                                    fundReportId: assetData.fundReportId,
                                    fundValuationId: assetData.fundValuationId,
                                    fundInvestmentId: assetData.fundInvestmentId,
                                    assetValuationId: assetData.assetValuationId,
                                    dealId: this.dealId
                                }
                            });
                        } else {
                            this.router.navigate(["../../funds/", assetData.fundId], {
                                queryParams: {
                                    fundReportId: assetData.fundReportId,
                                    fundValuationId: assetData.fundValuationId,
                                    dealId: this.dealId
                                }
                            });
                        }
                    }
                }
            }
        };
        return chart;
    }

    private colorFromRaw(item: any): string {
        const colorArray = [
            "70, 130, 180", // Steel Blue
            "46, 139, 87", // Sea Green
            "255, 140, 0", // Dark Orange
            "138, 43, 226", // Blue Violet
            "32, 178, 170", // Light Sea Green
            "255, 105, 180", // Hot Pink
            "96, 125, 139", // Blue Grey
            "255, 205, 86", // Light Yellow
            "128, 0, 128" // Purple
        ];

        const companyArray: any = [];

        if (item.type !== "data") {
            return "transparent";
        }

        item.chart.data.datasets[0].tree.forEach((datastructures: { fundName: string }) => {
            companyArray.push(datastructures.fundName);
        });

        const uniqueCompanyArray = [...new Set(companyArray)];
        const indexCompany = uniqueCompanyArray.indexOf(item.raw._data.fundName) % colorArray.length;

        const value = item.raw._data.gpNAV; // Use gpNAV for calculating the alpha value
        let alpha = 0.1; // Default alpha for funds
        if (item.raw._data.companyName) {
            alpha = value <= 1 ? value : 1; // Scale alpha for companies
        }

        return `rgba(${colorArray[indexCompany]}, ${alpha})`;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
