<h2 mat-dialog-title>Add new Fund</h2>
<mat-dialog-content>
    <p>If the fund already exists, please select it.</p>
    <form [formGroup]="nameForm">
        <div>
            <mat-form-field appearance="fill">
                <input matInput
                       formControlName="fundName"
                       data-test="fund-name-input"
                       placeholder="Fund Name"
                       (input)="onSearch()">
            </mat-form-field>
        </div>
        <div class="name-actions">
            <button mat-icon-button mat-dialog-close color="primary" title="Add Fund" aria-label="Add Fund" data-test="save-button" [disabled]="nameForm.invalid"
                    (click)="createFund()" type="submit">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>
    <table mat-table [dataSource]="(filtereFund$ | async) || []">
        <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef>Fund</th>
            <td mat-cell *matCellDef="let element">{{element.name.text}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row mat-dialog-close
            *matRowDef="let row; columns: columns"
            [routerLink]="'funds/'+row.id"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close data-test="save-button-bottom" [disabled]="nameForm.invalid" (click)="createFund()">Add Fund</button>
    <button mat-stroked-button mat-dialog-close data-test="cancel-button">Cancel</button>
</mat-dialog-actions>
