import { AggregatedFundCashflowRecord } from "./aggregated-fund-cashflow-record";
import {ApiDataModel} from "../../shared/model/api-data-model";
import {ScenarioType} from "../../shared/model/scenario-type";


export interface AggregatedFundCashflows {
    totalAssetDistribution?: number;
    totalAssetDrawdown?: number;
    totalGpManagementFee?: number;
    totalGpUnfundedPayment?: number;
    totalGpUnfundedDistribution?: number;
    totalGpUnfundedNet?: number;
    totalGpCarryNet?: number;
    totalGpGross?: number;
    totalGpCarryPayments?: number;
    totalSecondaryCashflow?: number;
    totalSecondaryManagementFee?: number;
    totalSecondaryCarryNet?: number;
    totalSecondaryCarryPayment?: number;
    totalSecondaryNetCashflow?: number;
    records: Array<AggregatedFundCashflowRecord>;
}

export interface FundValuationScenarioCashFlows {
    scenarioType?: ScenarioType;
    aggregatedFundCashFlows: ApiDataModel<AggregatedFundCashflows>;
}

export const EMPTY_AGGREGATED_FUND_CASHFLOWS: AggregatedFundCashflows = {
    totalAssetDistribution: undefined,
    totalAssetDrawdown: undefined,
    totalGpManagementFee: undefined,
    totalGpUnfundedPayment: undefined,
    totalGpUnfundedDistribution: undefined,
    totalGpUnfundedNet: undefined,
    totalGpCarryNet: undefined,
    totalGpGross: undefined,
    totalSecondaryCashflow: undefined,
    totalSecondaryManagementFee: undefined,
    totalSecondaryCarryPayment: undefined,
    totalSecondaryNetCashflow: undefined,
    records: []
};

export const EMPTY_FUND_VALUATION_CASHFLOWS: FundValuationScenarioCashFlows[] = [
    {
        scenarioType: "LOW",
        aggregatedFundCashFlows: {
            data: EMPTY_AGGREGATED_FUND_CASHFLOWS,
            status: "INIT",
            errorMessage: ""
        }
    },
    {
        scenarioType: "BASE",
        aggregatedFundCashFlows: {
            data: EMPTY_AGGREGATED_FUND_CASHFLOWS,
            status: "INIT",
            errorMessage: ""
        }
    },
    {
        scenarioType: "HIGH",
        aggregatedFundCashFlows: {
            data: EMPTY_AGGREGATED_FUND_CASHFLOWS,
            status: "INIT",
            errorMessage: ""
        }
    }
];

export const TEST_DATA_AGGREGATED_FUND_CASHFLOWS: AggregatedFundCashflows = {
    totalAssetDistribution: 99000000,
    totalAssetDrawdown: 99000000,
    totalGpManagementFee: 990000,
    totalGpUnfundedPayment: 990000,
    totalGpUnfundedDistribution: 990000,
    totalGpUnfundedNet: 9900000,
    totalGpCarryNet: 9900000,
    totalGpGross: 9900000,
    totalGpCarryPayments: 9900000,
    totalSecondaryCashflow: 990000,
    totalSecondaryManagementFee: 990000,
    totalSecondaryCarryPayment: 990000,
    totalSecondaryNetCashflow: 9900000,
    records: [
        {
            date: "2024-01-01",
            fundAgeInQuarters: 1,
            assetDistribution: 11000000,
            assetDrawdown: 11000000,
            gpManagementFee: {
                amount: 110000,
                pattern: 0.11
            },
            gpUnfundedPayment: {
                amount: 110000,
                pattern: 0.11
            },
            gpUnfundedDistribution: {
                amount: 110000,
                pattern: 0.11
            },
            gpUnfundedNet: 1100000,
            purchasePrice: 1100000,
            gpGross: 1100000,
            gpCarryNet: 1100000,
            gpCarryPayment: {
                amount: 110000,
                hurdle: 1100
            },
            secondaryCashflow: 110000,
            secondaryManagementFee: {
                amount: 110000,
                pattern: 0.11
            },
            secondaryCarryNet: 110000,
            secondaryCarryPayment: {
                amount: 110000,
                hurdle: 1100
            },
            secondaryNetCashflow: 1100000,
            details: []
        },
        {
            date: "2024-01-01",
            fundAgeInQuarters: 2,
            assetDistribution: 22000000,
            assetDrawdown: 22000000,
            gpManagementFee: {
                amount: 220000,
                pattern: 0.22
            },
            gpUnfundedPayment: {
                amount: 220000,
                pattern: 0.22
            },
            gpUnfundedDistribution: {
                amount: 220000,
                pattern: 0.22
            },
            gpUnfundedNet: 2200000,
            purchasePrice: 2200000,
            gpGross: 2200000,
            gpCarryNet: 2200000,
            gpCarryPayment: {
                amount: 220000,
                hurdle: 2200
            },
            secondaryCashflow: 220000,
            secondaryManagementFee: {
                amount: 220000,
                pattern: 0.22
            },
            secondaryCarryNet: 220000,
            secondaryCarryPayment: {
                amount: 220000,
                hurdle: 2200
            },
            secondaryNetCashflow: 2200000,
            details: []
        },
        {
            date: "2024-01-01",
            fundAgeInQuarters: 3,
            assetDistribution: 33000000,
            assetDrawdown: 33000000,
            gpManagementFee: {
                amount: 330000,
                pattern: 0.33
            },
            gpUnfundedPayment: {
                amount: 330000,
                pattern: 0.33
            },
            gpUnfundedDistribution: {
                amount: 330000,
                pattern: 0.33
            },
            gpUnfundedNet: 3300000,
            purchasePrice: 3300000,
            gpGross: 3300000,
            gpCarryNet: 3300000,
            gpCarryPayment: {
                amount: 330000,
                hurdle: 3300
            },
            secondaryCashflow: 330000,
            secondaryManagementFee: {
                amount: 330000,
                pattern: 0.33
            },
            secondaryCarryNet: 330000,
            secondaryCarryPayment: {
                amount: 330000,
                hurdle: 3300
            },
            secondaryNetCashflow: 3300000,
            details: []
        }

    ]
};
