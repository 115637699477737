import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {FundInvestment} from "../models/fund-investement";
import {Fund} from "../models/fund";
import {FundValuation} from "../models/fund-valuation";
import {Note} from "../../shared/model/note";
import {FundReport} from "../models/fund-report";
import {FundValuationScenario} from "../models/fund-valuation-scenario";
import {PartnershipInvestment} from "../models/partnership-investment";
import {AggregatedFundCashflows} from "../models/aggregated-fund-cashflows";
import {ScenarioType} from "../../shared/model/scenario-type";
import {FundReturnSummary} from "../models/fund-return-summary";
import {TraceableCode} from "../../shared/model/traceable";

export const FundListActions = createActionGroup({
    source: "Fund List Action",
    events: {
        open: emptyProps(),
        load: emptyProps(),
        loadfordeal: emptyProps(),
        loaded: props<{ funds: Fund[] }>(),
        loaderror: props<{ errorMsg: string }>()
    }
});

export const FundActions = createActionGroup({
    source: "Fund Actions",
    events: {
        open: props<{ fundId: number; dealId: number | undefined }>(),
        create: props<{ fundName: string }>(),
        load: props<{ fundId: number }>(),
        loaded: props<{ fund: Fund }>(),
        loadBaseline: props<{ fundId: number }>(),
        loadedBaseline: props<{ fund: Fund }>(),
        savedLoaded: props<{ fund: Fund }>(),
        loaderror: props<{ errorMsg: string }>(),
        clearfundstate: emptyProps(),
        setMergeTargetId: props<{ mergeTargetId?: number }>()
    }
});

export const FundNameActions = createActionGroup({
    source: "Fund Name Action",
    events: {
        edit: emptyProps(),
        save: props<{ fundName: string }>(),
        cancel: emptyProps()
    }
});

export const FundDetailActions = createActionGroup({
    source: "Fund Detail Action",
    events: {
        edit: emptyProps(),
        save: props<{
            vintageYear: string | undefined;
            purchaseYear: string | undefined;
            endYear: string | undefined;
            managementFee: number | null;
            carry: number | null;
            hurdleRate: number | null;
            stage: string;
            managementFeeCalculation: string;
            regions: TraceableCode[];
            currencyIso: string;
            country: string;
            size: number | null;
            mainFundSize: number | null;
        }>(),
        cancel: emptyProps()
    }
});

export const FundReportActions = createActionGroup({
    source: "Fund Report Actions",
    events: {
        edit: emptyProps(),
        save: props<{
            fundId: number;
            fundReport: FundReport;
        }>(),
        cancel: emptyProps(),
        create: props<{ fundId: number; reportDate: string }>(),
        load: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loaded: props<{ fundReport: FundReport }>(),
        loadBaseline: props<{ fundId: number; fundReportId: number }>(),
        loadedBaseline: props<{ fundReport: FundReport }>(),
        loadAll: props<{ fundId: number; fundIsMergeTarget: boolean }>(),
        loadedAll: props<{ fundId: number; reports: FundReport[] }>(),
        loadedAllBaseline: props<{ reports: FundReport[] }>(),
        clearBaseline: emptyProps(),
        loaderror: props<{ errorMsg: string }>(),
        setMergeTargetId: props<{ fundId: number; mergeTargetId?: number }>()
    }
});


export const FundValuationActions = createActionGroup({
    source: "Fund Valuation Actions",
    events: {
        create: props<{
            fundId: number;
            fundReportId: number;
            reportDate: string;
            closingDate: string;
        }>(),
        load: props<{ fundId: number; fundReportId: number; fundValuationId: number }>(),
        savedloaded: props<{ fundId: number; fundValuation: FundValuation }>(),
        loaded: props<{ fundValuation: FundValuation }>(),
        loadAll: props<{ fundId: number }>(),
        loadedAll: props<{ fundId: number; valuations: FundValuation[] }>(),
        loaderror: props<{ errorMsg: string }>()
    }
});

export const FundValuationScenarioActions = createActionGroup({
    source: "Fund Valuation Scenario Actions",
    events: {
        loadall: props<{ fundId: number; fundValuationId: number }>(),
        loadedall: props<{ scenarios: FundValuationScenario[] }>(),
        loaderror: props<{ errorMsg: string }>()
    }
});

export const FundValuationAggregatedCashflowAction = createActionGroup({
    source: "Fund Valuation Aggregated Cashflow Actions",
    events: {
        load: props<{ scenarioId: number; scenarioType: ScenarioType }>(),
        loaded: props<{ aggregatedCashFlows: AggregatedFundCashflows; scenarioType: ScenarioType }>(),
        loaderror: props<{ scenarioType: ScenarioType; errorMsg: string }>()
    }
});

export const FundInvestmentActions = createActionGroup({
    source: "Fund Investment Actions",
    events: {
        edit: emptyProps(),
        save: props<{ fundId: number; fundReportId: number; fundInvestment: FundInvestment }>(),
        load: props<{ fundId: number; fundReportId: number; fundInvestmentId: number }>(),
        loaded: props<{ fundInvestment: FundInvestment }>(),
        savedloaded: props<{ fundInvestment: FundInvestment }>(),
        saveAll: props<{ fundId: number; fundReportId: number; fundInvestments: FundInvestment[] }>(),
        loadall: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadedall: props<{ fundInvestments: FundInvestment[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        cancel: emptyProps()
    }
});

export const PartnershipInvestmentActions = createActionGroup({
    source: "Partnership Investment Actions",
    events: {
        load: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loaded: props<{ partnershipInvestment: PartnershipInvestment }>(),
        loaderror: props<{ errorMsg: string }>(),
        edit: emptyProps(),
        save: props<{ partnershipInvestment: PartnershipInvestment }>(),
        cancel: emptyProps()
    }
});

export const FundNoteActions = createActionGroup({
    source: "Fund Note Actions",
    events: {
        load: props<{ inContext: string }>(),
        loaded: props<{ notes: Note[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        save: props<{ context: string; note: Note }>(),
        delete: props<{ note: Note }>()
    }
});

export const UndrawnValuationActions = createActionGroup({
    source: "Undrawn Valuation Actions",
    events: {
        edit: emptyProps(),
        save: props<{
            fundValuation: FundValuation;
            scenarios: FundValuationScenario[];
        }>(),
        calc: props<{
            fundValuation: FundValuation;
            scenarios: FundValuationScenario[];
        }>(),
        calcSuccess: props<{
            fundValuation: FundValuation;
            scenarios: FundValuationScenario[];
        }>(),
        calcError: props<{ errorMsg: string }>(),
        cancel: emptyProps()
    }
});

export const FundSellerPositionActions = createActionGroup({
    source: "Fund Seller Position Actions",
    events: {
        edit: emptyProps(),
        cancel: emptyProps()
    }
});

export const ReturnSummaryActions = createActionGroup({
    source: "Return Summary Actions",
    events: {
        calc: props<{ fundId: number; fundValuationId: number }>(),
        calcSuccess: props<{ returnSummary: FundReturnSummary }>(),
        calcError: props<{ errorMsg: string }>()
    }
});

