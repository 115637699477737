/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {CodeService} from "../services/code/code.service";
import {SharedCodeActions} from "./shared.actions";
import {catchError, exhaustMap, forkJoin, map, mergeMap, of} from "rxjs";
import {Code} from "../model/code";

export const loadSharedCodeTables = createEffect(
    (actions$ = inject(Actions), codeService = inject(CodeService)) => {
        return actions$.pipe(
            ofType(SharedCodeActions.loadcodetables),
            exhaustMap(() =>
                codeService.getCodeTables("system.codeTables").pipe(
                    map((codeTableDefinition) => {
                        const codeTables: any = codeTableDefinition._embedded["ads:codes"].map((codeTable) => {
                            return {
                                code: codeTable.code,
                                table: codeTable.table,
                                active: codeTable.active,
                                id: codeTable.id,
                                translations: codeTable.translations,
                                parameters: codeTable.parameters
                            };
                        });
                        return SharedCodeActions.loadedcodetables({codeTables});
                    }),
                    catchError((error: { message: string }) =>
                        of(SharedCodeActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadSharedCodes = createEffect(
    (actions$ = inject(Actions), codeService = inject(CodeService)) => {
        return actions$.pipe(
            ofType(SharedCodeActions.loadedcodetables),
            mergeMap((action) =>
                forkJoin(
                    Object.values(action.codeTables).map(codeTable => codeService.getCodeTables(codeTable.code))
                ).pipe(
                    map(codeDefinitionList => {
                        const codes: Code[] = [];
                        codeDefinitionList.forEach((codeDefinition) => {
                            codeDefinition._embedded["ads:codes"].forEach((code) => {
                                codes.push(
                                    {
                                        code: code.code,
                                        table: code.table,
                                        active: code.active,
                                        id: code.id,
                                        translations: code.translations,
                                        parameters: code.parameters
                                    } as Code
                                );
                            });
                        });
                        return SharedCodeActions.loadedcodes({codes});
                    }),
                    catchError((error: { message: string }) =>
                        of(SharedCodeActions.loaderror({errorMsg: error.message}))
                    )
                )
            ));
    },
    {functional: true}
);
