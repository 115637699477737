import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2} from "@angular/core";
import {SearchResult} from "../models/search";
import {selectAssets, selectDeals, selectFunds} from "../store/search.selectors";
import {SearchActions} from "../store/search.actions";
import {Store} from "@ngrx/store";
import {debounceTime, distinctUntilChanged, Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import {selectSelectedSourceDataset} from "../../import/store/import.selectors";

@Component({
    selector: "valumize-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"]
})

export class SearchComponent implements OnInit, OnChanges, OnDestroy {
    @Output() searchResultSelected = new EventEmitter<string>();
    @Input() targetSearch = false;
    @Input() searchCategories = ["deals", "funds", "assets"];
    @Input() showSearchIcon = true;

    searchControl = new FormControl();
    isSearchActive = false;

    deals$: Observable<SearchResult[]> = this.store.select(selectDeals);
    funds$: Observable<SearchResult[]> = this.store.select(selectFunds(this.targetSearch));
    assets$: Observable<SearchResult[]> = this.store.select(selectAssets(this.targetSearch));

    sourceDataset$ = this.store.select(selectSelectedSourceDataset);

    private readonly clickListener: () => void;


    constructor(private readonly store: Store, private readonly el: ElementRef, private readonly renderer: Renderer2) {
        this.clickListener = this.renderer.listen("document", "click", (event) => {
            if (!this.el.nativeElement.contains(event.target)) {
                this.isSearchActive = false;
            }
        });
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(query => {
                if (query.length >= 3) {
                    this.isSearchActive = true;
                    const searchQueryObject = {query};
                    this.store.dispatch(SearchActions.load({query: searchQueryObject}));
                } else {
                    this.isSearchActive = false;
                }
            }
        );
    }

    ngOnChanges() {
        this.funds$ = this.store.select(selectFunds(this.targetSearch));
        this.assets$ = this.store.select(selectAssets(this.targetSearch));
    }

    ngOnDestroy(): void {
        this.clickListener();
    }

    selectSearchResult(resultReference?: string) {
        this.searchResultSelected.emit(resultReference);
        this.isSearchActive = false;
    }
}


