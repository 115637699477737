<ng-container *ngIf="(selectCashflowByScenarioType | async)?.aggregatedFundCashFlows as cashflowsData">
    <valumize-info-panel *ngIf="cashflowsData.status === 'ERROR'" [appearance]="'ERROR'" [message]="cashflowsData.errorMessage"></valumize-info-panel>
    <mat-progress-spinner *ngIf="cashflowsData.status === 'LOADING'"></mat-progress-spinner>
    <ng-container *ngIf="cashflowsData.data as cashflows">
        <table mat-table [dataSource]="cashflows.records">
            <ng-container matColumnDef="date" sticky>
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element">{{element.date | date}}</td>
                <td mat-footer-cell *matFooterCellDef>Total</td>
            </ng-container>
            <ng-container matColumnDef="fundAgeInQuarters">
                <th mat-header-cell *matHeaderCellDef>Fund Age in Quarters</th>
                <td mat-cell *matCellDef="let element">{{element.fundAgeInQuarters}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="assetDistribution">
                <th mat-header-cell *matHeaderCellDef>Asset Distributions</th>
                <td mat-cell *matCellDef="let element" (click)="openPopup(element, 'DISTRIBUTION')" style="cursor: pointer">{{element?.assetDistribution | decimalFormat: "currency"}}</td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalAssetDistribution | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="assetDrawdown">
                <th mat-header-cell *matHeaderCellDef>Asset Drawdowns</th>
                <td mat-cell *matCellDef="let element" (click)="openPopup(element, 'INJECTION')" style="cursor: pointer">{{element?.assetDrawdown | decimalFormat: "currency"}}</td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalAssetDrawdown | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="gpManagementFee">
                <th mat-header-cell *matHeaderCellDef>Management Fees</th>
                <td mat-cell *matCellDef="let element" matTooltipPosition="left" [matTooltip]="element?.gpManagementFee?.pattern | decimalFormat: 'number'">
                    {{element?.gpManagementFee?.amount | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpManagementFee | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="gpUnfundedPayment">
                <th mat-header-cell *matHeaderCellDef>Unfunded Drawdowns</th>
                <td mat-cell *matCellDef="let element" matTooltipPosition="left" [matTooltip]="'Pattern: ' + element?.gpUnfundedPayment?.pattern">
                    {{element?.gpUnfundedPayment?.amount | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpUnfundedPayment | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="gpUnfundedDistribution">
                <th mat-header-cell *matHeaderCellDef>Unfunded Distributions</th>
                <td mat-cell *matCellDef="let element" matTooltipPosition="left" [matTooltip]="'Pattern: ' + element?.gpUnfundedDistribution?.pattern">
                    {{element?.gpUnfundedDistribution?.amount | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpUnfundedDistribution | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="gpUnfundedNet">
                <th mat-header-cell *matHeaderCellDef>Unfunded Net</th>
                <td mat-cell *matCellDef="let element">{{element?.gpUnfundedNet | decimalFormat: "currency"}}</td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpUnfundedNet | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="purchasePrice">
                <th mat-header-cell *matHeaderCellDef>Purchase Price</th>
                <td mat-cell *matCellDef="let element">{{element?.purchasePrice | decimalFormat: "currency"}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="gpGross">
                <th mat-header-cell *matHeaderCellDef>Gross Cash Flows</th>
                <td mat-cell *matCellDef="let element">{{element?.gpGross | decimalFormat: "currency"}}</td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpGross | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="gpCarryNet">
                <th mat-header-cell *matHeaderCellDef>Net Cash Flows for Carry</th>
                <td mat-cell *matCellDef="let element">
                    {{element?.gpCarryNet | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpCarryNet | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="gpCarryPayment">
                <th mat-header-cell *matHeaderCellDef>Carry Payments</th>
                <td mat-cell *matCellDef="let element" matTooltipPosition="left" [matTooltip]="'Hurdle: ' + (element?.gpCarryPayment?.hurdle  | decimalFormat: 'currency')">
                    {{element?.gpCarryPayment?.amount | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalGpCarryPayments | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="secondaryCashflow">
                <th mat-header-cell *matHeaderCellDef>Total Secondary Cashflows</th>
                <td mat-cell *matCellDef="let element">{{element?.secondaryCashflow | decimalFormat: "currency"}}</td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalSecondaryCashflow | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="secondaryManagementFee">
                <th mat-header-cell *matHeaderCellDef>Secondary Management Fee</th>
                <td mat-cell *matCellDef="let element" matTooltipPosition="left" [matTooltip]="'Pattern: ' + element?.secondaryManagementFee?.pattern">
                    {{element?.secondaryManagementFee?.amount | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalSecondaryManagementFee | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="secondaryCarryNet">
                <th mat-header-cell *matHeaderCellDef>Secondary Net Cash Flows for Carry</th>
                <td mat-cell *matCellDef="let element">
                    {{element?.secondaryCarryNet | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="secondaryCarryPayment">
                <th mat-header-cell *matHeaderCellDef>Secondary Carry Payments</th>
                <td mat-cell *matCellDef="let element" matTooltipPosition="left" [matTooltip]="'Hurdle: ' + (element?.secondaryCarryPayment?.hurdle  | decimalFormat: 'currency')">
                    {{element?.secondaryCarryPayment?.amount | decimalFormat: "currency"}}
                </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalSecondaryCarryPayment | decimalFormat: "currency"}}</td>
            </ng-container>
            <ng-container matColumnDef="secondaryNetCashflow">
                <th mat-header-cell *matHeaderCellDef>Total Net Secondary Cashflows</th>
                <td mat-cell *matCellDef="let element">{{element?.secondaryNetCashflow | decimalFormat: "currency"}} </td>
                <td mat-footer-cell *matFooterCellDef>{{cashflows?.totalSecondaryNetCashflow | decimalFormat: "currency"}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
            <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()" class="row-important row-top-border"></tr>
        </table>
    </ng-container>
</ng-container>

<ng-template #popupTemplate let-data>
    <h4 mat-dialog-title>{{ data.title }}</h4>
    <mat-dialog-content>
        <p *ngFor="let detail of data.details">
            <span *ngIf="detail.cashflowType.code === 'DISTRIBUTION' || detail.cashflowType.code === 'INJECTION'">
                <span *ngIf="detail.originalAssetName && detail.originalAssetId; else netCurrentAssets">
                    <a (click)="navigateToAsset(detail)" style="cursor: pointer">{{ detail.originalAssetName }}</a>
                    : {{ detail.amount | traceableFormat }}
                </span>

                <ng-template #netCurrentAssets>
                    <span>
                        Net Current Assets: {{ detail.amount | traceableFormat }}
                    </span>
                </ng-template>
            </span>
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</ng-template>
