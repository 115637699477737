import {Injectable} from "@angular/core";
import {DealApiService} from "../generated-sources/api";
import {map, Observable} from "rxjs";
import {Deal} from "../models/deal";
import {DealWithSeller} from "../models/deal-with-seller";
import {DealFund} from "../models/deal-fund";
import {DealScenario} from "../models/deal-scenario";
import {AggregatedDealCashflows} from "../models/aggregated-deal-cashflows";
import {DealReturnSummary} from "../models/deal-return-summary";
import {DealCompany} from "../models/deal-company";

@Injectable({
    providedIn: "root"
})
export class DealService {

    constructor(private readonly dealApiService: DealApiService) {
    }

    public getDeals(): Observable<Deal[]> {
        return this.dealApiService.getDeals();
    }

    public getDealsForSeller(sellerId: number): Observable<Deal[]> {
        return this.dealApiService.getDealsForSeller(sellerId);
    }

    public getDeal(dealId: number): Observable<DealWithSeller> {
        return this.dealApiService.getDeal(dealId);
    }

    public save(deal: Deal, sellerId?: number): Observable<Deal> {
        const result: Observable<Deal> = !!deal.id
            ? this.dealApiService.updateDeal(deal.id, deal, sellerId)
            : this.dealApiService.createDeal(deal, sellerId);
        return result.pipe(map((dealDto: Deal) => dealDto));
    }

    public getDealFunds(dealId: number): Observable<DealFund> {
        return this.dealApiService.getDealFunds(dealId);
    }

    public getDealCompanies(dealId: number): Observable<DealCompany> {
        return this.dealApiService.getDealCompanies(dealId);
    };

    public getScenariosForDeal(dealId: number): Observable<DealScenario[]> {
        return this.dealApiService.getScenariosForDeal(dealId);
    }

    public getDealScenario(dealId: number, scenarioId: number): Observable<DealScenario> {
        return this.dealApiService.getDealScenario(dealId, scenarioId);
    }

    public saveScenario(dealId: number, scenario: DealScenario): Observable<DealScenario> {
        const result: Observable<DealScenario> = !!scenario.id && !!dealId
            ? this.dealApiService.updateDealScenario(dealId, scenario.id, scenario)
            : this.dealApiService.createDealScenario(dealId, scenario);
        return result.pipe(map((scenarioDto: DealScenario) => scenarioDto));
    }

    public saveDealScenarios(dealId: number, scenarios: DealScenario[]): Observable<DealScenario[]> {
        return this.dealApiService.updateDealScenarios(dealId, scenarios);
    }

    public getAggregatedDealCashflows(dealId: number, scenarioId: number): Observable<AggregatedDealCashflows> {
        return this.dealApiService.getAggregatedDealCashflows(dealId, scenarioId);
    }

    public calcDealReturnSummary(dealId: number): Observable<DealReturnSummary> {
        return this.dealApiService.calculateDealReturnSummary(dealId);
    }
}
