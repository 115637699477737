import {Component, OnDestroy, OnInit} from "@angular/core";
import {map, Subscription} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {selectIsAnyDealFormInEditMode, selectSelectedDealClosingDate, selectSelectedDealName} from "../store/deal/deal.selectors";
import {DealActions, DealNameActions} from "../store/deal.actions";
import {selectSelectedSeller, selectSelectedSellerId} from "../store/seller/seller.selectors";
import {SellerNewDialogComponent} from "../seller-new-dialog/seller-new-dialog.component";

@Component({
    selector: "valumize-deal-details-page",
    templateUrl: "./deal-details-page.component.html"
})
export class DealDetailsPageComponent implements OnInit, OnDestroy {

    dealId?: number;
    sellerId?: number;

    closingDate$ = this.store.select(selectSelectedDealClosingDate);
    selectedSeller$ = this.store.select(selectSelectedSeller);
    dealName$ = this.store.select(selectSelectedDealName);
    isEditDisabled$ = this.store.select(selectIsAnyDealFormInEditMode);

    dealNameIsEditable = false;

    subscriptions: Subscription[] = [];

    dealNameForm = this.formBuilder.group({
        dealName: this.formBuilder.control("", {nonNullable: true, validators: Validators.maxLength(100)}),
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        public dialog: MatDialog
    ) {
        this.subscriptions.push(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.reloadPage();
            }
        }));
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(selectSelectedDealName).pipe(map((dealName) => {
                    this.dealNameIsEditable = dealName.dealNameIsEditable;
                    this.dealNameForm.patchValue({dealName: dealName.name?.text});

                    if (dealName.dealNameIsEditable) {
                        this.dealNameForm.enable();
                    } else {
                        this.dealNameForm.disable();
                    }
                })
            ).subscribe());
    }

    reloadPage(): void {
        this.dealId = parseInt(this.route.snapshot.paramMap.get("dealId") ?? "", 10);
        const sellerIdParam = this.route.snapshot.queryParamMap.get("sellerId");
        this.sellerId = !!sellerIdParam ? parseInt(sellerIdParam, 10) : undefined;

        this.store.dispatch(DealActions.open({dealId: this.dealId, sellerId: this.sellerId}));
    }

    scrollToComponent = (componentId: string) => document.getElementById(componentId)?.scrollIntoView({behavior: "auto"});

    editMode = () => this.store.dispatch(DealNameActions.edit());

    save() {
        if (this.dealNameForm.valid) {
            this.store.dispatch(DealNameActions.save(this.dealNameForm.getRawValue()));
        }
    }

    cancel = () => this.store.dispatch(DealNameActions.cancel());

    openNewSellerDialog() {
        if (this.dialog.openDialogs.length === 0) {
            const dialogRef = this.dialog.open(SellerNewDialogComponent);
            dialogRef.componentInstance.dealId = this.dealId;
        }
        this.store.select(selectSelectedSellerId).pipe(map((id) => {
            if (!!id) {
                this.router.navigate([], {relativeTo: this.route, queryParams: {sellerId: id}});
            }
        })).subscribe();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
