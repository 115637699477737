import { Pipe, PipeTransform } from "@angular/core";
import {DatePipe} from "@angular/common";
import {DateUtil} from "../../utils/date-util";

@Pipe({
  name: "timestamp"
})
export class TimestampPipe extends DatePipe implements PipeTransform {

    override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
    override transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
    override transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
        const tsValue = (typeof value === "string") ? DateUtil.fromIsoTimestamp(value)?.toJSDate() : value;
        return super.transform(tsValue, format, timezone, locale);
    }
}
