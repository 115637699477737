<h3>{{title}}</h3>
<ng-container *ngIf="dataSource.length > 0">
    <section class="table-container" tabindex="0">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="company" sticky>
                <th mat-header-cell *matHeaderCellDef>Company</th>
                <td mat-cell *matCellDef="let element" class="no-wrap">{{element.company | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="activityIndustry">
                <th mat-header-cell *matHeaderCellDef>Activity/Industry</th>
                <td mat-cell *matCellDef="let element">{{element.activityIndustry | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>Country</th>
                <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
            </ng-container>

            <ng-container matColumnDef="gpIndication">
                <th mat-header-cell *matHeaderCellDef>GP Indication</th>
                <td mat-cell *matCellDef="let element">{{element.gpIndication.code | codeFormat: codeTableGpIndication | async}}</td>
            </ng-container>

            <ng-container matColumnDef="investmentDate">
                <th mat-header-cell *matHeaderCellDef>Inv. Date</th>
                <td mat-cell *matCellDef="let element">{{element.investmentDate | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="effectiveExitDate">
                <th mat-header-cell *matHeaderCellDef>Effective Exit Date</th>
                <td mat-cell *matCellDef="let element">{{element.effectiveExitDate | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="percentageHeld">
                <th mat-header-cell *matHeaderCellDef>% held</th>
                <td mat-cell *matCellDef="let element">{{element.percentageHeld | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="holdingPeriodInYears">
                <th mat-header-cell *matHeaderCellDef>Holding Period in Years</th>
                <td mat-cell *matCellDef="let element">{{element.holdingPeriodInYears}}</td>
            </ng-container>

            <ng-container matColumnDef="realizedCost">
                <th mat-header-cell *matHeaderCellDef>Realized Cost</th>
                <td mat-cell *matCellDef="let element">{{element.realizedCost | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="realizedGains">
                <th mat-header-cell *matHeaderCellDef>Realized Gains</th>
                <td mat-cell *matCellDef="let element">{{element.realizedGains | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="totalCost">
                <th mat-header-cell *matHeaderCellDef>Total Cost</th>
                <td mat-cell *matCellDef="let element">{{element.totalCost | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="totalRealized">
                <th mat-header-cell *matHeaderCellDef>Total Realized</th>
                <td mat-cell *matCellDef="let element">{{element.totalRealized | traceableFormat}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnDefinition"></tr>
            <tr mat-row *matRowDef=" let row; columns: columnDefinition"
                [routerLink]="'../../assets/' + row.assetId"
                [queryParams]="{
                                fundId: fundId,
                                fundReportId: fundReportId,
                                fundValuationId: fundValuationId,
                                fundInvestmentId: row.fundInvestmentId}"
            ></tr>
        </table>
    </section>
</ng-container>
