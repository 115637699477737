import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export abstract class LoginService {

    abstract showLoginPage(redirect?: string): Observable<void>;

    abstract showLoginModal(): Observable<string>;
}
