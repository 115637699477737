<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Return Summary</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <section class="table-container">
            <ng-container *ngIf="returnSummary$ | async as returnSummary">
                <table mat-table [dataSource]="returnSummary.tableDatasource">
                    <!-- first header row (column groups) -->
                    <ng-container matColumnDef="groupsCase" sticky>
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1"></th>
                    </ng-container>

                    <ng-container matColumnDef="groupsProbability">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1"></th>
                    </ng-container>

                    <ng-container matColumnDef="groupsNetPerformance">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="2">Net Performance
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="groupsGrossPerformance">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="2">Gross Performance
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="groupsNav">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1">NAV
                        </th>
                    </ng-container>

                    <!-- columns and cells -->
                    <ng-container matColumnDef="case" sticky>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">{{element.scenario.code | codeFormat: codeTableScenarioType | async}}</td>
                        <td mat-footer-cell *matFooterCellDef>CF weighted case</td>
                    </ng-container>

                    <ng-container matColumnDef="probability">
                        <th mat-header-cell *matHeaderCellDef>Probability</th>
                        <td mat-cell *matCellDef="let element">{{element.probability | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.probability | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="netPerformanceTvpi">
                        <th mat-header-cell *matHeaderCellDef>TVPI</th>
                        <td mat-cell *matCellDef="let element">{{element.netPerformanceTvpi | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.netPerformanceTvpi | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="netPerformanceIrr">
                        <th mat-header-cell *matHeaderCellDef>IRR</th>
                        <td mat-cell *matCellDef="let element">{{element.netPerformanceIrr | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.netPerformanceIrr | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="grossPerformanceTvpi">
                        <th mat-header-cell *matHeaderCellDef>TVPI</th>
                        <td mat-cell *matCellDef="let element">{{element.grossPerformanceTvpi | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.grossPerformanceTvpi | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="grossPerformanceIrr">
                        <th mat-header-cell *matHeaderCellDef>IRR</th>
                        <td mat-cell *matCellDef="let element">{{element.grossPerformanceIrr | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.grossPerformanceIrr | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="navUplift">
                        <th mat-header-cell *matHeaderCellDef>Uplift</th>
                        <td mat-cell *matCellDef="let element">{{element.navUplift | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.navUplift | traceableFormat}}</td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="['groupsCase', 'groupsProbability', 'groupsNetPerformance', 'groupsGrossPerformance', 'groupsNav']"></tr>
                    <tr mat-header-row *matHeaderRowDef="returnSummary.tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: returnSummary.tableColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="returnSummary.tableColumns" class="row-important row-top-border"></tr>
                </table>
            </ng-container>
        </section>
    </mat-card-content>
</mat-card>
