import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ScenarioType} from "../../../../shared/model/scenario-type";
import {map, Observable, Subscription} from "rxjs";
import {ApiDataModel} from "../../../../shared/model/api-data-model";
import {Store} from "@ngrx/store";
import {AggregatedDealCashflows} from "../../../models/aggregated-deal-cashflows";
import {selectDealCompaniesOverview, selectDealScenarioIdFromType, selectSelectedDealCashFlowsForScenarioType} from "../../../store/deal/deal.selectors";
import {DealAggregatedCashflowAction} from "../../../store/deal.actions";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AggregatedDealCashflowRecord} from "../../../models/aggregated-deal-cashflow-record";
import {DateUtil} from "../../../../shared/utils/date-util";
import {DealCompanyRecord} from "../../../models/deal-company-record";
import {Router} from "@angular/router";
import {DealCashflow} from "../../../models/deal-cashflow";

@Component({
    selector: "valumize-deal-cashflow-table",
    templateUrl: "./deal-cashflow-table.component.html",
    styleUrls: ["./deal-cashflow-table.component.scss"]
})
export class DealCashflowTableComponent implements OnInit, OnDestroy {

    @ViewChild("popupTemplate") popupTemplate!: TemplateRef<any>;

    @Input() dealId?: number;
    @Input() scenarioType: ScenarioType = "BASE";
    @Input() displaySecondaryColumns = false;

    dialogRef!: MatDialogRef<any>;
    subscriptions: Subscription[] = [];

    selectCashflowByScenarioType$?: Observable<{ aggregatedDealCashFlows: ApiDataModel<AggregatedDealCashflows> | undefined }>;
    dealCompanies: DealCompanyRecord[] = [];

    displayedColumnsGP = [
        "date",
        "dealAgeInQuarters",
        "assetDistribution",
        "assetDrawdown",
        "gpManagementFee",
        "gpUnfundedPayment",
        "gpUnfundedDistribution",
        "gpUnfundedNet",
        "purchasePrice",
        "gpGross",
        "gpCarryPayment"
    ];

    displayedColumnsSecondary = [
        "secondaryCashflow",
        "secondaryManagementFee",
        "secondaryCarryNet",
        "secondaryCarryPayment",
        "secondaryNetCashflow"
    ];

    displayedPopupColumns = [
        "company",
        "fundName",
        "nav",
        "bid",
        "exitDateAssumption",
        "cashflowAmount"
    ];

    constructor(private readonly store: Store,
                private readonly dialog: MatDialog,
                private readonly router: Router) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(selectDealScenarioIdFromType(this.scenarioType)).pipe(map(scenarioId => {
                if (!!scenarioId && !!this.dealId) {
                    this.store.dispatch(DealAggregatedCashflowAction.load({dealId: this.dealId, scenarioId, scenarioType: this.scenarioType}));
                }
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectDealCompaniesOverview).pipe(map(dealCompanies => {
                this.dealCompanies = dealCompanies.tableDatasource;
            })).subscribe());

        this.subscriptions.push(
            this.router.events.subscribe(() => {
                if (this.dialogRef) {
                    this.dialogRef.close();
                }
            })
        );
        this.selectCashflowByScenarioType$ = this.store.select(selectSelectedDealCashFlowsForScenarioType(this.scenarioType));
    }

    getDisplayedColumns() {
        return this.displaySecondaryColumns
            ? [...this.displayedColumnsGP, ...this.displayedColumnsSecondary]
            : this.displayedColumnsGP;
    }

    openPopup(aggregatedDealCashflowRecord: AggregatedDealCashflowRecord, cashflowType: string): void {
        const cashflowDate = DateUtil.fromIsoDate(aggregatedDealCashflowRecord.date);
        let title = cashflowType === "DISTRIBUTION" ? "Asset Cashflow" : "Asset Drawdown";
        title += " for " + cashflowDate?.toFormat("dd/MM/yyyy");

        const dealCashflows = aggregatedDealCashflowRecord.details
            .filter((detail: DealCashflow) => detail.cashflowType.code === cashflowType)
            .sort((a, b) => (b.amount.amount ?? 0) - (a.amount.amount ?? 0));

        const dealCompaniesForDate = this.dealCompanies
            .filter(dc => {
                const exitDateAssumption = DateUtil.fromIsoDate(dc.exitDateAssumption.date);
                return !!cashflowDate && !!exitDateAssumption ? cashflowDate.equals(exitDateAssumption) : false;
            })
            .sort((a, b) => this.sortByCashflowAmount(b, a));

        if (dealCashflows.length > 0 || dealCompaniesForDate.length > 0) {
            this.dialogRef = this.dialog.open(this.popupTemplate, {
                width: "850px",
                data: {dealCashflows, dealCompaniesForDate, title}
            });
        }
    }

    navigateToFund(dealCashflow: DealCashflow): void {
        if (!dealCashflow.originalFundId) {
            return;
        }
        this.router.navigate(["../../funds/", dealCashflow.originalFundId], {
            queryParams: {
                dealId: this.dealId,
                fundReportId: dealCashflow.originalFundReportId,
                fundValuationId: dealCashflow.originalFundValuationId
            }
        });
    }

    private sortByCashflowAmount(b: DealCompanyRecord, a: DealCompanyRecord) {
        switch (this.scenarioType) {
            case "LOW":
                return (b.cashflowLow.amount ?? 0) - (a.cashflowLow.amount ?? 0);
            case "BASE":
                return (b.cashflowBase.amount ?? 0) - (a.cashflowBase.amount ?? 0);
            case "HIGH":
                return (b.cashflowHigh.amount ?? 0) - (a.cashflowHigh.amount ?? 0);
            default:
                return (b.gpNAV.amount ?? 0) - (a.gpNAV.amount ?? 0);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
