<h2 mat-dialog-title>Manage Funds</h2>
<mat-dialog-content>
    <h3>Selected Fund Valuations for the Deal</h3>
    <table mat-table [dataSource]="sellerPositionsForDeal || []">
        <ng-container matColumnDef="fundName">
            <th mat-header-cell *matHeaderCellDef>Fund Name</th>
            <td mat-cell *matCellDef="let element">{{ element.fundName }}</td>
        </ng-container>
        <ng-container matColumnDef="id" sticky>
            <th mat-header-cell *matHeaderCellDef>Fund Valuation ID</th>
            <td mat-cell *matCellDef="let element">{{element.fundValuationId}}</td>
        </ng-container>
        <ng-container matColumnDef="reportDate">
            <th mat-header-cell *matHeaderCellDef>Report Date</th>
            <td mat-cell *matCellDef="let element">{{ element.reportDate | traceableFormat }}</td>
        </ng-container>
        <ng-container matColumnDef="closingDate">
            <th mat-header-cell *matHeaderCellDef>Closing Date</th>
            <td mat-cell *matCellDef="let element">{{ element.closingDate | traceableFormat }}</td>
        </ng-container>

        <ng-container matColumnDef="sellerCommitment">
            <th mat-header-cell *matHeaderCellDef>Seller Commitment</th>
            <td mat-cell *matCellDef="let element">{{element.sellerCommitment | traceableFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button
                    mat-icon-button
                    color="primary"
                    data-test="remove-fund-valuation"
                    (click)="removeFundValuationFromDeal(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dealFundsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dealFundsColumns"></tr>
    </table>
    <br>
    <br>
    <br>

    <mat-stepper [linear]="true" #stepper>
        <!-- Step 1 -->
        <mat-step [completed]="selectedFundName !== undefined">
            <ng-template matStepLabel>Select Fund</ng-template>
            <div class="header-container">
                <h3>Selectable Funds</h3>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <mat-label>Search Funds</mat-label>
                    <input matInput id="search-input" (input)="applyFilter($event)" placeholder="Search by name">
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="fundDataSource" matSort #fundSort="matSort">
                <ng-container matColumnDef="fundName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fund</th>
                    <td mat-cell *matCellDef="let element">{{element.name | traceableFormat}}</td>
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                    <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
                </ng-container>
                <ng-container matColumnDef="currencyIso">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Currency</th>
                    <td mat-cell *matCellDef="let element">{{element.currencyIso.code | codeFormat: codeTableCurrency | async}}</td>
                </ng-container>
                <ng-container matColumnDef="size">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>This Fund Size</th>
                    <td mat-cell *matCellDef="let element">{{element.size | traceableFormat}}</td>
                </ng-container>
                <ng-container matColumnDef="mainFundSize">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Main Fund Size</th>
                    <td mat-cell *matCellDef="let element">{{element.mainFundSize | traceableFormat}}</td>
                </ng-container>
                <ng-container matColumnDef="vintageYear">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vintage Year</th>
                    <td mat-cell *matCellDef="let element">{{element.vintageYear | traceableFormat}}</td>
                </ng-container>
                <ng-container matColumnDef="endYear">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fund Ends</th>
                    <td mat-cell *matCellDef="let element">{{element.endYear | traceableFormat}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="fundColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: fundColumns"
                    (click)="selectFundAndLoadValuations(row.id, row.name.text)">
                </tr>
            </table>
        </mat-step>

        <!-- Step 2 -->
        <mat-step [completed]="selectedFundValuation !== undefined">
            <ng-template matStepLabel>Select Fund Valuation</ng-template>
            <h3>Selectable Fund Valuations for "{{ this.selectedFundName }}"</h3>
            <br>
            <ng-container *ngIf="fundValuationsForFund$ | async as fundValuations">
                <ng-container *ngIf="filterFundValuations(fundValuations) as filteredFundValuations">
                    <ng-container *ngIf="filteredFundValuations.length !== 0; else noFundValuation">
                        <table mat-table [dataSource]="filteredFundValuations">
                            <ng-container matColumnDef="fundName" sticky>
                                <th mat-header-cell *matHeaderCellDef>Fund</th>
                                <td mat-cell *matCellDef="let element">{{ selectedFundName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="id" sticky>
                                <th mat-header-cell *matHeaderCellDef>Id</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="selectFundValuation(element); stepper.next()">
                                    {{ element.id }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="reportDate">
                                <th mat-header-cell *matHeaderCellDef>Report Date</th>
                                <td mat-cell *matCellDef="let element">{{ element.reportDate | traceableFormat }}</td>
                            </ng-container>
                            <ng-container matColumnDef="closingDate">
                                <th mat-header-cell *matHeaderCellDef>Closing Date</th>
                                <td mat-cell *matCellDef="let element">{{ element.closingDate | traceableFormat }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="fundValuationColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: fundValuationColumns"
                                (click)="selectFundValuation(row); stepper.next()">
                            </tr>
                        </table>
                    </ng-container>
                    <ng-template #noFundValuation>
                        <mat-card-content class="detail-card-content" style="color: grey">
                            No Fund Valuations available for this Fund. Please create one first.
                        </mat-card-content>
                    </ng-template>
                </ng-container>
            </ng-container>
            <div>
                <button mat-stroked-button color="primary" class="button-margin" (click)="goBackToList(); stepper.previous()">Back</button>
            </div>
        </mat-step>

        <!-- Step 3 -->
        <mat-step [stepControl]="sellerPositionForm">
            <ng-template matStepLabel>Enter Seller Commitment</ng-template>
            <form [formGroup]="sellerPositionForm">
                <mat-form-field appearance="fill">
                    <mat-label>Seller Commitment</mat-label>
                    <input matInput formControlName="sellerCommitment" id="sellerCommitment">
                </mat-form-field>
            </form>
            <div>
                <button mat-stroked-button color="primary" class="button-margin" (click)="goBackToValuations()">Back</button>
                <button mat-stroked-button color="primary" class="button-margin" [disabled]="sellerPositionForm.invalid"
                        (click)="addFundValuationToDeal(); stepper.next()">Add</button>
            </div>
        </mat-step>

        <!-- Step 4 -->
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <p>Successfully added Fund Valuation!</p>
            <button mat-stroked-button color="primary" class="button-margin" (click)="resetStepper()">Add another fund</button>
        </mat-step>
    </mat-stepper>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-stroked-button
        color="primary"
        mat-dialog-close
        data-test="save-button-bottom"
        (click)="save()">
        Save
    </button>
    <button
        mat-stroked-button
        mat-dialog-close
        data-test="cancel-button">
        Close
    </button>
</mat-dialog-actions>

