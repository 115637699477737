import {createActionGroup, props} from "@ngrx/store";
import {SearchQuery, SearchResult} from "../models/search";

export const SearchActions = createActionGroup({
    source: "Search Actions",
    events: {
        load: props< {query: SearchQuery} >(),
        loaded: props<{ searchResults: SearchResult[] }>(),
        loaderror: props<{ errorMsg: string }>()
    }
});
