<mat-card class="detail-card">
    <mat-expansion-panel [expanded]="graphPanelOpenState" class="mat-expansion-panel">
        <mat-expansion-panel-header class="mat-expansion-panel-header">
            <mat-card-title data-test="title">Fund Cashflows Graph</mat-card-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="(fundValuationCashflowsGraph$ | async) as graphData">
            <div class="chart-container" [class.hidden]="graphData.labels.length === 0">
                <canvas id="FundCashFlowsGraph"></canvas>
            </div>
            <ng-container *ngIf="graphData.labels.length === 0">
                <div class="detail-card-content" style="color: grey">
                    For displaying the cashflow graph, you need to add a fund report, a fund valuation, asset valuations, and some fund details.
                </div>
            </ng-container>
        </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="scenarioGraphPanelOpenState" class="mat-expansion-panel">
        <mat-expansion-panel-header class="mat-expansion-panel-header">
            <mat-card-title data-test="title">Scenario Cashflows Graphs</mat-card-title>
        </mat-expansion-panel-header>

        <mat-card-content class="detail-card-content">
            <mat-tab-group selectedIndex="1">
                <mat-tab label="Low" data-test="tab-low">
                    <ng-container *ngIf="(lowScenarioCashflowsGraph$ | async) as lowGraphData">
                        <div class="chart-container" [class.hidden]="lowGraphData.labels.length === 0">
                            <canvas id="LowScenarioCashFlowsGraph"></canvas>
                        </div>
                        <ng-container *ngIf="lowGraphData.labels.length === 0">
                            <div class="detail-card-content" style="color: grey">
                                For displaying the cashflow graph, you need to add a fund report, a fund valuation, asset valuations, and some fund details.
                            </div>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <mat-tab label="Base" data-test="tab-base">
                    <ng-container *ngIf="(baseScenarioCashflowsGraph$ | async) as baseGraphData">
                        <div class="chart-container" [class.hidden]="baseGraphData.labels.length === 0">
                            <canvas id="BaseScenarioCashFlowsGraph"></canvas>
                        </div>
                        <ng-container *ngIf="baseGraphData.labels.length === 0">
                            <div class="detail-card-content" style="color: grey">
                                For displaying the cashflow graph, you need to add a fund report, a fund valuation, asset valuations, and some fund details.
                            </div>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <mat-tab label="High" data-test="tab-high">
                    <ng-container *ngIf="(highScenarioCashflowsGraph$ | async) as highGraphData">
                        <div class="chart-container" [class.hidden]="highGraphData.labels.length === 0">
                            <canvas id="HighScenarioCashFlowsGraph"></canvas>
                        </div>
                        <ng-container *ngIf="highGraphData.labels.length === 0">
                            <div class="detail-card-content" style="color: grey">
                                For displaying the cashflow graph, you need to add a fund report, a fund valuation, asset valuations, and some fund details.
                            </div>
                        </ng-container>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-expansion-panel>
</mat-card>
