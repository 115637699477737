import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectFundReturnSummaryTable} from "../../store/fund-valuation/fund-valuation.selectors";
import {CodeTableEnum} from "../../../shared/model/code";

@Component({
    selector: "valumize-fund-return-summary",
    templateUrl: "./fund-return-summary.component.html"
})
export class FundReturnSummaryComponent {

    returnSummary$ = this.store.select(selectFundReturnSummaryTable);
    codeTableScenarioType = CodeTableEnum.SHARED_SCENARIOTYPE;

    constructor(private readonly store: Store) {
    }
}
