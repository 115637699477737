import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {selectBaselineFundReportsForBaselineFund} from "../../store/fund-report/fund-report.selectors";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {FundReportActions} from "../../store/fund.actions";
import {FundReport} from "../../models/fund-report";
import {TraceableDate} from "../../../shared/model/traceable";

@Component({
    selector: "valumize-fund-report-merge-target-dialog",
    templateUrl: "./fund-report-merge-target-dialog.component.html",
    styleUrls: ["./fund-report-merge-target-dialog.component.scss"]
})
export class FundReportMergeTargetDialogComponent implements OnInit {
    @Input() fundId?: number;
    @Input() reportDate?: TraceableDate;
    @Output() fundReportSelected = new EventEmitter<number>();

    fundReportsForFund$ = this.store.select(selectBaselineFundReportsForBaselineFund);
    columns = ["id", "reportDate"];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
    ) {
    }

    ngOnInit() {
        if (!!this.fundId) {
            this.store.dispatch(FundReportActions.loadall({fundId: this.fundId, fundIsMergeTarget: true}));
        }
    }

    filterFundReportsByDate(fundReports: FundReport[]) {
        return fundReports.filter(fr => fr.reportDate.date === this.reportDate?.date);
    }

    selectMergeTarget(fundReportId: number) {
        this.fundReportSelected.emit(fundReportId);
    }
}
