import {Pipe, PipeTransform} from "@angular/core";
import {CodeTableEnum} from "../../model/code";
import {Store} from "@ngrx/store";
import {selectCodeTranslation} from "../../store/shared.selectors";
import {map, Observable, of} from "rxjs";

@Pipe({
    name: "codeFormat"
})
export class CodeFormatPipe implements PipeTransform {
    translation = "-";

    constructor(private readonly store: Store) {
    }

    transform(code?: string, codeTable?: CodeTableEnum): Observable<string> {
        if (!code || !codeTable) {
            return of(this.translation);
        }

        return this.store.select(selectCodeTranslation(code, codeTable)).pipe(map((translationFromStore) =>
            !!translationFromStore ? translationFromStore : this.translation
        ));
    }
}
