import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {ImportLog} from "../models/import-log";

export const AccelexImportActions = createActionGroup({
    source: "Accelex Import Actions",
    events: {
        load: emptyProps(),
        loaded: props<{ fileNames: string[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        import: props<{ filesNames: string[]; dealId?: number }>(),
        importsubmitted: emptyProps(),
        importerror: props<{ errorMsg: string }>(),
    }
});

export const DatasetActions = createActionGroup({
    source: "Dataset Actions",
    events: {
        load: emptyProps(),
        loaded: props<{ datasets: string[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        setsourceandtarget: props<{ source: string; target: string | undefined }>(),
        merge: props<{ source: string; target: string }>(),
        delete: props<{ dataset: string }>(),
        deleted: props<{ dataset: string }>()
    }
});

export const AccelexImportLogActions = createActionGroup({
    source: "Accelex Import Log Actions",
    events: {
        loadAll: emptyProps(),
        loadedAll: props<{ importLogs: ImportLog[] }>(),
        loaderror: props<{ errorMsg: string }>()
    }
});
