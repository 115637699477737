import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ImportOverviewPageComponent} from "./import-overview-page/import-overview-page.component";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {ImportDatasetDialogComponent} from "./import-dataset-dialog/import-dataset-dialog.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {SharedModule} from "../shared/shared.module";
import {MetaReducer, StoreModule} from "@ngrx/store";
import {importReducer, importStoreFeatureKey} from "./store/import.reducer";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {importHydrationMetaReducer} from "./store/import-hydration.reducer";
import {BASE_PATH as GPT_BASE_PATH} from "../import/generated-sources/api/gpt";
import {BASE_PATH as DATASET_BASE_PATH} from "../import/generated-sources/api/dataset";
import {BASE_PATH as ACCELEX_BASE_PATH} from "../import/generated-sources/api/accelex";
import {AccelexImportLogsComponent} from "./import-overview-page/accelex-import-logs/accelex-import-logs.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

export const metaReducers: MetaReducer[] = [importHydrationMetaReducer];

@NgModule({
    declarations: [
        ImportOverviewPageComponent,
        ImportDatasetDialogComponent,
        AccelexImportLogsComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        FormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule,
        StoreModule.forFeature(importStoreFeatureKey, importReducer, {metaReducers}),
        MatOptionModule,
        MatSelectModule,
        MatExpansionModule,
        MatProgressSpinnerModule
    ],
    providers: [
        {provide: GPT_BASE_PATH, useValue: ".."},
        {provide: DATASET_BASE_PATH, useValue: ".."},
        {provide: ACCELEX_BASE_PATH, useValue: ".."}
    ]
})
export class ImportModule {
}
