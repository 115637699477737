import {Component} from "@angular/core";
import {selectImportLogs} from "../../store/import.selectors";
import {Store} from "@ngrx/store";
import {AccelexImportLogActions} from "../../store/import.actions";
import {CodeTableEnum} from "../../../shared/model/code";

@Component({
    selector: "valumize-accelex-import-logs",
    templateUrl: "./accelex-import-logs.component.html"
})
export class AccelexImportLogsComponent {

    columns = ["userName", "started", "finished", "fileName", "dataset", "status"];
    importLogs$ = this.store.select(selectImportLogs);

    codeTableTaskInfoStatus = CodeTableEnum.ACCELEX_TASK_INFO_STATUS;

    constructor(private readonly store: Store) {
        this.store.dispatch(AccelexImportLogActions.loadall());
    }
}
