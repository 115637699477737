import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "valumize-info-panel",
  templateUrl: "./info-panel.component.html",
  styleUrls: ["./info-panel.component.scss"]
})
export class InfoPanelComponent implements OnChanges {
    @Input() appearance: "INFO" | "SUCCESS" | "WARNING" | "ERROR" | "SAVED" | undefined;
    @Input() message = "";

    constructor(private readonly snackBar: MatSnackBar) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.appearance === "SAVED" ) {
            this.openSavedSnackBar();
        }
    }

    openSavedSnackBar() {
        this.snackBar.open((this.message === "") ? "Successfully saved" : this.message, "", {
            duration: 3000
        });
    }

}
