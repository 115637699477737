<ng-container *ngIf="(groupedDealCompanies$ | async) as data">
    <div class="treemap-container" [class.hidden]="data.length === 0">
        <canvas #dealsTreemap></canvas>
    </div>
    <ng-container *ngIf="data.length === 0">
        <div class="detail-card-content" style="color: grey">
            For displaying the companies treemap, you need to add some data first.
        </div>
    </ng-container>
</ng-container>
