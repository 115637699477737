import {DecimalFormatPipe} from "../../shared/pipes/decimal-format/decimal-format.pipe";
import Big from "big.js";
import {ApiDataModel} from "../../shared/model/api-data-model";
import {AggregatedFundCashflows} from "../models/aggregated-fund-cashflows";
import {ChartDataset} from "chart.js";
import {DateTime} from "luxon";

interface CumulativeGpGross {
    quarters: string[];
    cumulativeGpGross: number[];
}

export class FundCashFlowsUtil {

    public static getCumulativeGpGross(cashflows: { aggregatedFundCashFlows: ApiDataModel<AggregatedFundCashflows> | undefined }): CumulativeGpGross {

        const quarters: string[] = [];
        const cumulativeGpGross: number[] = [];
        if (cashflows === undefined) {
            return {quarters, cumulativeGpGross};
        }
        cashflows.aggregatedFundCashFlows?.data.records.forEach(
            (record) => {
                if (record.gpGross) {
                    quarters.push(record.date);
                    // get the latest entry of the array and add the new gpGross
                    const gpGross = DecimalFormatPipe.transformFromMillionsNum(record.gpGross) === undefined ?
                        0 :
                        DecimalFormatPipe.transformFromMillionsNum(record.gpGross) as number;
                    const newGpGross = (cumulativeGpGross.length > 0) ?
                        Big(cumulativeGpGross[cumulativeGpGross.length - 1]).add(gpGross) :
                        Big(gpGross);

                    cumulativeGpGross.push(newGpGross.toNumber());
                }
            }
        );
        return {quarters, cumulativeGpGross};

    }

    public static getStackedBarAndCumulativeData(cashflows: { aggregatedFundCashFlows: ApiDataModel<AggregatedFundCashflows> | undefined }): StackedBarAndCumulativeData {
        const quarters: string[] = [];
        const assetDistribution: { [quarter: string]: { [company: string]: number } } = {};
        const assetDistributionTotal: number[] = [];
        const assetDrawdown: number[] = [];
        const gpManagementFee: number[] = [];
        const gpUnfundedPayment: number[] = [];
        const gpUnfundedDistribution: number[] = [];
        const purchasePrice: number[] = [];
        const gpCarryPayment: number[] = [];
        const cumulativeGpGross: number[] = [];
        let cumulativeTotal = 0;
        let transformedValue: number | undefined;

        if (cashflows.aggregatedFundCashFlows === undefined) {
            return {
                quarters, assetDistribution, assetDistributionTotal, assetDrawdown, gpManagementFee, gpUnfundedPayment, gpUnfundedDistribution,
                purchasePrice, gpCarryPayment, cumulativeGpGross
            };
        }

        cashflows.aggregatedFundCashFlows.data.records.forEach(record => {
            if (record.gpGross) {
                quarters.push(record.date);
                assetDistribution[record.date] = {};

                record.details.forEach(detail => {
                    if (detail.cashflowType.code === "DISTRIBUTION" || detail.cashflowType.code === "INJECTION") {
                        const companyName = detail.originalAssetName ? detail.originalAssetName : "Net Current Assets";
                        let amount = DecimalFormatPipe.transformFromMillionsNum(detail.amount.amount);
                        if (amount !== undefined) {
                            amount = parseFloat(amount.toFixed(2));
                            if (!assetDistribution[record.date][companyName]) {
                                assetDistribution[record.date][companyName] = 0;
                            }
                            assetDistribution[record.date][companyName] += amount;
                        }
                    }
                });

                const transformAndPush = (total: number[], value: number | undefined) => {
                    transformedValue = DecimalFormatPipe.transformFromMillionsNum(value);
                    if (transformedValue !== undefined) {
                        total.push(parseFloat(transformedValue.toFixed(2)));
                    }
                };
                transformAndPush(assetDistributionTotal, record.assetDistribution);
                transformAndPush(assetDrawdown, record.assetDrawdown);
                transformAndPush(gpManagementFee, record.gpManagementFee?.amount);
                transformAndPush(gpUnfundedPayment, record.gpUnfundedPayment?.amount);
                transformAndPush(gpUnfundedDistribution, record.gpUnfundedDistribution?.amount);
                transformAndPush(purchasePrice, record.purchasePrice);
                transformAndPush(gpCarryPayment, record.gpCarryPayment?.amount);

                const gpGross = DecimalFormatPipe.transformFromMillionsNum(record.gpGross);
                if (gpGross !== undefined) {
                    const roundedGpGross = parseFloat(gpGross.toFixed(2));
                    cumulativeTotal += roundedGpGross;
                    cumulativeTotal = parseFloat(cumulativeTotal.toFixed(2));
                    cumulativeGpGross.push(cumulativeTotal);
                }
            }
        });

        return {
            quarters,
            assetDistribution,
            assetDistributionTotal,
            assetDrawdown,
            gpManagementFee,
            gpUnfundedPayment,
            gpUnfundedDistribution,
            purchasePrice,
            gpCarryPayment,
            cumulativeGpGross
        };
    }

    public static createChartData(cashflows: any, cumulativeGpGrossColor: string):
        { labels: string[]; assetDistribution: any; datasets: ChartDataset<"bar" | "line", number[]>[] } {

        const data = this.getStackedBarAndCumulativeData(cashflows);

        return {
            labels: data.quarters.map(dateStr => DateTime.fromISO(dateStr).toFormat("MMM yyyy")),
            assetDistribution: data.assetDistribution,
            datasets: [
                {
                    type: "bar",
                    label: "Asset Distribution",
                    data: data.assetDistributionTotal,
                    backgroundColor: "rgba(102, 204, 102, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "bar",
                    label: "Asset Drawdown",
                    data: data.assetDrawdown,
                    backgroundColor: "rgba(255, 102, 102, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "bar",
                    label: "Management Fee",
                    data: data.gpManagementFee,
                    backgroundColor: "rgba(255, 178, 102, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "bar",
                    label: "Unfunded Drawdown",
                    data: data.gpUnfundedPayment,
                    backgroundColor: "rgba(255, 204, 102, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "bar",
                    label: "Unfunded Distribution",
                    data: data.gpUnfundedDistribution,
                    backgroundColor: "rgba(102, 153, 255, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "bar",
                    label: "Purchase Price",
                    data: data.purchasePrice,
                    backgroundColor: "rgba(255, 153, 153, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "bar",
                    label: "Carry Payment",
                    data: data.gpCarryPayment,
                    backgroundColor: "rgba(255, 204, 153, 0.5)",
                    stack: "Stack 0",
                } as ChartDataset<"bar", number[]>,
                {
                    type: "line",
                    label: "Cumulative GP Gross",
                    data: data.cumulativeGpGross,
                    borderColor: cumulativeGpGrossColor,
                    backgroundColor: cumulativeGpGrossColor,
                    borderWidth: 2,
                    fill: false,
                } as ChartDataset<"line", number[]>
            ]
        };
    }
}

interface StackedBarAndCumulativeData {
    quarters: string[];
    assetDistribution: { [quarter: string]: { [company: string]: number } };
    assetDistributionTotal: number[];
    assetDrawdown: number[];
    gpManagementFee: number[];
    gpUnfundedPayment: number[];
    gpUnfundedDistribution: number[];
    purchasePrice: number[];
    gpCarryPayment: number[];
    cumulativeGpGross: number[];
}
