import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Note} from "../../model/note";
import {Note as ApiNote, NoteApiService} from "../../generated-sources/api";

@Injectable({
  providedIn: "root"
})
export class NoteService {
    constructor(private readonly noteApiService: NoteApiService) {}

    getNotes(inContext: string): Observable<Note[]> {
        return this.noteApiService.getNotes(inContext);
    }

    save(note: Note, context: string): Observable<Note> {
        const update: ApiNote = {
            ... note,
            pinned: false
        };

        return (update.id)
            ? this.noteApiService.updateNote(update.id, update)
            : this.noteApiService.createNote(context, update);
    }

    delete(noteId: number): Observable<void> {
        return this.noteApiService.deleteNote(noteId);
    }
}
