import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {selectSelectedFundValuationsForFundOnReportDate} from "../../store/fund-valuation/fund-valuation.selectors";
import {selectSelectedFundReportId, selectSelectedFundReportReportDate} from "../../store/fund-report/fund-report.selectors";
import {FundValuationActions} from "../../store/fund.actions";
import {DateUtil} from "../../../shared/utils/date-util";

@Component({
    selector: "valumize-fund-valuation-new-dialog",
    templateUrl: "./fund-valuation-new-dialog.component.html",
    styleUrls: ["./fund-valuation-new-dialog.component.scss"]
})
export class FundValuationNewDialogComponent implements OnInit {
    @Input() fundId?: number;
    fundReportId$ = this.store.select(selectSelectedFundReportId);
    fundReportDate$ = this.store.select(selectSelectedFundReportReportDate);
    fundValuationsForFundOnReportDate$ = this.store.select(selectSelectedFundValuationsForFundOnReportDate);
    columns = ["id", "reportDate", "closingDate"];

    fundValuationForm = this.formBuilder.group({
        closingDate: this.formBuilder.control<Date>(DateUtil.jsDate(), {nonNullable: true, validators: Validators.required})
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        if (!!this.fundId) {
            this.store.dispatch(FundValuationActions.loadall({fundId: this.fundId}));
        }
    }

    createFundValuation(reportDate?: string, fundReportId?: number) {
        if (this.fundValuationForm.valid) {
            if (!this.fundId || !reportDate || !fundReportId) {
                return;
            }

            const updatedFormValues = this.fundValuationForm.getRawValue();
            this.store.dispatch(FundValuationActions.create({
                    fundId: this.fundId,
                    fundReportId,
                    reportDate,
                    closingDate: DateUtil.toIsoDate(updatedFormValues.closingDate) as string
                }
            ));
        }
    }
}
