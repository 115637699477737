<form [formGroup]="endMarketsCustomersForm">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">End Markets / Customers</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="selectedAsset$ | async as selectedAsset">
            <ng-container *ngIf="endMarketsCustomers$ | async as endMarketsCustomers">
                <mat-card-subtitle>
                    <valumize-info-panel *ngIf="(gptEndMarkets$ | async)?.status === 'ERROR'" [appearance]="'ERROR'" [message]="gptErrorMessage"></valumize-info-panel>
                    <valumize-info-panel *ngIf="selectedAsset.status === 'ERROR'" [appearance]="'ERROR'" [message]="selectedAsset.errorMessage"></valumize-info-panel>
                    <valumize-info-panel *ngIf="selectedAsset.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Successfully saved'"></valumize-info-panel>
                    <mat-progress-spinner *ngIf="selectedAsset.status === 'LOADING'"></mat-progress-spinner>
                </mat-card-subtitle>
                <mat-card-content class="detail-card-content">
                    <div class="end-markets-customers-form">
                        <div class="description-section">
                            <div *ngIf="(endMarketsCustomersIsEditable$ | async) === false && endMarketsCustomers.text; else noText" class="line-breaks">
                                {{endMarketsCustomers.text}}
                            </div>
                            <div class="description-container" *ngIf="(endMarketsCustomersIsEditable$ | async)">
                                <mat-form-field class="description">
                                    <textarea class="line-breaks"
                                              style="height: 250px"
                                              formControlName="text"
                                              data-test="text"
                                              matInput></textarea>
                                </mat-form-field>

                                <ng-container *ngIf="gptEndMarkets$ | async as gptEndMarkets">
                                    <div *ngIf="isGptEditMode && gptEndMarkets.status !=='LOADING'" class="gpt-actions">
                                        <button mat-stroked-button color="primary" (click)="copyGptText(gptEndMarkets.data)">Replace</button>
                                        <button mat-stroked-button color="primary" (click)="addGptText(gptEndMarkets.data)">Append</button>
                                    </div>
                                </ng-container>

                                <mat-form-field class="description" *ngIf="isGptEditMode && (gptEndMarkets$ | async)?.status !== 'LOADING'">
                                    <textarea class="line-breaks"
                                              [value]="(gptEndMarkets$ | async)?.data?.text || ''"
                                              matInput
                                              readonly></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <ng-template #noText>
                        <div *ngIf="(endMarketsCustomersIsEditable$ | async) === false" class="no-text" style="color: grey">
                            Please add content for End Markets / Customers!
                        </div>
                    </ng-template>
                </mat-card-content>
                <mat-card-actions class="detail-card-actions">
                    <mat-spinner *ngIf="(gptEndMarkets$ | async)?.status === 'LOADING'" diameter="24"></mat-spinner>
                    <button mat-icon-button
                            *ngIf="(endMarketsCustomersIsEditable$ | async)"
                            (click)="isGptEditMode ? gptCancel() : gptEditMode()"
                            class="gpt-button"
                            aria-label="Generate Description">
                        <img ngSrc="assets/GPT-Logo.png" alt="GPT Logo" style="width:24px; height:24px;" height="2160" width="2160">
                    </button>
                    <div class="last-modified" data-test="modification-date">Last modified: {{endMarketsCustomers.modDate | timestamp}}</div>
                    <button mat-icon-button color="primary"
                            data-test="edit-button"
                            (click)="editMode()"
                            *ngIf="(endMarketsCustomersIsEditable$ | async) === false"
                            [disabled]="(isEditDisabled$ | async)!">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" data-test="save-button" (click)="save(); gptCancel()" *ngIf="(endMarketsCustomersIsEditable$ | async)">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" data-test="cancel-button" (click)="cancel(); gptCancel()" *ngIf="(endMarketsCustomersIsEditable$ | async) === true">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-card-actions>
            </ng-container>
        </ng-container>
    </mat-card>
</form>
