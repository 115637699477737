import {Component, OnInit} from "@angular/core";
import {selectAssets, selectSelectedAssetId} from "../store/asset/asset.selectors";
import {Store} from "@ngrx/store";
import {AssetActions, AssetListActions} from "../store/asset/asset.actions";
import {map, Observable} from "rxjs";
import {Asset} from "../models/asset/asset";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {noWhitespaceValidator} from "../../shared/utils/form-validators";

@Component({
    selector: "valumize-asset-new-dialog",
    templateUrl: "./asset-new-dialog.component.html",
    styleUrls: ["./asset-new-dialog.component.scss"]
})
export class AssetNewDialogComponent implements OnInit {

    filteredAsset$?: Observable<Asset[]>;
    asset$ = this.store.select(selectAssets);
    columns = ["name"];

    nameForm = this.formBuilder.group({
        assetName: this.formBuilder.control("", {nonNullable: true, validators: [Validators.required, Validators.maxLength(100), noWhitespaceValidator()]}),
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder,
                private readonly router: Router) {
        this.store.dispatch(AssetListActions.load());
    }

    ngOnInit(): void {
        this.filteredAsset$ = this.asset$;
    }

    onSearch() {
        if (this.nameForm.value.assetName) {
            this.filteredAsset$ = this.asset$.pipe(map((assets) =>
                assets.filter((asset) => !!asset.name.text ? asset.name.text.toLowerCase().includes(this.nameForm.getRawValue().assetName.toLowerCase()) : false)));
        } else {
            this.filteredAsset$ = this.asset$;
        }
    }

    createAsset() {
        if (this.nameForm.valid) {
            this.store.dispatch(AssetActions.create(this.nameForm.getRawValue()));
        }
        this.store.select(selectSelectedAssetId).pipe(map((id) => {
            if (!!id && id > 0) {
                this.router.navigate(["/assets", id]);
            }
        })).subscribe();
    }

}
