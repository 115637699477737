import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiUtil} from "../../utils/api-util";

@Injectable({
    providedIn: "root"
})
export class UserProfileService {

    constructor(private readonly httpClient: HttpClient) {
    }

    changePassword(userName: string, oldPassword: string, newPassword: string): Observable<any> {
        return this.httpClient.put(ApiUtil.toApiPath(`users/${userName}/password`), {oldPassword, newPassword});
    }
}
