<ng-container *ngIf="dealCompanies$ | async as dealCompanies">
    <mat-tab-group selectedIndex="1">
        <mat-tab label="Low" data-test="tab-low">
            <div class="chart-container" [class.hidden]="dealCompanies.dealCompanyRecords.length === 0">
                <canvas id="LowScenarioDealBubbleChart"></canvas>
            </div>
            <ng-container *ngIf="dealCompanies.dealCompanyRecords.length === 0">
                <div class="detail-card-content" style="color: grey">
                    No data available for the low scenario.
                </div>
            </ng-container>
        </mat-tab>
        <mat-tab label="Base" data-test="tab-base">
            <div class="chart-container" [class.hidden]="dealCompanies.dealCompanyRecords.length === 0">
                <canvas id="BaseScenarioDealBubbleChart"></canvas>
            </div>
            <ng-container *ngIf="dealCompanies.dealCompanyRecords.length === 0">
                <div class="detail-card-content" style="color: grey">
                    No data available for the base scenario.
                </div>
            </ng-container>
        </mat-tab>
        <mat-tab label="High" data-test="tab-high">
            <div class="chart-container" [class.hidden]="dealCompanies.dealCompanyRecords.length === 0">
                <canvas id="HighScenarioDealBubbleChart"></canvas>
            </div>
            <ng-container *ngIf="dealCompanies.dealCompanyRecords.length === 0">
                <div class="detail-card-content" style="color: grey">
                    No data available for the high scenario.
                </div>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-container>
