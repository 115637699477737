<form [formGroup]="discountMultipleValuationForm">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title>Discount Multiple Valuation (Forecast)</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="(selectedAssetValuation$ | async) as selectedAssetValuation">
            <ng-container *ngIf="selectedAssetValuation.data?.id; else selectValuationFirst">
                <ng-container *ngIf="(discountMultipleValuation$ | async) as discountMultipleValuation">
                    <mat-card-subtitle>
                        <valumize-info-panel *ngIf="selectedAssetValuation.status === 'ERROR'" [appearance]="'ERROR'"
                                             [message]="selectedAssetValuation.errorMessage"></valumize-info-panel>
                        <valumize-info-panel *ngIf="selectedAssetValuation.status === 'SAVED'" [appearance]="'SAVED'"
                                             [message]="'Discount Multiple Valuation successfully saved'"></valumize-info-panel>
                        <mat-progress-spinner *ngIf="selectedAssetValuation.status === 'LOADING'"></mat-progress-spinner>
                    </mat-card-subtitle>

                    <mat-card-content class="detail-card-content">
                        <div class="key-value-pair-section">
                            <div class="key-value-pair-column">
                                <div class="key-value-pair">
                                    <div class="key-value-item">
                                        Required gIRR
                                    </div>
                                    <div class="key-value-item">
                                        <ng-container *ngIf="isEditable">
                                            <div class="d-flex">
                                                <div>
                                                    {{discountMultipleValuation.persistedRequiredGrossIRR | traceableFormat}}
                                                </div>
                                                <div>
                                                    <input matInput
                                                           type="number"
                                                           formControlName="calculatedRequiredGrossIRR"
                                                           id="calculatedRequiredGrossIRR"
                                                           (change)="calcAndSave('calculatedRequiredGrossIRR')">
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!isEditable">
                                            {{discountMultipleValuation.persistedRequiredGrossIRR | traceableFormat}}
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="key-value-pair-column">
                                <div class="key-value-pair">
                                    <div class="key-value-item">
                                        Bid Price
                                    </div>
                                    <div class="key-value-item d-flex">
                                        <div>{{discountMultipleValuation.persistedFinalBidPrice | traceableFormat}}</div>
                                        <div>
                                            <ng-container *ngIf="isEditable">
                                                {{discountMultipleValuation.calculatedFinalBidPrice | traceableFormat}}
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table mat-table [dataSource]="discountMultipleValuation.tableDataSource">

                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    <ng-container *ngIf="isEditable">
                                        Exit Date:
                                        <mat-form-field class="date-form-field">
                                            <input matInput
                                                   formControlName="mostRecentCashflowDate"
                                                   id="mostRecentCashflowDate"
                                                   (dateChange)="calcAndSave('mostRecentCashflowDate')"
                                                   [matDatepicker]="cashflowDatePicker">
                                            <mat-hint>dd/MM/yyyy</mat-hint>
                                            <mat-datepicker-toggle matIconSuffix [for]="cashflowDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #cashflowDatePicker></mat-datepicker>
                                        </mat-form-field>
                                        <br>
                                    </ng-container>
                                    <ng-container *ngIf="!isEditable">
                                        Exit Date: {{discountMultipleValuation.mostRecentCashflowDate | date}}
                                    </ng-container>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <!-- Low Column -->
                            <ng-container matColumnDef="low">
                                <th mat-header-cell *matHeaderCellDef>Low</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.low | traceableFormat}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="lowCalc">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="isEditable">
                                        <ng-container *ngIf="formControlIsEditable(element.definition)">
                                            <input matInput
                                                   type="number"
                                                   formControlName="{{element.definition}}LowCalc"
                                                   id="{{element.definition}}LowCalc"
                                                   (change)="calcAndSave(element.definition + 'LowCalc')">
                                        </ng-container>
                                        <ng-container *ngIf="!formControlIsEditable(element.definition)">
                                            {{element.lowCalc | traceableFormat}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <!-- Base Column -->
                            <ng-container matColumnDef="base">
                                <th mat-header-cell *matHeaderCellDef>Base</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.base | traceableFormat}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="baseCalc">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="isEditable">
                                        <ng-container *ngIf="formControlIsEditable(element.definition)">
                                            <input matInput
                                                   type="number"
                                                   formControlName="{{element.definition}}BaseCalc"
                                                   id="{{element.definition}}BaseCalc"
                                                   (change)="calcAndSave(element.definition + 'BaseCalc')">
                                        </ng-container>
                                        <ng-container *ngIf="!formControlIsEditable(element.definition)">
                                            {{element.baseCalc | traceableFormat}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <!-- High Column -->
                            <ng-container matColumnDef="high">
                                <th mat-header-cell *matHeaderCellDef>High</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.high | traceableFormat}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="highCalc">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="isEditable">
                                        <ng-container *ngIf="formControlIsEditable(element.definition)">
                                            <input matInput
                                                   type="number"
                                                   formControlName="{{element.definition}}HighCalc"
                                                   id="{{element.definition}}HighCalc"
                                                   (change)="calcAndSave(element.definition + 'HighCalc')">
                                        </ng-container>
                                        <ng-container *ngIf="!formControlIsEditable(element.definition)">
                                            {{element.highCalc | traceableFormat}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="discountMultipleValuation.tableColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: discountMultipleValuation.tableColumns;" class="{{styleRowImportant(row)}} {{styleRowBorderTop(row)}}"></tr>
                        </table>
                    </mat-card-content>
                    <mat-card-actions class="detail-card-actions">
                        <div class="last-modified" data-test="modification-date">Last modified: {{discountMultipleValuation.persistedFinalBidPrice.modDate | timestamp}}</div>
                        <button (click)="editMode()"
                                *ngIf="!isEditable"
                                [disabled]="(isEditDisabled$ | async)!"
                                color="primary"
                                data-test="edit-button"
                                mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="calcAndSave('', true)"
                                *ngIf="isEditable"
                                [disabled]="discountMultipleValuationForm.invalid"
                                color="primary"
                                data-test="save-button"
                                mat-icon-button>
                            <mat-icon>save</mat-icon>
                        </button>
                        <button (click)="cancel()"
                                *ngIf="isEditable"
                                color="primary"
                                data-test="cancel-button"
                                mat-icon-button>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-card-actions>

                    <form [formGroup]="rationaleForm">
                        <mat-card class="detail-card" style="padding: unset">
                            <mat-card-header class="detail-card-header">
                                <mat-card-title>Valuation Rationale</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="detail-card-content">
                                <div *ngIf="!rationaleIsEditable && discountMultipleValuation.rationale.text; else noRationale" class="line-breaks">
                                    {{discountMultipleValuation.rationale.text}}
                                </div>

                                <ng-template #noRationale>
                                    <div *ngIf="!rationaleIsEditable" class="no-description" style="color: grey">
                                        Please add a valuation rationale!
                                    </div>
                                </ng-template>

                                <mat-form-field appearance="fill" style="display: unset" *ngIf="rationaleIsEditable">
                                    <textarea class="line-breaks"
                                              data-test="rationale"
                                              formControlName="rationale"
                                              matInput
                                              style="height: 250px">
                                    </textarea>
                                </mat-form-field>
                            </mat-card-content>

                            <mat-card-actions class="detail-card-actions">
                                <div class="last-modified" data-test="modification-date">Last
                                    modified: {{discountMultipleValuation.persistedFinalBidPrice.modDate | timestamp}}</div>
                                <button (click)="editRationaleMode()"
                                        *ngIf="!rationaleIsEditable"
                                        [disabled]="(isEditDisabled$ | async)!"
                                        color="primary"
                                        data-test="edit-rationale-button"
                                        mat-icon-button>
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button (click)="saveRationale()"
                                        *ngIf="rationaleIsEditable"
                                        [disabled]="rationaleForm.invalid"
                                        color="primary"
                                        data-test="save-rationale-button"
                                        mat-icon-button>
                                    <mat-icon>save</mat-icon>
                                </button>
                                <button (click)="cancelRationale()"
                                        *ngIf="rationaleIsEditable"
                                        color="primary"
                                        data-test="cancel-rationale-button"
                                        mat-icon-button>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </form>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #selectValuationFirst>
            <mat-card-content class="detail-card-content" style="color: grey">
                Please select valuation first!
            </mat-card-content>
        </ng-template>
    </mat-card>
</form>
