import {Component} from "@angular/core";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../services/login/authentication.service";
import {UserProfileService} from "../../../services/login/user-profile.service";
import {MatDialogRef} from "@angular/material/dialog";
import {catchError, map, of} from "rxjs";

export const PASSWORD_INPUT_TYPE = "password";
export const TEXT_INPUT_TYPE = "text";

type PasswordInputs = "currentPassword" | "newPassword" | "newPasswordRepeated";

@Component({
    selector: "valumize-change-password",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent {
    changePasswordForm: FormGroup;
    repeatedPasswordDiffersErrorMessage = `The passwords entered do not match`;
    wrongPassword = "";
    updating = false;

    passwordInputStates = {
        currentPassword: PASSWORD_INPUT_TYPE,
        newPassword: PASSWORD_INPUT_TYPE,
        newPasswordRepeated: PASSWORD_INPUT_TYPE
    };

    constructor(private readonly dialogRef: MatDialogRef<ChangePasswordComponent>,
                private readonly authService: AuthenticationService,
                private readonly userProfileService: UserProfileService,
                private readonly formBuilder: FormBuilder) {

        this.changePasswordForm = this.formBuilder.group({
            currentPassword: new FormControl("", Validators.required),
            newPassword: new FormControl("", [Validators.required, Validators.minLength(5)]),
            newPasswordRepeated: new FormControl("", [Validators.required, this.repeatedPasswordValidator])
        });
    }

    changePassword(): void {
        const userName = this.authService.currentUser?.userName;

        if (!userName) {
            throw new Error("No user is logged in!");
        }
        this.updating = true;

        this.userProfileService.changePassword(
            userName,
            this.changePasswordForm.get("currentPassword")?.value,
            this.changePasswordForm.get("newPassword")?.value
        ).pipe(
            map(() => {
                this.updating = false;
                return true;
            }),
            catchError(() => {
                this.wrongPassword = "The current password is incorrect";
                this.changePasswordForm.setErrors({invalid: true});
                this.updating = false;
                return of(false);
            }))
            .subscribe(result => {
                if (result) {
                    this.wrongPassword = "";
                    this.dialogRef.close();
                }
            });
    }

    cancel(): void {
        this.dialogRef.close();
    }

    togglePassword(passwordInput: PasswordInputs): void {
        this.passwordInputStates[passwordInput] =
            this.isPasswordType(passwordInput) ?
                TEXT_INPUT_TYPE : PASSWORD_INPUT_TYPE;
    }

    isPasswordType(passwordField: PasswordInputs): boolean {
        return this.passwordInputStates[passwordField] === PASSWORD_INPUT_TYPE;
    }

    private readonly repeatedPasswordValidator: ValidatorFn = (control: AbstractControl) => {
        const newPassword = control.parent?.get("newPassword")?.value;
        const newPasswordRepeated = control.value;
        return !!newPassword && !!newPasswordRepeated && newPassword !== newPasswordRepeated ? {differentRepeatedPassword: true} : null;
    };
}
