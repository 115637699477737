import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Fund} from "../models/fund";
import {FundApiService} from "../generated-sources/api";

@Injectable({
    providedIn: "root"
})
export class FundService {

    constructor(private readonly fundApiService: FundApiService) {
    }

    getFunds(dataset?: string): Observable<Fund[]> {
        return this.fundApiService.getFunds(dataset);
    }

    getFund(id: number): Observable<Fund> {
        return this.fundApiService.getFund(id);
    }

    saveFund(fund: Fund, id?: number, dataset?: string) {
        return (id) ? this.fundApiService.updateFund(id, fund) : this.fundApiService.createFund(fund, dataset);
    }
}
