import {DateUtil} from "../utils/date-util";

export interface Period {
    keyDate: string;
    type: "QUARTER" | "SEMESTER" | "FULL_FISCAL_YEAR";
    source?: string;
    modDate?: string;
    refDocument?: string;
    userId?: number;
}

export const EMPTY_PERIOD: Period = {
    keyDate: DateUtil.isoDate(),
    type: "FULL_FISCAL_YEAR",
    source: "",
    modDate: DateUtil.isoTimestamp(),
    refDocument: "",
    userId: undefined
};


