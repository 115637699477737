<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Treemap Actual - % of Total NAV</mat-card-title>
    </mat-card-header>

    <ng-container *ngIf="(treemapDataFromPartnershipInvestment$ | async) as treemapData">
        <canvas [class.hidden]="treemapData.length === 0" #fundTreemap id="fund-treemap"></canvas>
        <ng-container *ngIf="treemapData.length === 0">
            <mat-card-content class="detail-card-content" style="color: grey">
                For displaying the treemap, you need to add a fund report, a fund valuation and asset valuations.
            </mat-card-content>
        </ng-container>
    </ng-container>
</mat-card>
