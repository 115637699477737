import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DealState} from "../deal.state";
import {dealStoreFeatureKey} from "../deal.reducer";
import {selectDealFunds} from "../deal/deal.selectors";

export const selectDealState = createFeatureSelector<DealState>(dealStoreFeatureKey);

export const selectSelectedSellerPositionsForDeal = createSelector(
    selectDealState,
    (state) => state.selectedSellerPositionsForDeal.data
);

export const selectSellerPositionsWithFundNames = createSelector(
    selectSelectedSellerPositionsForDeal,
    selectDealFunds,
    (sellerPositions, dealFunds) => sellerPositions.map(position => {
        const dealFundRecord = dealFunds.dealFundRecords.find(record => record.fundValuationId === position.fundValuationId);
        return {
            ...position,
            fundName: dealFundRecord ? dealFundRecord.fundName.text : "",
            reportDate: dealFundRecord ? dealFundRecord.reportDate : "",
            closingDate: dealFundRecord ? dealFundRecord.closingDate : ""
        };
    })
);

export const selectSelectedSellerPosition = createSelector(
    selectDealState,
    (state) => state.selectedSellerPosition
);

export const selectSelectedSellerPositionCalc = createSelector(
    selectDealState,
    (state) => state.selectedSellerPositionCalc.data
);

