import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs/operators";
import {LoginService} from "../../../services/login/login.service";

@Component({
    selector: "valumize-login-page",
    template: ""
})
export class LoginPageComponent {
    constructor(loginService: LoginService,
                activatedRoute: ActivatedRoute,
                router: Router) {
        const redirectUrl = activatedRoute.snapshot.queryParams["redirect"] || "/";

        loginService.showLoginModal()
            .pipe(take(1)).subscribe(() => router.navigateByUrl(redirectUrl));
    }
}
