import {createFeatureSelector, createSelector} from "@ngrx/store";
import {FundState} from "../fund.state";
import {fundStoreFeatureKey} from "../fund.reducer";

export const selectFundState = createFeatureSelector<FundState>(fundStoreFeatureKey);

export const selectSelectedFundInvestment = createSelector(
    selectFundState,
    (state) => state.selectedFundInvestment
);

export const selectSelectedFundInvestmentsForFundReport = createSelector(
    selectFundState,
    (state) => state.selectedFundInvestmentsForFundReport
);

export const selectSelectedFundInvestmentId = createSelector(
    selectSelectedFundInvestment,
    (state) => state.data.id
);

export const selectSelectedFundInvestmentDate = createSelector(
    selectSelectedFundInvestment,
    (state) => state.data.investmentDate
);

export const selectFundInvestmentIdForAsset = (assetId: number) => createSelector(
    selectSelectedFundInvestmentsForFundReport,
    (fundInvestments) => {
        const matchingInvestment = fundInvestments.data.find(investment => investment.assetId === assetId);
        return matchingInvestment ? matchingInvestment.id : undefined;
    }
);
