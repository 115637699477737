<div class="container-xxl" data-test="accessDeniedWrapper">
    <div class="detail-card-content">
        <h2 class="mt-0" data-test="title">Access denied</h2>

        <p data-test="content">
            You do not have access to this area. Make sure you are logged in with the correct user.
        </p>

        <button type="button"
                data-test="relogin-button"
                (click)="logout()"
                mat-button>
            Log in again
        </button>
    </div>
</div>
