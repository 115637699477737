<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">GP Valuation</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="(generalPartnerValuation$ | async) as gpValuation">
        <mat-card-subtitle>
            <valumize-info-panel *ngIf="gpValuation.status === 'ERROR'" [appearance]="'ERROR'" [message]="gpValuation.errorMessage"></valumize-info-panel>
            <valumize-info-panel *ngIf="gpValuation.status === 'SAVED'" [appearance]="'SAVED'" [message]="'GP Valuation successfully saved'"></valumize-info-panel>
            <mat-progress-spinner *ngIf="gpValuation.status === 'LOADING'"></mat-progress-spinner>
        </mat-card-subtitle>
        <ng-container *ngIf="!!(selectedFundInvestment$ | async)?.data?.id; else selectFundReportFirst">
            <mat-card-content class="detail-card-content">
                <div class="key-value-pair-section">
                    <div class="key-value-pair-column">
                        <div class="key-value-pair">
                            <div class="key-value-item">
                                Unrealized TVPI
                            </div>
                            <div class="key-value-item">
                                {{gpValuation.data.unrealizedTVPI | traceableFormat}}
                            </div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">
                                Realized TVPI
                            </div>
                            <div class="key-value-item">
                                {{gpValuation.data.realizedTVPI | traceableFormat}}
                            </div>
                        </div>
                    </div>
                    <div class="key-value-pair-column">
                        <div class="key-value-pair">
                            <div class="key-value-item">
                                Total TVPI
                            </div>
                            <div class="key-value-item">
                                {{gpValuation.data.totalTVPI | traceableFormat}}
                            </div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">
                                Real. % of Invest.
                            </div>
                            <div class="key-value-item">
                                {{gpValuation.data.realizedPercentageOfInvestment | traceableFormat}}
                            </div>
                        </div>
                    </div>
                    <div class="key-value-pair-column">
                        <div class="key-value-pair">
                            <div class="key-value-item">
                                Total IRR
                            </div>
                            <div class="key-value-item">
                                {{gpValuation.data.totalIRR | traceableFormat}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 50%">
                    <form [formGroup]="gpValuationForm" *ngIf="generalPartnerValuationTableData$ | async as dataSource">
                        <table mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="fundLevel">
                                <th mat-header-cell *matHeaderCellDef>Fund Level</th>
                                <td mat-cell *matCellDef="let element; let i = index">
                                    <ng-container *ngIf="formControlIsEditable(element.type)">
                                        <input matInput
                                               type="number"
                                               class="my-input-field"
                                               formControlName="{{element.type}}"
                                               [id]="element.type"
                                               (change)="calc(element.type)">
                                    </ng-container>
                                    <ng-container *ngIf="!formControlIsEditable(element.type)">
                                        {{element.fundLevelValue | traceableFormat}}
                                    </ng-container>
                                </td>
                            </ng-container>

                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="{{styleRowImportant(row)}}"></tr>

                        </table>
                    </form>
                </div>
            </mat-card-content>
            <mat-card-actions class="detail-card-actions">
                <div class="last-modified" data-test="modification-date">Last modified: {{gpValuation.data.realizedTVPI.modDate | timestamp}}</div>
                <button (click)="editMode()"
                        *ngIf="!isEditable"
                        [disabled]="(isEditDisabled$ | async)!"
                        color="primary"
                        data-test="edit-button"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="save()"
                        *ngIf="isEditable"
                        [disabled]="gpValuationForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <button (click)="cancel()"
                        *ngIf="isEditable"
                        color="primary"
                        data-test="cancel-button"
                        mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
        </ng-container>
    </ng-container>
    <ng-template #selectFundReportFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select a fund report first!
        </mat-card-content>
    </ng-template>
</mat-card>
