import {Inject, Injectable} from "@angular/core";
import {WINDOW} from "../../model/login/window-token";

export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = "kw-access-token";

@Injectable({
    providedIn: "root"
})
export class LocalStorageAccessTokenService {

    constructor(@Inject(WINDOW) private readonly window: Window) {
    }

    storeAccessToken(token?: string | null): void {
        if (!!token) {
            this.window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
        } else {
            this.clearAccessToken();
        }
    }

    clearAccessToken(): void {
        this.window.localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    }

    getAccessToken(): string | null {
        return this.window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    }
}
