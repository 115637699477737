import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {SharedCodeActions} from "./shared/store/shared.actions";

@Component({
    selector: "valumize-root",
    templateUrl: "./app.component.html"
})
export class AppComponent {
    title = "valumize";

    constructor(private readonly store: Store) {
        this.store.dispatch(SharedCodeActions.loadcodetables());
    }
}
