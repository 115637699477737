import {Component, OnInit, Renderer2} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectDiscountMultipleValuation, selectSelectedAssetValuation} from "../../store/asset-valuation/asset-valuation.selectors";
import {AssetDiscountValuationActions} from "../../store/asset/asset.actions";
import {map} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AssetValuationScenario} from "../../models/asset-valuation/asset-valuation-scenario";
import {AssetValuation} from "../../models/asset-valuation/asset-valuation";
import {EMPTY_MULTIPLE, EMPTY_TRACEABLE, TraceableCode, TraceableMoneyWithCurrency} from "../../../shared/model/traceable";
import {AssetCashflow} from "../../models/asset-valuation/asset-cashflow";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {EMPTY_ASSET_VALUATION} from "../../store/asset.reducer";
import {DateUtil} from "../../../shared/utils/date-util";
import {selectIsAnyAssetFormInEditMode} from "../../store/asset/asset.selectors";

@Component({
    selector: "valumize-asset-discount-multiple-valuation",
    templateUrl: "./asset-discount-multiple-valuation.component.html",
    styleUrls: ["./asset-discount-multiple-valuation.component.scss"],
})
export class AssetDiscountMultipleValuationComponent implements OnInit {

    selectedAssetValuation$ = this.store.select(selectSelectedAssetValuation);
    discountMultipleValuation$ = this.store.select(selectDiscountMultipleValuation);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    persistedScenarios: AssetValuationScenario[] = [];
    persistedAssetValuation: AssetValuation = EMPTY_ASSET_VALUATION;
    calculatedScenarios: AssetValuationScenario[] = [];
    calculatedAssetValuation?: AssetValuation;
    fundCurrency?: TraceableCode;

    isEditable = false;
    rationaleIsEditable = false;

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        private readonly renderer: Renderer2
    ) {
    }

    discountMultipleValuationForm = this.formBuilder.group({
        mostRecentCashflowDate: this.formBuilder.control<Date | null>(null),
        calculatedRequiredGrossIRR: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        cashflowAmountLowCalc: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        cashflowAmountBaseCalc: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        cashflowAmountHighCalc: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        })
    });

    rationaleForm = this.formBuilder.group({
        rationale: this.formBuilder.control<string | undefined>("", {nonNullable: true})
    });

    nextFocus: string | undefined;
    controlNamesForFocus = ["calculatedRequiredGrossIRR", "mostRecentCashflowDate", "cashflowAmountLowCalc", "cashflowAmountBaseCalc", "cashflowAmountHighCalc"];

    ngOnInit() {
        this.store.select(selectDiscountMultipleValuation).pipe(map((discountMultipleValuation) => {
            this.isEditable = discountMultipleValuation.discountMultipleValuationEditable;
            this.rationaleIsEditable = discountMultipleValuation.rationaleIsEditable;

            this.persistedAssetValuation = discountMultipleValuation.persistedAssetValuation;
            this.persistedScenarios = discountMultipleValuation.persistedScenarios;
            this.calculatedAssetValuation = discountMultipleValuation.calcAssetValuation;
            this.calculatedScenarios = discountMultipleValuation.calcScenarios;
            this.fundCurrency = discountMultipleValuation.fundCurrency;

            const rowCashflow = discountMultipleValuation.tableDataSource.find(row => row.definition === "cashflowAmount");
            if (!!rowCashflow) {
                this.discountMultipleValuationForm.patchValue({
                    cashflowAmountLowCalc: DecimalFormatPipe.transformFromMillionsNum((rowCashflow.lowCalc as TraceableMoneyWithCurrency)?.amount ?? undefined),
                    cashflowAmountBaseCalc: DecimalFormatPipe.transformFromMillionsNum((rowCashflow.baseCalc as TraceableMoneyWithCurrency)?.amount ?? undefined),
                    cashflowAmountHighCalc: DecimalFormatPipe.transformFromMillionsNum((rowCashflow.highCalc as TraceableMoneyWithCurrency)?.amount ?? undefined)
                });
            }
            this.discountMultipleValuationForm.patchValue({
                calculatedRequiredGrossIRR: DecimalFormatPipe.transformFractionToPercent(discountMultipleValuation.calculatedRequiredGrossIRR.fraction ?? 0.25),
                mostRecentCashflowDate: discountMultipleValuation.mostRecentCalcCashflowDate
            });

            this.rationaleForm.controls.rationale.patchValue(discountMultipleValuation.rationale.text ?? undefined);

            if (this.isEditable) {
                // Use a setTimeout to refocus on the next Javascript event loop tick,
                // giving Angular a chance to update the DOM first
                setTimeout(() => {
                    if (!!this.nextFocus) {
                        this.renderer.selectRootElement("#" + this.nextFocus).focus();
                    }
                }, 0);
                this.discountMultipleValuationForm.enable();
            } else {
                this.discountMultipleValuationForm.disable();
            }
        })).subscribe();
    }

    editRationaleMode = () => this.store.dispatch(AssetDiscountValuationActions.editrationale());

    cancelRationale = () => this.store.dispatch(AssetDiscountValuationActions.cancelrational());

    saveRationale() {
        if (this.rationaleForm.valid) {
            const assetValuationToSave: AssetValuation = {
                ...this.persistedAssetValuation,
                rationale: {
                    ...this.persistedAssetValuation.rationale,
                    text: this.rationaleForm.getRawValue().rationale
                }
            };
            this.store.dispatch(AssetDiscountValuationActions.saverationale({assetValuation: assetValuationToSave}));
        }
    }

    calcAndSave(focus: string, save = false) {
        if (focus.length > 0) {
            const currentIndex = this.controlNamesForFocus.findIndex(control => control === focus);
            this.nextFocus = this.controlNamesForFocus[currentIndex + 1] || this.controlNamesForFocus[0];
        }
        if (this.discountMultipleValuationForm.valid) {
            const updatedAssetValuation = this.assetValuationForCalc();
            const updatedScenarios = this.scenarioForCalc(this.persistedScenarios, this.discountMultipleValuationForm);
            this.store.dispatch(AssetDiscountValuationActions.calc({assetValuation: updatedAssetValuation, scenarios: updatedScenarios, save}));
        }
    }

    assetValuationForCalc(): AssetValuation {
        const mostRecentCashflowDate =
            DateUtil.toIsoDate(this.discountMultipleValuationForm.controls.mostRecentCashflowDate.value)
            ?? this.persistedAssetValuation.exitDateAssumption.date;

        const requiredGrossIRR =
            DecimalFormatPipe.transformPercentToFraction(this.discountMultipleValuationForm.controls.calculatedRequiredGrossIRR.value)
            ?? this.persistedAssetValuation.requiredGrossIRR.fraction;

        return {
            ...this.persistedAssetValuation,
            requiredGrossIRR: {
                ...this.persistedAssetValuation.requiredGrossIRR,
                fraction: requiredGrossIRR
            },
            exitDateAssumption: {
                ...this.persistedAssetValuation.exitDateAssumption,
                date: mostRecentCashflowDate
            }
        };
    }

    scenarioForCalc(
        oldScenarios: AssetValuationScenario[],
        form: FormGroup<{
            mostRecentCashflowDate: FormControl<Date | null>;
            calculatedRequiredGrossIRR: FormControl<number | null>;
            cashflowAmountLowCalc: FormControl<number | null>;
            cashflowAmountBaseCalc: FormControl<number | null>;
            cashflowAmountHighCalc: FormControl<number | null>;
        }>
    ): AssetValuationScenario[] {
        const lowScenario = oldScenarios.find(s => s.scenario.code === "LOW");
        const baseScenario = oldScenarios.find(s => s.scenario.code === "BASE");
        const highScenario = oldScenarios.find(s => s.scenario.code === "HIGH");

        const updatedFormValues = form.getRawValue();
        const updatedScenarios: AssetValuationScenario[] = [];

        if (!!lowScenario) {
            const updatedLowScenario = this.createScenario(lowScenario, DecimalFormatPipe.transformToMillionsNum(updatedFormValues.cashflowAmountLowCalc));
            updatedScenarios.push(updatedLowScenario);
        }
        if (!!baseScenario) {
            const updatedBaseScenario = this.createScenario(baseScenario, DecimalFormatPipe.transformToMillionsNum(updatedFormValues.cashflowAmountBaseCalc));
            updatedScenarios.push(updatedBaseScenario);
        }
        if (!!highScenario) {
            const updatedHighScenario = this.createScenario(highScenario, DecimalFormatPipe.transformToMillionsNum(updatedFormValues.cashflowAmountHighCalc));
            updatedScenarios.push(updatedHighScenario);
        }
        return updatedScenarios;
    }

    private createScenario(scenario: AssetValuationScenario, cashflowAmount?: number): AssetValuationScenario {
        return {
            ...scenario,
            cashflows: [{
                id: scenario.cashflows.at(0)?.id ?? undefined,
                date: {...EMPTY_TRACEABLE, date: DateUtil.toIsoDate(this.discountMultipleValuationForm.controls.mostRecentCashflowDate.value)},
                cashflowType: {...EMPTY_TRACEABLE, code: "DISTRIBUTION"},
                amount: {...EMPTY_TRACEABLE, currencyIso: this.fundCurrency?.code ?? "UNKNOWN", amount: cashflowAmount},
                navMultiple: scenario.navMultiple ?? EMPTY_MULTIPLE
            } as AssetCashflow]
        };
    }

    editMode = () => this.store.dispatch(AssetDiscountValuationActions.edit());

    cancel = () => this.store.dispatch(AssetDiscountValuationActions.cancel());

    formControlIsEditable(name: string): boolean {
        const editableRows = ["cashflowAmount"];
        return editableRows.includes(name) && this.isEditable;
    }

    styleRowImportant(row: any): string {
        const importantRowTypes = ["cashflowAmount"];
        return (importantRowTypes.includes(row.definition)) ? "row-important" : "";
    }

    styleRowBorderTop(row: any): string {
        const borderTopRowTypes = ["navMultiple"];
        return (borderTopRowTypes.includes(row.definition)) ? "row-top-border" : "";
    }
}
