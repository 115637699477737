import {Component, Input, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {EMPTY_CODE, TraceableCode} from "../../model/traceable";
import {DateUtil} from "../../utils/date-util";
import {Code, CodeTableEnum} from "../../model/code";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {selectCodesFromTable} from "../../store/shared.selectors";

@Component({
    selector: "valumize-mergeable-select-multi-code",
    templateUrl: "./mergeable-select-multi-code.component.html",
    styleUrls: ["./mergeable-select-multi-code.component.scss"]
})
export class MergeableSelectMultiCodeComponent implements OnInit {

    @Input() label = "";
    @Input() control!: FormControl;
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() codes: TraceableCode[] = [EMPTY_CODE];
    @Input() comparativeCodes: TraceableCode[] = [
        {source: "", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, code: "AFRICA"},
        {source: "", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, code: "CENTRAL_AMERICA"},
        {source: "", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, code: "EUROPE"}
    ];
    @Input() codeTable!: CodeTableEnum;

    selectCodes$: Observable<Code[]> | undefined;

    constructor(private readonly store: Store) {
    }

    ngOnInit(): void {
        this.selectCodes$ = this.store.select(selectCodesFromTable(this.codeTable));
    }

    overwriteValue(baselineCode: TraceableCode) {
        if (this.isEditable && baselineCode.code) {
            const values: string[] = this.control.value;
            if (!values.includes(baselineCode.code)) {
                values.push(baselineCode.code);
            }
            this.control.setValue(values);
        }
    }

    getChipColor(baselineCode: TraceableCode) {
        if (this.control.value.includes(baselineCode.code) && !this.codes.find(code => code.code === baselineCode.code)) {
            return "chip-overwritten-value";
        } else if (this.codes.find(code => code.code === baselineCode.code)) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }
}
