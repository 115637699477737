import {Code} from "../model/code";

export class CodeUtil {
    static sortBySortIndex(codes: Code[], ascending = true): Code[] {
        if (codes.some(code => code.parameters.sortIndex !== null
            && code.parameters.sortIndex !== undefined)) {
            return codes.sort((code1, code2) => {
                const value1 = this.getSortIndexValue(code1.parameters, ascending);
                const value2 = this.getSortIndexValue(code2.parameters, ascending);

                const result = ascending ? value1 - value2 : value2 - value1;
                if (!result) {
                    return code1.translations.en.localeCompare(code2.translations.en);
                }
                return result;

            });
        }
        return this.sortByTranslations(codes);
    }

    static sortByTranslations(translatedCodes: Code[]): Code[] {
        return translatedCodes.sort((code1, code2) => code1.translations.en.localeCompare(code2.translations.en));
    }

    static getSortIndexValue(parameters: { [parameterName: string]: string } = {}, ascending = true): number {
        const sortIndex = +parameters["sortIndex"];
        return isNaN(sortIndex) ? ascending ? Number.MAX_VALUE : -Number.MAX_VALUE : sortIndex;
    }
}
