import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectSelectedFundValuationIsInit} from "../../store/fund-valuation/fund-valuation.selectors";

@Component({
    selector: "valumize-fund-cashflows",
    templateUrl: "./fund-cashflows.component.html"
})
export class FundCashflowsComponent {
    displaySecondaryColumns = false;

    selectedFundValuationIsInit$ = this.store.select(selectSelectedFundValuationIsInit);

    constructor(private readonly store: Store) {
    }

    toggleSecondaryColumns() {
        this.displaySecondaryColumns = !this.displaySecondaryColumns;
    }

}
