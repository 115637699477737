<h2 mat-dialog-title>{{confirmMessage}}</h2>
<mat-dialog-actions align="end">
    <button
        mat-stroked-button
        mat-dialog-close
        color="primary"
        (click)="confirm()">
        Confirm
    </button>
    <button
        mat-stroked-button
        mat-dialog-close
        (click)="cancel()">
        Cancel
    </button>
</mat-dialog-actions>

