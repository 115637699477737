import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {Store} from "@ngrx/store";
import {
    selectFundValuationScenarioIdFromType,
    selectSelectedFundValuationCashFlowsForScenarioType,
    selectSelectedFundValuationId
} from "../../../store/fund-valuation/fund-valuation.selectors";
import {ScenarioType} from "../../../../shared/model/scenario-type";
import {FundValuationAggregatedCashflowAction} from "../../../store/fund.actions";
import {find, map, Observable, Subscription} from "rxjs";
import {ApiDataModel} from "../../../../shared/model/api-data-model";
import {AggregatedFundCashflows} from "../../../models/aggregated-fund-cashflows";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {selectSelectedFundId} from "../../../store/fund/fund.selectors";
import {selectSelectedFundReportId} from "../../../store/fund-report/fund-report.selectors";
import {Router} from "@angular/router";
import {FundCashflow} from "../../../models/fund-cashflow";
import {selectFundInvestmentIdForAsset} from "../../../store/fund-investment/fund-investment.selectors";
import {DateTime} from "luxon";
import {AggregatedFundCashflowRecord} from "../../../models/aggregated-fund-cashflow-record";

@Component({
    selector: "valumize-fund-cashflow-table",
    templateUrl: "./fund-cashflow-table.component.html",
    styleUrls: ["./fund-cashflow-table.component.scss"]
})
export class FundCashflowTableComponent implements OnInit, OnDestroy {

    @ViewChild("popupTemplate") popupTemplate!: TemplateRef<any>;

    @Input() scenarioType: ScenarioType = "BASE";
    @Input() displaySecondaryColumns = false;

    fundId$ = this.store.select(selectSelectedFundId);
    fundReportId$ = this.store.select(selectSelectedFundReportId);
    fundValuationId$ = this.store.select(selectSelectedFundValuationId);

    fundId: number | undefined;
    fundReportId: number | undefined;
    fundValuationId: number | undefined;

    dialogRef!: MatDialogRef<any>;

    subscription?: Subscription;

    selectCashflowByScenarioType?: Observable<{ aggregatedFundCashFlows: ApiDataModel<AggregatedFundCashflows> | undefined }>;

    displayedColumnsGP = [
        "date",
        "fundAgeInQuarters",
        "assetDistribution",
        "assetDrawdown",
        "gpManagementFee",
        "gpUnfundedPayment",
        "gpUnfundedDistribution",
        "gpUnfundedNet",
        "purchasePrice",
        "gpGross",
        "gpCarryNet",
        "gpCarryPayment"
    ];

    displayedColumnsSecondary = [
        "secondaryCashflow",
        "secondaryManagementFee",
        "secondaryCarryNet",
        "secondaryCarryPayment",
        "secondaryNetCashflow"
    ];

    constructor(private readonly store: Store,
                private readonly dialog: MatDialog,
                private readonly router: Router,) {
    }

    ngOnInit(): void {
        if (this.scenarioType !== undefined) {
            this.subscription = this.store.select(selectFundValuationScenarioIdFromType(this.scenarioType)).pipe(map(scenarioId => {
                if (!!scenarioId) {
                    this.store.dispatch(FundValuationAggregatedCashflowAction.load({scenarioId, scenarioType: this.scenarioType}));
                }
            })).subscribe();
        }
        this.selectCashflowByScenarioType = this.store.select(selectSelectedFundValuationCashFlowsForScenarioType(this.scenarioType));
        this.fundId$.subscribe(id => this.fundId = id);
        this.fundReportId$.subscribe(id => this.fundReportId = id);
        this.fundValuationId$.subscribe(id => this.fundValuationId = id);
    }

    getDisplayedColumns() {
        return this.displaySecondaryColumns
            ? [...this.displayedColumnsGP, ...this.displayedColumnsSecondary]
            : this.displayedColumnsGP;
    }

    openPopup(element: AggregatedFundCashflowRecord, cashflowType: string): void {
        const fundCashflows = element.details.filter((detail: FundCashflow) => detail.cashflowType.code === cashflowType);
        const formattedDate = DateTime.fromISO(element.date).toFormat("dd/MM/yyyy");
        let title = cashflowType === "DISTRIBUTION" ? "Asset Cashflow" : "Asset Drawdown";
        title += " for " + formattedDate;

        if (fundCashflows.length > 0) {
            this.dialogRef = this.dialog.open(this.popupTemplate, {
                width: "350px",
                data: {details: fundCashflows, title}
            });
        }
    }

    navigateToAsset(detail: FundCashflow): void {
        if (detail.originalAssetId == null) {
            return;
        }

        this.store.select(selectFundInvestmentIdForAsset(detail.originalAssetId)).pipe(
            find(fundInvestmentId => fundInvestmentId != null)
        ).subscribe(fundInvestmentId => {
            this.router.navigate(["../../assets/", detail.originalAssetId], {
                queryParams: {
                    fundId: this.fundId,
                    fundReportId: this.fundReportId,
                    fundValuationId: this.fundValuationId,
                    fundInvestmentId,
                    assetValuationId: detail.originalAssetValuationId
                }
            });

            if (this.dialogRef) {
                this.dialogRef.close();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
