<div class="key-value-pair">
    <div class="key-value-item">{{label}}</div>
    <ng-container *ngIf="isDataset">
        <mat-chip (click)="overwriteValue()" [ngClass]="getChipColor()">{{baselineCode.code | codeFormat: codeTable | async}}</mat-chip>
    </ng-container>

    <div class="key-value-item">
        <div *ngIf="!isEditable">{{code.code | codeFormat: codeTable | async}}</div>
        <select [formControl]="control" *ngIf="isEditable && !searchable">
            <option *ngFor="let code of (selectCode$ | async)" [value]="code.code">{{ code.translations.en }}</option>
        </select>
        <div *ngIf="isEditable && searchable">
            <input type="text"
                   matInput
                   [formControl]="control"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let code of filteredCode$ | async" [value]="code.code">
                    <span>{{ code.code }}</span> | <small>{{ code.translations.en }}</small>
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>
</div>
