import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {
    selectAssetFinancialHistory,
    selectAssetFinancialHistoryEdit,
    selectBaselineAssetFinancialHistory,
    selectIsAnyAssetFormInEditMode,
    selectSelectedAssetId
} from "../../store/asset/asset.selectors";
import {map} from "rxjs/operators";
import {AssetFinancialHistoryRecord} from "../../models/asset/asset-financial-history-record";
import {filter, Subscription} from "rxjs";
import {AssetFinancialHistoryActions} from "../../store/asset/asset.actions";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {TraceableMoney, TraceablePeriod} from "../../../shared/model/traceable";
import {DateTime} from "luxon";
import {EMPTY_FINANCIAL_HISTORY_RECORD} from "../../store/asset.reducer";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {MatDialog} from "@angular/material/dialog";
import {ActionType, AssetHistoryPeriodDialogComponent} from "./asset-history-period-dialog/asset-history-period-dialog.component";
import {PeriodTypeEnum} from "../../models/asset/period-type.enum";

@Component({
    selector: "valumize-asset-fin-data-history",
    templateUrl: "./asset-fin-data-history.component.html",
    styleUrls: ["./asset-fin-data-history.component.scss"]
})
export class AssetFinDataHistoryComponent implements OnInit, OnDestroy {

    selectedAssetFinancialHistory$ = this.store.select(selectAssetFinancialHistory);
    selectedAssetId$ = this.store.select(selectSelectedAssetId);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    selectedAssetFinancialHistoryData: AssetFinancialHistoryRecord[] = [];
    baselineAssetFinancialHistoryData: AssetFinancialHistoryRecord[] = [];
    isEditable = false;
    isDataset = false;
    displayedColumns: string[] = [];
    selectedMetrics: string[] = [];
    alwaysDisplayRows = [
        "revenue",
        "revenueGrowth",
        "debtRepaymentCapacity",
        "netDebt",
        "enterpriseValue",
        "commonEquity"
    ];
    rowOrder: string[] = [
        "revenue",
        "revenueGrowth",
        "ebitdar",
        "ebitdarMargin",
        "ebitdarGrowth",
        "enterpriseValueOverEbitdarMultiple",
        "ebitda",
        "ebitdaMargin",
        "ebitdaGrowth",
        "enterpriseValueOverEbitdaMultiple",
        "ebita",
        "ebitaMargin",
        "ebitaGrowth",
        "enterpriseValueOverEbitaMultiple",
        "ebit",
        "ebitMargin",
        "ebitGrowth",
        "enterpriseValueOverEbitMultiple",
        "ebt",
        "ebtMargin",
        "ebtGrowth",
        "priceOverEarningsMultiple",
        "debtRepaymentCapacity",
        "netDebt",
        "enterpriseValue",
        "commonEquity"
    ];

    financialDataForms: FormGroup | undefined;
    subscriptions: Subscription[] = [];

    fieldDisplayNames: { [key: string]: string } = {
        revenue: "Revenue",
        revenueGrowth: "Revenue Growth",
        debtRepaymentCapacity: "Debt Repayment Capacity",
        netDebt: "Net Debt",
        enterpriseValue: "Enterprise Value",
        commonEquity: "Common Equity",
        ebt: "EBT",
        ebtMargin: "EBT Margin",
        ebtGrowth: "EBT Growth",
        ebit: "EBIT",
        ebitMargin: "EBIT Margin",
        ebitGrowth: "EBIT Growth",
        ebita: "EBITA",
        ebitaMargin: "EBITA Margin",
        ebitaGrowth: "EBITA Growth",
        ebitda: "EBITDA",
        ebitdaMargin: "EBITDA Margin",
        ebitdaGrowth: "EBITDA Growth",
        ebitdar: "EBITDAR",
        ebitdarMargin: "EBITDAR Margin",
        ebitdarGrowth: "EBITDAR Growth",
        priceOverEarningsMultiple: "P/E",
        enterpriseValueOverEbitMultiple: "EV/EBIT",
        enterpriseValueOverEbitaMultiple: "EV/EBITA",
        enterpriseValueOverEbitdaMultiple: "EV/EBITDA",
        enterpriseValueOverEbitdarMultiple: "EV/EBITDAR",
        enterpriseValueOverRevenueMultiple: "EV/Revenue"
    };
    fieldNames = Object.keys(this.fieldDisplayNames);

    // Metrics definitions for dynamic display in the table
    metrics = [
        {
            key: "ebt",
            displayName: "EBT",
            associatedRows: ["ebt", "ebtMargin", "ebtGrowth", "priceOverEarningsMultiple"]
        },
        {
            key: "ebit",
            displayName: "EBIT",
            associatedRows: ["ebit", "ebitMargin", "ebitGrowth", "enterpriseValueOverEbitMultiple"]
        },
        {
            key: "ebita",
            displayName: "EBITA",
            associatedRows: ["ebita", "ebitaMargin", "ebitaGrowth", "enterpriseValueOverEbitaMultiple"]
        },
        {
            key: "ebitda",
            displayName: "EBITDA",
            associatedRows: ["ebitda", "ebitdaMargin", "ebitdaGrowth", "enterpriseValueOverEbitdaMultiple"]
        },
        {
            key: "ebitdar",
            displayName: "EBITDAR",
            associatedRows: ["ebitdar", "ebitdarMargin", "ebitdarGrowth", "enterpriseValueOverEbitdarMultiple"]
        }
    ];
    protected readonly ACTION_TYPE = ActionType;

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.fetchFinancialHistoryData();

        this.subscriptions.push(
            this.selectedAssetFinancialHistory$.pipe(map((data) => {
                if (data?.data) {
                    this.selectedAssetFinancialHistoryData = [...data.data];


                    // Map the data to form groups for each financial record
                    const formGroups = data.data.map(record =>
                        this.formBuilder.group({
                            periodType: [record.period.type ?? ""],
                            periodKeyDate: [record.period.keyDate ?? ""],
                            revenue: [DecimalFormatPipe.transformFromMillionsNum(record.revenue.amount ?? undefined)],
                            netDebt: [DecimalFormatPipe.transformFromMillionsNum(record.netDebt.amount ?? undefined)],
                            ebt: [DecimalFormatPipe.transformFromMillionsNum(record.ebt.amount ?? undefined)],
                            ebit: [DecimalFormatPipe.transformFromMillionsNum(record.ebit.amount ?? undefined)],
                            ebita: [DecimalFormatPipe.transformFromMillionsNum(record.ebita.amount ?? undefined)],
                            ebitda: [DecimalFormatPipe.transformFromMillionsNum(record.ebitda.amount ?? undefined)],
                            ebitdar: [DecimalFormatPipe.transformFromMillionsNum(record.ebitdar.amount ?? undefined)],
                            enterpriseValue: [DecimalFormatPipe.transformFromMillionsNum(record.enterpriseValue.amount ?? undefined)],
                        }));

                    // Initialize the form group for financial data forms with an array of the created form groups
                    this.financialDataForms = this.formBuilder.group({
                        records: this.formBuilder.array(formGroups)
                    });

                    // Determine the columns to be displayed based on the key dates available in the data
                    this.displayedColumns = ["rowName"].concat(data.data
                        .filter(record => record.period.keyDate !== undefined && record.period.type !== undefined)
                        .map(record => `${record.period.keyDate}${record.period.type}`));

                    // Update the selected metrics based on the content of the metrics
                    this.selectedMetrics = this.metrics
                        .filter(metric => this.metricHasContent(metric.key))
                        .map(metric => metric.key);

                    // Call the method to update the rows that will be displayed
                    this.updateDisplayedRows();

                } else {
                    // If no data is present, reset the financial history data and the form
                    this.selectedAssetFinancialHistoryData = [];
                    this.financialDataForms = this.formBuilder.group({
                        records: this.formBuilder.array([])
                    });
                }
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectAssetFinancialHistoryEdit).pipe(map((isEditable) => {
                this.isEditable = isEditable;
                if (isEditable) {
                    this.financialDataForms?.enable();
                } else {
                    this.financialDataForms?.disable();
                }
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectBaselineAssetFinancialHistory).pipe(map((baselineAssetFinancialHistory) => {
                if (baselineAssetFinancialHistory.status === "LOADED") {
                    this.isDataset = true;
                    this.baselineAssetFinancialHistoryData = baselineAssetFinancialHistory.data;
                } else {
                    this.isDataset = false;
                }
            })).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    fetchFinancialHistoryData(): void {
        this.selectedAssetId$.pipe(
            filter(assetId => assetId !== undefined)
        ).subscribe(assetId => {
            if (assetId) {
                this.store.dispatch(AssetFinancialHistoryActions.load({assetId}));
            }
        });
    }

    // retrieves the value of a field for a given key date
    getFieldValue(keyDate: string | undefined, type: string | undefined, fieldName: string, isBaseline: boolean): any {
        const historyData = isBaseline ? this.baselineAssetFinancialHistoryData : this.selectedAssetFinancialHistoryData;
        const record = historyData.find(r => r.period.keyDate === keyDate && r.period.type === type);
        return record ? (record as any)[fieldName] : null;
    }

    save() {
        if (this.financialDataForms?.valid) {
            const formRecords = (this.financialDataForms?.get("records") as FormArray).controls;

            // Ensure there are no duplicate key dates
            const keyDates = formRecords.map(group => group.get("periodKeyDate")?.value + group.get("periodType")?.value);
            const uniqueKeyDates = new Set(keyDates);
            if (uniqueKeyDates.size !== keyDates.length) {
                this.store.dispatch(AssetFinancialHistoryActions.saveerror({errorMsg: "Duplicate key dates are not allowed."}));
                return;
            }

            const assetHistoryToSave: AssetFinancialHistoryRecord[] = formRecords.map((group, index) => {
                const record = this.selectedAssetFinancialHistoryData[index];
                return {
                    ...record,
                    period: {
                        ...record.period,
                        type: group.get("periodType")?.value,
                        keyDate: group.get("periodKeyDate")?.value
                    },
                    revenue: {
                        ...record.revenue,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("revenue")?.value)
                    },
                    netDebt: {
                        ...record.netDebt,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("netDebt")?.value)
                    },
                    ebt: {
                        ...record.ebt,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("ebt")?.value)
                    },
                    ebit: {
                        ...record.ebit,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("ebit")?.value)
                    },
                    ebita: {
                        ...record.ebita,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("ebita")?.value)
                    },
                    ebitda: {
                        ...record.ebitda,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("ebitda")?.value)
                    },
                    ebitdar: {
                        ...record.ebitdar,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("ebitdar")?.value)
                    },
                    enterpriseValue: {
                        ...record.enterpriseValue,
                        amount: DecimalFormatPipe.transformToMillionsNum(group.get("enterpriseValue")?.value)
                    },
                };
            });

            this.store.dispatch(AssetFinancialHistoryActions.save({financialHistory: assetHistoryToSave}));
        }
    }

    edit() {
        this.store.dispatch(AssetFinancialHistoryActions.edit());
    }

    cancel() {
        this.fetchFinancialHistoryData();
        this.store.dispatch(AssetFinancialHistoryActions.cancel());
    }


    // calculates and updates a record based on a given key date and field name
    calc(keyDate?: string, type?: string, fieldName?: string) {

        if (typeof fieldName === "string" && typeof keyDate === "string" && typeof type === "string") {
            // Find the financial record matching the given key date
            const recordToUpdate = this.selectedAssetFinancialHistoryData.find(r => r.period.keyDate === keyDate && r.period.type === type);
            if (!recordToUpdate) {
                console.error(`No record found for keyDate: ${keyDate}`);
                return;
            }

            // Update the financial record with the new value for the specified field
            const updatedRecord = this.updateFinancialHistoryRecord(recordToUpdate, fieldName, this.getFormControl(keyDate, type, fieldName).value);
            // Map over the financial history data and replace the record with the updated one
            const updatedRecords = this.selectedAssetFinancialHistoryData.map(r =>
                r.period.keyDate === keyDate && r.period.type === type ? updatedRecord : r
            );

            // Dispatch an action to update the store with the new financial history records
            this.store.dispatch(AssetFinancialHistoryActions.calc({
                financialHistoryRecord: updatedRecords
            }));
        } else {
            this.store.dispatch(AssetFinancialHistoryActions.calc({
                financialHistoryRecord: this.selectedAssetFinancialHistoryData
            }));
        }
    }

    updateFinancialHistoryRecord(record: AssetFinancialHistoryRecord, fieldName: string, newValue: any): AssetFinancialHistoryRecord {
        return {
            ...record,
            [fieldName]: {
                ...((record as any)[fieldName]),
                amount: DecimalFormatPipe.transformToMillionsNum(newValue)
            }
        };
    }

    // retrieves FormControl for a specific field based on the key date
    getFormControl(keyDate: string | undefined, type: string | undefined, fieldName: string): FormControl {
        const index = this.selectedAssetFinancialHistoryData
            .findIndex(r => r.period.keyDate === keyDate && r.period.type === type);
        const formArray = this.financialDataForms?.get("records") as FormArray;
        const control = (formArray?.at(index) as FormGroup)?.get(fieldName);

        if (!control) {
            throw new Error(`FormControl not found for keyDate: ${keyDate} and fieldName: ${fieldName}`);
        }

        return control as FormControl;
    }

    openPeriodSelectorDialog(action: ActionType, index?: number): void {

        let periodKeyDate: string | undefined;
        let periodType: PeriodTypeEnum | undefined;
        let existingPeriods: { periodType: string | undefined; periodKeyDate: string | undefined }[] = this.getExistingPeriodsFromHistoryData();

        if (action === ActionType.Edit && index != null && index >= 0) {
            const recordFormGroup = (this.financialDataForms?.get("records") as FormArray)?.at(index) as FormGroup;
            periodType = recordFormGroup.get("periodType")?.getRawValue();
            periodKeyDate = recordFormGroup.get("periodKeyDate")?.getRawValue();
            existingPeriods = existingPeriods.filter(p => p.periodKeyDate !== periodKeyDate);
        }

        const dialogRef = this.dialog.open(AssetHistoryPeriodDialogComponent, {
            data: {
                action,
                existingPeriods,
                periodType,
                periodKeyDate
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.action === ActionType.Edit) {
                    this.selectedAssetFinancialHistoryData = this.selectedAssetFinancialHistoryData.map(r =>
                        r.period.keyDate === result.originPeriodKeyDate && r.period.type === result.originPeriodType ?
                            {
                                ...r,
                                period: {
                                    ...r.period,
                                    keyDate: result.periodKeyDate,
                                    type: result.periodType
                                }
                            } : r
                    );
                    this.calc();

                } else if (result.action === ActionType.Add) {

                    const newRecord: AssetFinancialHistoryRecord = {
                        ...EMPTY_FINANCIAL_HISTORY_RECORD,
                        period: {
                            ...EMPTY_FINANCIAL_HISTORY_RECORD.period,
                            type: result.periodType,
                            keyDate: result.periodKeyDate
                        }
                    };

                    this.selectedAssetFinancialHistoryData.push(newRecord);

                    this.selectedAssetFinancialHistoryData.forEach((record) => {
                        console.log("ADD - " + JSON.stringify(record.period.keyDate) + " - " + JSON.stringify(record.period.type));
                    });

                    this.calc();

                } else if (result.action === ActionType.Delete) {

                    const recIndex = this.selectedAssetFinancialHistoryData.findIndex(r => r.period.keyDate === result.originPeriodKeyDate);
                    if (recIndex !== -1) {
                        this.selectedAssetFinancialHistoryData.splice(recIndex, 1);
                    }
                    this.calc();

                }
            }
        });
    }

    getExistingPeriodsFromHistoryData(): { periodType: string | undefined; periodKeyDate: string | undefined }[] {
        return this.selectedAssetFinancialHistoryData.map(record =>
            ({
                periodType: record.period.type,
                periodKeyDate: record.period.keyDate
            })
        );
    }

    // retrieves the period details from a form group at a given index
    getPeriodFromFormGroup(index: number): TraceablePeriod {
        const recordsArray = this.financialDataForms?.get("records") as FormArray;
        const recordFormGroup = recordsArray?.at(index) as FormGroup;
        return {
            type: recordFormGroup.get("periodType")?.value,
            keyDate: recordFormGroup.get("periodKeyDate")?.value
        };
    }

    getLastModified(): string | null {
        let mostRecentDate: DateTime | null = null;

        for (const record of this.selectedAssetFinancialHistoryData) {
            for (const field of this.fieldNames) {
                const currentField = (record as any)[field];

                if (currentField?.modDate) {
                    const currentModDate = DateTime.fromISO(currentField.modDate);

                    if (!mostRecentDate || currentModDate > mostRecentDate) {
                        mostRecentDate = currentModDate;
                    }
                }
            }
        }
        return mostRecentDate ? mostRecentDate.toISO() : null;
    }

    isFieldEditable(fieldName: string): boolean {
        const editableFields = ["revenue", "netDebt", "ebt", "ebit", "ebita", "ebitda", "ebitdar", "enterpriseValue"];
        return editableFields.includes(fieldName);
    }

    styleRowImportant(fieldName: string): string {
        const importantRowTypes = ["revenue", "netDebt", "ebt", "ebit", "ebita", "ebitda", "ebitdar"];
        return importantRowTypes.includes(fieldName) ? "row-important" : "";
    }


    // ============ METRIC DISPLAY LOGIC ============

    // controls selection of metrics
    toggleMetric(metric: { key: string }) {
        const index = this.selectedMetrics.indexOf(metric.key);
        if (index > -1) {
            this.selectedMetrics.splice(index, 1);
        } else {
            this.selectedMetrics.push(metric.key);
        }
        this.updateDisplayedRows();
    }

    isSelected(metric: { key: string }): boolean {
        return this.selectedMetrics.includes(metric.key);
    }

    updateDisplayedRows() {
        let rowsToShow = [...this.alwaysDisplayRows];

        for (const metricKey of this.selectedMetrics) {
            const metric = this.metrics.find(m => m.key === metricKey);
            if (metric) {
                rowsToShow = rowsToShow.concat(metric.associatedRows);
            }
        }

        rowsToShow = this.sortBy(rowsToShow);

        this.fieldNames = rowsToShow;
    }

    sortBy(data: string[]): any {
        const order = this.rowOrder;
        const ordering: any = {};
        for (let i = 0; i < order.length; i++) {
            ordering[order[i]] = i;
        }
        return data.sort((a: any, b: any) =>
            (ordering[a] - ordering[b]) || a.localeCompare(b)
        );
    }

    metricHasContent(metricKey: string): boolean {
        return this.selectedAssetFinancialHistoryData.some(record => {
            const value = (record as any)[metricKey]?.amount;
            return value !== null && value !== undefined;
        });
    }

    overwriteValue(isEditable: boolean, control: FormControl, baselineValue: TraceableMoney) {
        if (isEditable) {
            control.setValue(DecimalFormatPipe.transformFromMillionsNum(baselineValue.amount ?? undefined));
        }
    }

    getChipColor(control: FormControl, baselineValue: TraceableMoney, value: TraceableMoney) {
        const baselineAmount = DecimalFormatPipe.transformFromMillionsNum(baselineValue.amount ?? undefined);
        const amount = DecimalFormatPipe.transformFromMillionsNum(value.amount ?? undefined);

        if (control.value === baselineAmount && baselineAmount !== amount) {
            return "chip-overwritten-value";
        } else if (baselineAmount === amount) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }
}
