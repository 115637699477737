<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Asset Overview</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <table mat-table [dataSource]="assetDataSource" matSort #assetSort="matSort">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
                <td mat-cell *matCellDef="let element">{{element.name | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
            </ng-container>

            <ng-container matColumnDef="currencyIso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Currency</th>
                <td mat-cell *matCellDef="let element">{{element.currencyIso.code | codeFormat: codeTableCurrency | async}}</td>
            </ng-container>

            <ng-container matColumnDef="activityDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Description</th>
                <td mat-cell *matCellDef="let element">{{element.activityDescription | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="gic">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>GIC</th>
                <td mat-cell *matCellDef="let element">{{element.gic | traceableFormat}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: columns"
                [routerLink]="row.id"></tr>
        </table>

        <mat-paginator
            #assetPaging
            [pageSizeOptions]="[10, 20, 30]"
            [pageSize]="20"
            showFirstLastButtons>
        </mat-paginator>
    </mat-card-content>
    <mat-card-actions class="overview-actions">
        <button mat-stroked-button color="primary" (click)="openNewAssetDialog()" data-test="new-asset">
            <mat-icon>add</mat-icon>
            Company
        </button>
    </mat-card-actions>
</mat-card>
