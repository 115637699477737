/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {FundInvestmentActions, FundReportActions, FundValuationActions, FundValuationScenarioActions, PartnershipInvestmentActions} from "../fund.actions";
import {FundInvestmentService} from "../../services/fund-investment.service";
import {Store} from "@ngrx/store";
import {GeneralPartnerValuationActions} from "../../../asset/store/asset/asset.actions";
import {FundReport} from "../../models/fund-report";
import {selectSelectedFundReport, selectSelectedFundReportId} from "../fund-report/fund-report.selectors";
import {FundReportService} from "../../services/fund-report.service";
import {selectSelectedFundId} from "../fund/fund.selectors";
import {selectSelectedFundValuation, selectSelectedFundValuationId, selectSelectedFundValuationScenarios} from "../fund-valuation/fund-valuation.selectors";
import {FundValuationService} from "../../services/fund-valuation.service";
import {FundValuation} from "../../models/fund-valuation";

export const loadFundInvestment = createEffect(
    (actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.load),
            exhaustMap((action) => {
                return fundInvestmentService.getFundInvestment(action.fundId, action.fundReportId, action.fundInvestmentId).pipe(
                    switchMap((fundInvestment) => of(
                        FundInvestmentActions.loaded({fundInvestment}),
                        !!fundInvestment.id
                            ? GeneralPartnerValuationActions.load({fundInvestmentId: fundInvestment.id})
                            : GeneralPartnerValuationActions.loaderror({errorMsg: "Fund investment id is required"})
                    )),
                    catchError((error: { message: string }) =>
                        of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const updateFundInvestment = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.save),
            exhaustMap((action) => {
                if (!!action.fundInvestment.id) {
                    return fundInvestmentService.updateFundInvestment(action.fundId, action.fundReportId, action.fundInvestment.id, action.fundInvestment).pipe(
                        switchMap((fundInvestment) => of(
                            FundInvestmentActions.savedloaded({fundInvestment}),
                            !!fundInvestment.id
                                ? GeneralPartnerValuationActions.load({fundInvestmentId: fundInvestment.id})
                                : GeneralPartnerValuationActions.loaderror({errorMsg: "Fund investment id is required"})
                        )),
                        catchError((error: { message: string }) =>
                            of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundInvestmentActions.loaderror({errorMsg: "Fund investment id is required"}));
                }
            })
        );
    },
    {functional: true}
);


export const loadFundInvestments = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.loadall),
            exhaustMap((action) =>
                fundInvestmentService.getInvestmentsForFundReport(action.fundId, action.fundReportId).pipe(
                    switchMap((fundInvestments) => of(
                        FundInvestmentActions.loadedall({fundInvestments}),
                        PartnershipInvestmentActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                    )),
                    catchError((error: { message: string }) =>
                        of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const saveFundInvestments = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.saveall),
            withLatestFrom(store$.select(selectSelectedFundValuationId)),
            exhaustMap(([action, selectedFundValuationId]) => {
                if (!!action.fundId) {
                    return fundInvestmentService.updateFundInvestments(
                        action.fundId,
                        action.fundReportId,
                        action.fundInvestments
                    ).pipe(
                        switchMap((fundInvestments) => of(
                            FundInvestmentActions.loadedall({fundInvestments}),
                            PartnershipInvestmentActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: selectedFundValuationId})
                        )),
                        catchError((error: { message: string }) =>
                            of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundInvestmentActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadPartnershipInvestment = createEffect(
    (actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.load),
            exhaustMap((action) => {
                return fundInvestmentService.getPartnershipInvestment(action.fundId, action.fundReportId, action.fundValuationId).pipe(
                    map((partnershipInvestment) => PartnershipInvestmentActions.loaded({partnershipInvestment})),
                    catchError((error: { message: string }) =>
                        of(PartnershipInvestmentActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const savePartnershipInvestmentFundReport = createEffect(
    (
        store$ = inject(Store),
        actions$ = inject(Actions),
        fundReportService = inject(FundReportService),
    ) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReport), store$.select(selectSelectedFundValuationId)),
            exhaustMap(([action, selectedFundId, selectedFundReport, selectedFundValuationId]) => {
                const fundReport: FundReport = selectedFundReport.fundReport.data;
                if (!!selectedFundId) {
                    return fundReportService.save(
                        selectedFundId,
                        {
                            ...fundReport,
                            ...{
                                gpCarryReserve: {
                                    ...fundReport.gpCarryReserve,
                                    amount: action.partnershipInvestment.gpCarryReserve.amount
                                },
                                netCurrentAssets: {
                                    ...fundReport.netCurrentAssets,
                                    amount: action.partnershipInvestment.netCurrentAssets.amount
                                },
                                netCurrentAssetsDate: {
                                    ...fundReport.netCurrentAssetsDate,
                                    date: action.partnershipInvestment.netCurrentAssetsDate.date
                                },
                                totalNav: {
                                    ...fundReport.totalNav,
                                    amount: action.partnershipInvestment.totalGpNAV.amount
                                },
                                totalRemainingCosts: {
                                    ...fundReport.totalRemainingCosts,
                                    amount: action.partnershipInvestment.totalRemainingCost.amount
                                },
                                totalTVPI: {
                                    ...fundReport.totalTVPI,
                                    factor: action.partnershipInvestment.totalTVPI.factor
                                },
                                unrealizedTVPI: {
                                    ...fundReport.unrealizedTVPI,
                                    factor: action.partnershipInvestment.totalUnrealizedTVPI.factor
                                },
                                totalOfUnrealizedAssets: {
                                    ...fundReport.totalOfUnrealizedAssets,
                                    amount: action.partnershipInvestment.totalOfUnrealizedAssets.amount
                                }
                            }
                        })
                        .pipe(
                            switchMap((fundReportResponse) => {
                                return of(
                                    FundReportActions.loaded({fundReport: fundReportResponse}),
                                    !!fundReportResponse.id
                                        ? PartnershipInvestmentActions.load({fundId: selectedFundId, fundReportId: fundReportResponse.id, fundValuationId: selectedFundValuationId})
                                        : PartnershipInvestmentActions.loaderror({errorMsg: "Fund Report could not be saved"})
                                );
                            }),
                            catchError((error: { message: string }) =>
                                of(FundReportActions.loaderror({errorMsg: error.message}))
                            )
                        );
                } else {
                    return of(FundReportActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const savePartnershipInvestmentFundValuation = createEffect(
    (
        store$ = inject(Store),
        actions$ = inject(Actions),
        fundValuationService = inject(FundValuationService)
    ) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReportId), store$.select(selectSelectedFundValuation)),
            exhaustMap(([action, selectedFundId, selectedFundReportId, selectedFundValuation]) => {
                const fundValuation: FundValuation = selectedFundValuation.data;
                if (!!selectedFundId && !!selectedFundReportId) {
                    return fundValuationService.save(
                        selectedFundId,
                        {
                            ...fundValuation,
                            ...{
                                bidPriceAdjustment: {
                                    ...fundValuation.bidPriceAdjustment,
                                    amount: action.partnershipInvestment.bidPriceAdjustment.amount
                                },
                                finalBidPrice: {
                                    ...fundValuation.finalBidPrice,
                                    amount: action.partnershipInvestment.totalBid.amount
                                }
                            }
                        })
                        .pipe(
                            switchMap((fundValuationResponse) => {
                                return of(
                                    FundValuationActions.loaded({fundValuation: fundValuationResponse}),
                                    !!fundValuationResponse.id
                                        ? PartnershipInvestmentActions.load({fundId: selectedFundId, fundReportId: selectedFundReportId, fundValuationId: fundValuationResponse.id})
                                        : PartnershipInvestmentActions.loaderror({errorMsg: "Fund Valuation could not be saved"})
                                );
                            }),
                            catchError((error: { message: string }) =>
                                of(FundValuationActions.loaderror({errorMsg: error.message}))
                            )
                        );
                } else {
                    return of(FundValuationActions.loaderror({errorMsg: "Fund id and fund report id are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const savePartnershipInvestmentFundValuationScenarios = createEffect(
    (
        store$ = inject(Store),
        actions$ = inject(Actions),
        fundValuationService = inject(FundValuationService)
    ) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundValuationId), store$.select(selectSelectedFundValuationScenarios)),
            exhaustMap(([action, selectedFundId, selectedFundValuationId, selectedFundValuationScenarios]) => {
                if (!selectedFundId || !selectedFundValuationId) {
                    return of(FundValuationScenarioActions.loaderror({errorMsg: "Fund Id and Fund Valuation Id are required"}));
                }

                const updatedScenarios = selectedFundValuationScenarios.map(scenario => {
                    let amount = null;
                    switch(scenario.scenario.code) {
                        case "LOW":
                            amount = action.partnershipInvestment.netCurrentAssetsLow.amount;
                            break;
                        case "BASE":
                            amount = action.partnershipInvestment.netCurrentAssetsBase.amount;
                            break;
                        case "HIGH":
                            amount = action.partnershipInvestment.netCurrentAssetsHigh.amount;
                            break;
                    }

                    if (amount !== null) {
                        return {
                            ...scenario,
                            netCurrentAssets: {
                                ...scenario.netCurrentAssets,
                                amount
                            }
                        };
                    } else {
                        return scenario;
                    }
                });

                return fundValuationService.saveScenariosForFundValuation(selectedFundId, selectedFundValuationId, updatedScenarios)
                    .pipe(
                        switchMap(scenarios => of(FundValuationScenarioActions.loadedall({scenarios}))),
                        catchError(error => of(FundValuationScenarioActions.loaderror({errorMsg: error.message})))
                    );
            })
        );
    },
    {functional: true}
);

