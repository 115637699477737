<h2 mat-dialog-title>Add new Seller</h2>
<mat-dialog-content>
    <p>If the seller already exists, please select it.</p>
    <form [formGroup]="sellerForm">
        <div>
            <mat-form-field appearance="fill">
                <input matInput
                       formControlName="sellerName"
                       data-test="seller-name-input"
                       placeholder="Seller Name"
                       (input)="onSearch()">
            </mat-form-field>
        </div>
        <div class="name-actions">
            <button mat-icon-button mat-dialog-close color="primary" title="Add Seller" aria-label="Add Seller" data-test="save-button" [disabled]="sellerForm.invalid"
                    (click)="createSeller()" type="submit">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>
    <table mat-table [dataSource]="(filteredSellers$ | async) || []">
        <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef>Seller</th>
            <td mat-cell *matCellDef="let element">{{element.name | traceableFormat}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row mat-dialog-close
            *matRowDef="let row; columns: columns"
            [routerLink]="'deals/'+ dealId"
            [queryParams]="{sellerId: row.id}"
        ></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close data-test="save-button-bottom" [disabled]="sellerForm.invalid" (click)="createSeller()">Add Seller</button>
    <button mat-stroked-button mat-dialog-close data-test="cancel-button">Cancel</button>
</mat-dialog-actions>
