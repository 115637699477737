import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DealState} from "../deal.state";
import {dealStoreFeatureKey} from "../deal.reducer";

export const selectDealState = createFeatureSelector<DealState>(dealStoreFeatureKey);

export const selectSellers = createSelector(
    selectDealState,
    (state) => state.sellers.data
);

export const selectSelectedSeller = createSelector(
    selectDealState,
    (state) => state.selectedSeller.data
);

export const selectSelectedSellerId = createSelector(
    selectSelectedSeller,
    (state) => state.id
);

