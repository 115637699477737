<form [formGroup]="form">
    <div class="notes">
        <mat-card *ngIf="form.get('newNoteDescription')?.value === null" (click)="editNewNote()" class="new_note"
            data-test="new_note_add">
            <mat-card-content>
                <mat-icon>add</mat-icon>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="form.get('newNoteDescription')?.value !== null" class="note" data-test="new_note_edit">
            <mat-card-content>
                <textarea
                    cdkTextAreaAutosize
                    #newNoteTextarea
                    formControlName="newNoteDescription"
                    data-test="description"
                    (focusout)="createNote()"></textarea>
            </mat-card-content>
        </mat-card>
        <ng-container formArrayName="noteDescriptions" *ngFor="let note of notes; index as i">
            <mat-card class="note" data-test="note">
                <mat-card-header>
                    <div class="creator" data-test="creator" *ngIf="!!note.displayCreatedBy">
                        {{note.displayCreatedBy.displayName}}, {{note.displayCreatedDate | timestamp }}
                    </div>
                    <div class="creator" data-test="creator" *ngIf="!note.displayCreatedBy">
                        -
                    </div>
                    <div class="actions">
                        <mat-icon data-test="delte-action" (click)="deleteNote(note)">delete</mat-icon>
                    </div>
                </mat-card-header>
                <mat-card-content>
                        <textarea
                            cdkTextareaAutosize
                            #textAreaRef
                            [formControlName]="i"
                            data-test="description"
                            (focusout)="saveNote(note, textAreaRef)"></textarea>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
</form>
