import {Component, EventEmitter, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../../services/login/login.service";
import {AuthenticationService} from "../../../services/login/authentication.service";

@Component({
    selector: "valumize-login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"]
})
export class LoginFormComponent {
    @Output() loginCallback = new EventEmitter<string>();
    loginForm: FormGroup;
    errorMessage: string;

    constructor(private readonly loginService: LoginService,
                private readonly authService: AuthenticationService,
                private readonly formBuilder: FormBuilder) {

        this.loginForm = this.formBuilder.group({
            username: new FormControl("", Validators.required),
            password: new FormControl("", Validators.required),
        });
        this.errorMessage = "";
    }

    login(): void {
        this.errorMessage = "";
        this.authService.login(this.loginForm.controls["username"].value, this.loginForm.controls["password"].value).subscribe({
            next: () => {
                this.loginCallback.emit();
            },
            error: error => {
                if (error.status === 401 || error.status === 403) {
                    this.errorMessage = "Username or Password incorrect";
                } else {
                    this.errorMessage = `There has been an error (${error.statusText})`;
                }
            }
        });
    }

}
