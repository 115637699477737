import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {LoginService} from "../../../services/login/login.service";
import {AuthenticationService} from "../../../services/login/authentication.service";

@Component({
    selector: "valumize-access-denied",
    templateUrl: "./access-denied.component.html"
})
export class AccessDeniedComponent {
    constructor(private readonly authenticationService: AuthenticationService,
                private readonly loginService: LoginService,
                private readonly activatedRoute: ActivatedRoute) {
    }

    async logout(): Promise<void> {
        await this.authenticationService.logout();
        const redirectUrl = this.activatedRoute.snapshot.queryParams["redirect"];
        await firstValueFrom(this.loginService.showLoginPage(redirectUrl));
    }
}
