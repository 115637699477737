import {Component, Input} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "valumize-delete-confirmation",
    templateUrl: "./confirmation.component.html"
})
export class ConfirmationComponent {

    @Input() confirmMessage?: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationComponent>) {
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
