<ng-container *ngIf="importLogs$ | async as importLogs">
    <section class="table-container" tabindex="0">
        <table mat-table [dataSource]="importLogs">
            <ng-container matColumnDef="userName" sticky>
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">{{element.userName}}</td>
            </ng-container>

            <ng-container matColumnDef="started" sticky>
                <th mat-header-cell *matHeaderCellDef>Started</th>
                <td mat-cell *matCellDef="let element">{{element.started | date: "medium"}}</td>
            </ng-container>

            <ng-container matColumnDef="finished" sticky>
                <th mat-header-cell *matHeaderCellDef>Finished</th>
                <td mat-cell *matCellDef="let element">{{element.finished | date: "medium"}}</td>
            </ng-container>

            <ng-container matColumnDef="fileName" sticky>
                <th mat-header-cell *matHeaderCellDef>Filename</th>
                <td mat-cell *matCellDef="let element">{{element.fileName}}</td>
            </ng-container>

            <ng-container matColumnDef="dataset" sticky>
                <th mat-header-cell *matHeaderCellDef>Dataset</th>
                <td mat-cell *matCellDef="let element">{{element.dataset}}</td>
            </ng-container>

            <ng-container matColumnDef="status" sticky>
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{element.status | codeFormat: codeTableTaskInfoStatus | async}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef=" let row; columns: columns"></tr>
        </table>
    </section>
</ng-container>
