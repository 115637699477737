/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of, switchMap} from "rxjs";
import {AccelexImportActions, AccelexImportLogActions, DatasetActions} from "./import.actions";
import {AccelexImportService} from "../services/accelex-import.service";
import {DatasetService} from "../services/dataset.service";
import {MergeDataset} from "../models/merge-dataset";
import {DealListActions} from "../../deal/store/deal.actions";
import {ImportInfo} from "../models/import-info";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";


export const loadImportFiles = createEffect(
    (actions$ = inject(Actions), accelexImportService = inject(AccelexImportService)) => {
        return actions$.pipe(
            ofType(AccelexImportActions.load),
            exhaustMap(() =>
                accelexImportService.getImportFiles().pipe(
                    switchMap((fileNames) => of(
                        AccelexImportActions.loaded({fileNames}),
                        DealListActions.load()
                    )),
                    catchError((error: { message: string }) =>
                        of(AccelexImportActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const startImportJob = createEffect(
    (actions$ = inject(Actions), accelexImportService = inject(AccelexImportService)) => {
        return actions$.pipe(
            ofType(AccelexImportActions.import),
            exhaustMap((action) => {
                const importInfo: ImportInfo = {files: action.filesNames, dealId: action.dealId};
                return accelexImportService.startImportJob(importInfo).pipe(
                    map(() => AccelexImportActions.importsubmitted()),
                    catchError((error: { message: string }) =>
                        of(AccelexImportActions.importerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDatasets = createEffect(
    (actions$ = inject(Actions), datasetService = inject(DatasetService)) => {
        return actions$.pipe(
            ofType(DatasetActions.load),
            exhaustMap(() =>
                datasetService.getDatasets().pipe(
                    map((datasets) => DatasetActions.loaded({datasets})),
                    catchError((error: { message: string }) =>
                        of(DatasetActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

// @fixme: using tab and routing in a rxjs effect is not a good practice
// tap(() => router.navigate(["/funds"]).then((() => window.location.reload()))),
export const mergeDatasets = createEffect(
    (actions$ = inject(Actions), datasetService = inject(DatasetService), router = inject(Router)) => {
        return actions$.pipe(
            ofType(DatasetActions.merge),
            exhaustMap((action) => {
                const mergeDataset: MergeDataset = {
                    sourceDataset: action.source,
                    targetDataset: action.target
                };
                return datasetService.mergeDatasets(mergeDataset).pipe(
                    switchMap((dataset) => of(
                        DatasetActions.setsourceandtarget({source: dataset, target: undefined}),
                        DatasetActions.load()
                    )),
                    tap(() => router.navigate(["/funds"]).then((() => window.location.reload()))),
                    catchError((error: { message: string }) =>
                        of(DatasetActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const deleteDataset = createEffect(
    (actions$ = inject(Actions), datasetService = inject(DatasetService)) => {
        return actions$.pipe(
            ofType(DatasetActions.delete),
            exhaustMap((action) => {
                return datasetService.deleteDataset(action.dataset).pipe(
                    switchMap((dataset) => of(
                            DatasetActions.deleted({dataset}),
                            DatasetActions.load()
                        )
                    ),
                    catchError((error: { message: string }) =>
                        of(DatasetActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadImportLogs = createEffect(
    (actions$ = inject(Actions), accelexImportService = inject(AccelexImportService)) => {
        return actions$.pipe(
            ofType(AccelexImportLogActions.loadall),
            exhaustMap(() =>
                accelexImportService.getImportLogs().pipe(
                    map((importLogs) => AccelexImportLogActions.loadedall({importLogs})),
                    catchError((error: { message: string }) =>
                        of(AccelexImportLogActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);
