<mat-sidenav-container>
    <mat-sidenav #sidenav class="mat-elevation-z8" mode="side" opened>
        <div *ngIf="sidenav.opened" class="top-right-nav-toggle">
            <button (click)="sidenav.toggle()" color="primary" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div>
            <div class="sidenav-info">
                <ng-container *ngIf="reportDate$ | async as reportDate" class="report-date">
                    <div>
                        <div>
                            <label data-test="report-date-label">Report Date</label>
                            <p data-test="report-date">{{reportDate | traceableFormat}}</p>
                        </div>
                        <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                            <ng-container *ngIf="closingDate$ | async as closingDate">
                                <ng-container *ngIf="!!closingDate.date">
                                    <div style="display: flex">
                                        <div>
                                            <label data-test="closing-date-label">Closing Date</label>
                                            <p data-test="closing-date">{{closingDate | traceableFormat}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="(selectedAssetValuation$ | async) as selectedAssetValuation">
                                <ng-container *ngIf="selectedAssetValuation.data.reportDate.date; else selectOrCreateAssetValuation">
                                    <div>
                                        <label data-test="report-date-label">Final Bid Price</label>
                                        <p data-test="report-date">{{ selectedAssetValuation.data.finalBidPrice | traceableFormat }}</p>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #selectOrCreateAssetValuation>
                                <ng-container *ngIf="(selectedFundValuation$ | async) as fundValuation">
                                    <ng-container *ngIf="!!reportDate.date && !!fundValuation.data.closingDate.date; else noFundReportAndValuation">
                                        <ng-container *ngIf="!(fundInvestment$ | async)?.data?.exitDate?.date; else effectiveExitDateExists">
                                            <button (click)="openNewAssetValuationDialog(fundValuation.data)"
                                                    color="primary"
                                                    data-test="select-asset-valuation"
                                                    mat-stroked-button>
                                                <mat-icon>add</mat-icon>
                                                Asset Valuation
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noFundReportAndValuation>
                                    Asset valuation can only be added<br>
                                    if a fund valuation is selected first!
                                </ng-template>
                                <ng-template #effectiveExitDateExists>
                                    No asset valuation can be added<br>
                                    as this asset has already exited the fund.
                                </ng-template>
                            </ng-template>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngIf="(sourceDataset$ | async) !== 'master'">
                <div class="sidenav-info">
                    <h3>Merge Target</h3>
                </div>
                <div class="sidenav-info">
                    <div>
                        <div class="label-search">
                            <label>Selected Company</label>
                            <button (click)="displaySearch()"
                                    color="primary"
                                    data-test="search-merge-target-button"
                                    mat-icon-button>
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                        <ng-container *ngIf="searchEnabled">
                            <valumize-search
                                (searchResultSelected)="selectMergeTarget($event)"
                                [searchCategories]="['assets']"
                                [showSearchIcon]="false"
                                [targetSearch]="true">
                            </valumize-search>
                        </ng-container>
                        <ng-container *ngIf="baselineAssset$ | async as baselineAsset">
                            <div class="selected-asset">
                                <p class="selected-asset-name">{{baselineAsset.data.name | traceableFormat}}</p>
                                <ng-container *ngIf="baselineAsset.data.id">
                                    <button (click)="deselectAssetMergeTarget()"
                                            color="primary"
                                            data-test="cancel-button"
                                            mat-icon-button>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
            <mat-action-list>
                <a (click)="scrollToComponent('description')" data-test="company-description-nav-item">
                    <mat-list-item>Company Description</mat-list-item>
                </a>
                <a (click)="scrollToComponent('general-information')" data-test="general-information-nav-item">
                    <mat-list-item>General Information</mat-list-item>
                </a>
                <a (click)="scrollToComponent('end-markets-customers')" data-test="end-markets-nav-item">
                    <mat-list-item>End Markets</mat-list-item>
                </a>
                <a (click)="scrollToComponent('swot')" data-test="swot-nav-item">
                    <mat-list-item>SWOT</mat-list-item>
                </a>
                <a (click)="scrollToComponent('shareholder-structure')" data-test="shareholder-structure-nav-item">
                    <mat-list-item>Shareholder Structure</mat-list-item>
                </a>
                <a (click)="scrollToComponent('exit-information')" data-test="exit-information-nav-item">
                    <mat-list-item>Exit Information</mat-list-item>
                </a>
                <a (click)="scrollToComponent('gp-valuation')" data-test="gp-valuation-nav-item">
                    <mat-list-item>GP Valuation</mat-list-item>
                </a>
                <a (click)="scrollToComponent('fin-data-history')" data-test="fin-data-history">
                    <mat-list-item>Financial Data History</mat-list-item>
                </a>
                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <a (click)="scrollToComponent('discount-multiple-valuation')" data-test="discount-multiple-valuation-nav-item">
                        <mat-list-item>Discount Multiple Valuation</mat-list-item>
                    </a>
                </ng-container>
            </mat-action-list>
            <mat-divider></mat-divider>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div *ngIf="!sidenav.opened" class="top-left-nav-toggle">
            <button (click)="sidenav.toggle()" color="primary" mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="selectedAsset$ | async as selectedAsset">
            <label class="breadcrumb" data-test="breadcrumb">
                <ng-container *ngIf="deal$ | async as deal">
                    <ng-container *ngIf="!!deal.id">
                        <mat-icon>work_history</mat-icon>
                        <a [routerLink]="'../../deals/' + deal.id">{{deal.projectName.text}}</a>
                        <mat-icon>chevron_right</mat-icon>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedFund$ | async as fund">
                    <ng-container *ngIf="!!fund.fund.id">
                        <mat-icon>domain</mat-icon>
                        <a [queryParams]="{
                           fundReportId : fundReportId,
                           fundValuationId : fundValuationId,
                           dealId: (deal$ | async)?.id}"
                           [routerLink]="'../../funds/' + fund.fund.id"
                        >{{fund.fund.name | traceableFormat}}</a>
                        <mat-icon>chevron_right</mat-icon>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="sortedInvestments$ | async as sortedInvestments">
                    <ng-container *ngIf="!!sortedInvestments && sortedInvestments.length > 0">
                        <mat-icon>factory</mat-icon>
                        <select (change)="navigateTo($event.target, sortedInvestments)">
                            <option *ngFor="let investment of sortedInvestments"
                                    [selected]="investment.assetId === selectedAsset.data.id"
                                    [value]="investment.assetId">
                                {{investment.company | traceableFormat}}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="!sortedInvestments || sortedInvestments.length === 0">
                        <mat-icon>factory</mat-icon>
                        {{name.text}}
                    </ng-container>
                </ng-container>
            </label>
            <div class="page-content">
                <form [formGroup]="nameForm">
                    <div>
                        <h1 *ngIf="!assetNameIsEditable" data-test="asset-name" style="margin-top: 7px"
                            title="Last modified: {{name.modDate | timestamp}}">{{name.text}}</h1>
                        <mat-form-field *ngIf="assetNameIsEditable" appearance="fill">
                            <input data-test="asset-name-input"
                                   formControlName="assetName"
                                   matInput
                                   placeholder="Company Name">
                        </mat-form-field>
                    </div>
                    <div class="name-actions">
                        <button (click)="editMode()" *ngIf="!assetNameIsEditable" [disabled]="(isEditDisabled$ | async)!" color="primary" data-test="edit-button" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="save()" *ngIf="assetNameIsEditable" [disabled]="nameForm.invalid" color="primary" data-test="save-button" mat-icon-button>
                            <mat-icon>save</mat-icon>
                        </button>
                        <button (click)="cancel()" *ngIf="assetNameIsEditable" color="primary" data-test="cancel-button" mat-icon-button>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </form>
                <valumize-asset-description id="description"></valumize-asset-description>
                <valumize-asset-general-information class="component" id="general-information"></valumize-asset-general-information>
                <valumize-asset-end-markets-customers class="component" id="end-markets-customers"></valumize-asset-end-markets-customers>
                <valumize-asset-swot id="swot"></valumize-asset-swot>
                <div class="component-wrapper">
                    <valumize-asset-shareholder-structure class="component" id="shareholder-structure" style="width: 50%"></valumize-asset-shareholder-structure>
                    <valumize-asset-exit-information class="component" id="exit-information"></valumize-asset-exit-information>
                </div>
                <valumize-asset-gp-valuation id="gp-valuation"></valumize-asset-gp-valuation>
                <valumize-asset-fin-data-history id="fin-data-history"></valumize-asset-fin-data-history>
                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <valumize-asset-discount-multiple-valuation id="discount-multiple-valuation"></valumize-asset-discount-multiple-valuation>
                </ng-container>
                <br/>
                <br/>
                <br/>
            </div>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>
