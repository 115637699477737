import {createReducer, on} from "@ngrx/store";
import {ImportState} from "./import.state";
import {AccelexImportActions, AccelexImportLogActions, DatasetActions} from "./import.actions";

export const importStoreFeatureKey = "valumize/import";

export const INITIAL_STATE: ImportState = {
    selectedSourceDataset: "master",
    selectedTargetDataset: undefined,
    fileNames: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    datasets: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    importLogs: {
        data: [],
        status: "INIT",
        errorMessage: ""
    }
};

export const importReducer = createReducer(
    INITIAL_STATE,

    // Files
    on(AccelexImportActions.load, (state): ImportState => ({
        ...state,
        fileNames: {
            ...state.fileNames,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(AccelexImportActions.loaded, (state, action): ImportState => ({
        ...state,
        fileNames: {
            data: action.fileNames,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AccelexImportActions.loaderror, (state, action): ImportState => ({
        ...state,
        fileNames: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Datasets
    on(DatasetActions.load, (state): ImportState => ({
        ...state,
        datasets: {
            ...state.datasets,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DatasetActions.loaded, (state, action): ImportState => ({
        ...state,
        datasets: {
            data: action.datasets,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DatasetActions.loaderror, (state, action): ImportState => ({
        ...state,
        datasets: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(DatasetActions.setsourceandtarget, (state, action): ImportState => ({
        ...state,
        selectedSourceDataset: action.source,
        selectedTargetDataset: action.target
    })),

    // Import Logs
    on(AccelexImportLogActions.loadall, (state): ImportState => ({
        ...state,
        importLogs: {
            ...state.importLogs,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(AccelexImportLogActions.loadedall, (state, action): ImportState => ({
        ...state,
        importLogs: {
            data: action.importLogs,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AccelexImportLogActions.loaderror, (state, action): ImportState => ({
        ...state,
        importLogs: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    }))
);
