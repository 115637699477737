<h2 mat-dialog-title>Select Fund Report</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="filterFundReportsByDate((fundReportsForFund$ | async)?.data || [])">
        <ng-container matColumnDef="id" sticky>
            <th mat-header-cell *matHeaderCellDef>Fund Report ID</th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="reportDate" sticky>
            <th mat-header-cell *matHeaderCellDef>Report Date</th>
            <td mat-cell *matCellDef="let element">{{element.reportDate | traceableFormat}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>

        <tr mat-row *matRowDef="let row; columns: columns" (click)="selectMergeTarget(row.id)" mat-dialog-close></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-stroked-button mat-dialog-close
        data-test="cancel-button">
        Cancel
    </button>
</mat-dialog-actions>
