import {Component, Input, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {ActivatedRoute} from "@angular/router";
import {AssetValuationActions} from "../../store/asset/asset.actions";
import {selectSelectedAssetValuationsForAsset} from "../../store/asset/asset.selectors";
import {selectSelectedFundId} from "../../../fund/store/fund/fund.selectors";
import {selectSelectedFundReportId} from "../../../fund/store/fund-report/fund-report.selectors";
import {selectSelectedFundValuationId} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {selectSelectedFundInvestmentId} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {FundValuation} from "../../../fund/models/fund-valuation";
import {AssetValuation} from "../../models/asset-valuation/asset-valuation";
import {DateUtil} from "../../../shared/utils/date-util";

@Component({
    selector: "valumize-asset-valuation-new-dialog",
    templateUrl: "./asset-valuation-new-dialog.component.html",
    styleUrls: ["./asset-valuation-new-dialog.component.scss"]
})
export class AssetValuationNewDialogComponent implements OnInit {

    @Input() assetId?: number;
    @Input() fundValuation?: FundValuation;
    assetValuationsForAsset$ = this.store.select(selectSelectedAssetValuationsForAsset);
    columns = ["id", "reportDate", "bidPrice"];

    fundId$ = this.store.select(selectSelectedFundId);
    fundReportId$ = this.store.select(selectSelectedFundReportId);
    fundValuationId$ = this.store.select(selectSelectedFundValuationId);
    fundInvestmentId$ = this.store.select(selectSelectedFundInvestmentId);

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store
    ) {
    }

    ngOnInit() {
        if (!!this.assetId) {
            this.store.dispatch(AssetValuationActions.loadall({assetId: this.assetId}));
        }
    }

    filterByReportDate(assetValuations: AssetValuation[]): AssetValuation[] {
        const reportDateFundValuation = DateUtil.fromIsoDate(this.fundValuation?.reportDate.date);

        return !!reportDateFundValuation
            ? assetValuations.filter(av => DateUtil.fromIsoDate(av.reportDate.date)?.equals(reportDateFundValuation))
            : assetValuations;
    }
}
