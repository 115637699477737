<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title>Change password</mat-card-title>
        </mat-card-header>

        <mat-card-content class="detail-card-content">
            <mat-form-field>
                <mat-label data-test="current-password-label">Current password</mat-label>
                <input
                    [type]="passwordInputStates.currentPassword"
                    class="form-control"
                    data-test="current-password-input"
                    formControlName="currentPassword"
                    id="currentPassword" matInput required>
                <mat-icon matSuffix (click)="togglePassword('currentPassword')">
                    {{isPasswordType("currentPassword") ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>

            <mat-form-field>
                <mat-label data-test="new-password-label">New Password</mat-label>
                <input
                    [type]="passwordInputStates.newPassword"
                    class="form-control"
                    data-test="new-password-input"
                    formControlName="newPassword"
                    id="newPassword" matInput required>
                <mat-icon matSuffix (click)="togglePassword('newPassword')">
                    {{isPasswordType("newPassword") ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>

            <mat-form-field>
                <mat-label data-test="new-password-repeated-label">Confirm password</mat-label>
                <input
                    [type]="passwordInputStates.newPasswordRepeated"
                    class="form-control"
                    data-test="new-password-repeated-input"
                    formControlName="newPasswordRepeated"
                    id="newPasswordRepeated" matInput required>
                <mat-icon matSuffix (click)="togglePassword('newPasswordRepeated')">
                    {{isPasswordType("newPasswordRepeated") ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>

            <mat-error class="error-message"
                       *ngIf="changePasswordForm.controls?.['newPasswordRepeated']?.invalid
                       && changePasswordForm.controls?.['newPasswordRepeated']?.touched">
                {{repeatedPasswordDiffersErrorMessage}}
            </mat-error>
            <mat-error class="error-message" *ngIf="!!wrongPassword">{{wrongPassword}}</mat-error>
        </mat-card-content>

        <div class="buttons">
            <button type="button" [disabled]="updating" data-test="cancel-button" (click)="cancel()" mat-button>
                Abbrechen
            </button>

            <button type="submit" data-test="submit-button" [disabled]="!changePasswordForm.valid" mat-button>Change password
                <mat-icon>
                    <mat-spinner class="spinner" *ngIf="updating" mode="indeterminate" diameter="16"></mat-spinner>
                </mat-icon>
            </button>
        </div>
    </mat-card>
</form>

