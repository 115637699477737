import {SearchState} from "./search.state";
import {SearchQuery, SearchResult} from "../models/search";
import {createReducer, on} from "@ngrx/store";
import {SearchActions} from "./search.actions";

export const searchStoreFeatureKey = "valumize/search";

const EMPTY_SEARCH_QUERY: SearchQuery = {
    query: ""
};

export const INITIAL_STATE: SearchState = {
    searchResults: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    query: {
        data: EMPTY_SEARCH_QUERY,
        status: "INIT",
        errorMessage: ""
    }
};

export const searchReducer = createReducer(
    INITIAL_STATE,

    on(SearchActions.load, (state, action): SearchState => ({
        ...state,
        searchResults: {
            ...state.searchResults,
            status: "LOADING"
        },
        query: {
            data: action.query,
            status: "LOADING",
            errorMessage: ""
        }
    })),
    on(SearchActions.loaded, (state, action): SearchState => ({
        ...state,
        searchResults: {
            data: action.searchResults,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(SearchActions.loaderror, (state, action): SearchState => ({
        ...state,
        searchResults: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    }))
);
