import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {FundValuation} from "../models/fund-valuation";
import {FundApiService} from "../generated-sources/api";
import {FundValuationScenario} from "../models/fund-valuation-scenario";
import {UndrawnValuation} from "../models/undrawn-valuation";
import {AggregatedFundCashflows} from "../models/aggregated-fund-cashflows";
import {FundReturnSummary} from "../models/fund-return-summary";

@Injectable({
    providedIn: "root"
})
export class FundValuationService {

    constructor(private readonly fundApiService: FundApiService) {
    }

    public getValuationsForFund(fundId: number): Observable<FundValuation[]> {
        return this.fundApiService.getValuationsForFund(fundId);
    }

    public getFundValuation(fundId: number, fundValuationId: number): Observable<FundValuation> {
        return this.fundApiService.getFundValuation(fundId, fundValuationId);
    }

    public save(fundId: number, valuation: FundValuation): Observable<FundValuation> {
        const result: Observable<FundValuation> = !!valuation.id && fundId
            ? this.fundApiService.updateFundValuation(fundId, valuation.id, valuation)
            : this.fundApiService.createFundValuation(fundId, valuation);
        return result.pipe(map((valuationDto: FundValuation) => valuationDto));
    }

    public getScenariosForFundValuation(fundId: number, fundValuationId: number): Observable<FundValuationScenario[]> {
        return this.fundApiService.getScenariosForFundValuation(fundId, fundValuationId);
    }

    public getFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number) {
        return this.fundApiService.getFundValuationScenario(fundId, fundValuationId, scenarioId);
    }

    public saveScenario(fundId: number, fundValuationId: number, scenario: FundValuationScenario) {
        const result: Observable<FundValuationScenario> = !!scenario.id && !!fundId && !!fundValuationId
            ? this.fundApiService.updateFundValuationScenario(fundId, fundValuationId, scenario.id, scenario)
            : this.fundApiService.createFundValuationScenario(fundId, fundValuationId, scenario);
        return result.pipe(map((scenarioDto: FundValuationScenario) => scenarioDto));
    }

    public saveScenariosForFundValuation(fundId: number, fundValuationId: number, scenarios: FundValuationScenario[]): Observable<FundValuationScenario[]> {
        return this.fundApiService.updateScenariosForFundValuation(fundId, fundValuationId, scenarios);
    }

    public getAggregatedFundCashflows(fundId: number, fundValuationId: number, scenarioId: number): Observable<AggregatedFundCashflows> {
        return this.fundApiService.getAggregatedFundCashflows(fundId, fundValuationId, scenarioId);
    }

    public calcUndrawnValuation(fundId: number, fundValuationId: number, undrawnValuation: UndrawnValuation): Observable<UndrawnValuation> {
        return this.fundApiService.calculateUndrawnValuation(fundId, fundValuationId, undrawnValuation);
    }

    public calcReturnSummary(fundId: number, fundValuationId: number): Observable<FundReturnSummary> {
        return this.fundApiService.calculateFundReturnSummary(fundId, fundValuationId);
    }
}
