<div class="key-value-pair">
    <div class="key-value-item">{{label}}</div>
    <ng-container *ngIf="isDataset">
        <mat-chip (click)="overwriteValue()" [ngClass]="getChipColor()">{{baselineValue | traceableFormat}}</mat-chip>
    </ng-container>
    <div class="key-value-item">
        <div *ngIf="!isEditable">{{value | traceableFormat}}</div>
        <input class="key-value-item" *ngIf="isEditable" [formControl]="control" type="number">
    </div>
</div>
