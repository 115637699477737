import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {FundReportActions} from "../../store/fund.actions";
import {selectSelectedFundReportsForFund} from "../../store/fund-report/fund-report.selectors";
import {DateUtil} from "../../../shared/utils/date-util";

@Component({
    selector: "valumize-fund-report-new-dialog",
    templateUrl: "./fund-report-new-dialog.component.html",
    styleUrls: ["./fund-report-new-dialog.component.scss"]
})
export class FundReportNewDialogComponent implements OnInit {
    @Input() fundId?: number;
    fundReportsForFund$ = this.store.select(selectSelectedFundReportsForFund);
    columns = ["id", "reportDate"];

    fundReportForm = this.formBuilder.group({
        reportDate: this.formBuilder.control<Date>(DateUtil.jsDate(), {nonNullable: true, validators: Validators.required}),
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        if (!!this.fundId) {
            this.store.dispatch(FundReportActions.loadall({fundId: this.fundId, fundIsMergeTarget: false}));
        }
    }

    createFundReport() {
        if (this.fundReportForm.valid) {
            if (!this.fundId) {
                return;
            }
            this.store.dispatch(FundReportActions.create({
                fundId: this.fundId,
                reportDate: DateUtil.toIsoDate(this.fundReportForm.getRawValue().reportDate) as string
            }));
        }
    }
}
