import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Note } from "../../model/note";

@Component({
  selector: "valumize-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"]
})
export class NotesComponent implements OnChanges {

    @ViewChild("newNoteTextarea")
    newNoteTextareaElement?: ElementRef;

    @Input()
    notes: Note[] = [];

    @Output()
    save = new EventEmitter<Note>();

    @Output()
    delete = new EventEmitter<Note>();

    form = new FormGroup({
        noteDescriptions: new FormArray<FormControl<string | null>>([]),
        newNoteDescription: new FormControl<string | null>(null, { nonNullable: true })
    });

    ngOnChanges(changes: SimpleChanges): void {
        this.form.controls = {
            ...this.form.controls,
            noteDescriptions: new FormArray<FormControl<string | null>>(
                this.notes.map(n => {
                    const control = new FormControl(n.description, { nonNullable: true });
                    control.patchValue(n.description);
                    return control;
                })
            ),
        };
    }

    saveNote(note: Note, textArea: HTMLTextAreaElement): void {
        this.save.emit({
            ...note,
            description: textArea.value
        });
    }

    deleteNote(note: Note): void {
        this.delete.emit(note);
    }

    editNewNote(): void {
        this.form.controls = {
            ... this.form.controls,
            newNoteDescription: new FormControl<string | null>("", { nonNullable: true })
        };

        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            console.log(this.newNoteTextareaElement);
            this.newNoteTextareaElement?.nativeElement.focus();
          }, 10);
    }

    createNote(): void {
        const description = this.form.controls.newNoteDescription.getRawValue();
        if (!!description) {
            this.save.emit({
                context: "",
                description
            });
        }
        this.form.controls = {
            ... this.form.controls,
            newNoteDescription: new FormControl<string | null>(null, { nonNullable: true })
        };
    }
}
