<mat-sidenav-container>
    <mat-sidenav #sidenav class="mat-elevation-z8" mode="side" opened>
        <div *ngIf="sidenav.opened" class="top-right-nav-toggle">
            <button (click)="sidenav.toggle()" color="primary" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div>
            <div class="sidenav-info">
                <div>
                    <ng-container *ngIf="closingDate$ | async as closingDate">
                        <div>
                            <label data-test="closing-date-label">Closing Date</label>
                            <p data-test="closing-date">{{closingDate | traceableFormat}}</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedSeller$ | async as seller">
                        <ng-container *ngIf="!!seller.id; else noSeller">
                            <div style="display: flex">
                                <div>
                                    <label data-test="seller-label">Seller</label>
                                    <p data-test="seller">{{seller.name | traceableFormat}}</p>
                                </div>
                                <div>
                                    <button
                                        (click)="openNewSellerDialog()"
                                        color="primary"
                                        data-test="edit-seller-button"
                                        mat-icon-button>
                                        <mat-icon>manage_history</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noSeller>
                            <button (click)="openNewSellerDialog()"
                                    color="primary"
                                    data-test="create-or-select-seller"
                                    mat-stroked-button>
                                <mat-icon>add</mat-icon>
                                Seller
                            </button>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-action-list>
                <a (click)="scrollToComponent('deal-funds')" data-test="deal-funds-nav-item">
                    <mat-list-item>Funds</mat-list-item>
                </a>
                <a (click)="scrollToComponent('deal-companies')" data-test="deal-companies-nav-item">
                    <mat-list-item>Companies</mat-list-item>
                </a>
                <a (click)="scrollToComponent('deal-details')" data-test="deal-details-nav-item">
                    <mat-list-item>Deal Details</mat-list-item>
                </a>
                <a (click)="scrollToComponent('deal-cashflows-graph')" data-test="deal-cashflows-graph-nav-item">
                    <mat-list-item>Deal Cashflows Graph</mat-list-item>
                </a>
                <a (click)="scrollToComponent('deal-cashflows')" data-test="deal-cashflows-nav-item">
                    <mat-list-item>Deal Cashflows</mat-list-item>
                </a>
            </mat-action-list>
            <mat-divider></mat-divider>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div *ngIf="!sidenav.opened" class="top-left-nav-toggle">
            <button (click)="sidenav.toggle()" color="primary" mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="dealName$ | async as dealName">
            <label class="breadcrumb" data-test="breadcrumb">
                <mat-icon>work_history</mat-icon>
                {{dealName.name | traceableFormat}}
            </label>
            <div class="page-content">
                <form [formGroup]="dealNameForm">
                    <div>
                        <h1 *ngIf="!dealNameIsEditable" data-test="deal-name" style="margin-top: 7px">{{dealName.name | traceableFormat}}</h1>
                        <mat-form-field *ngIf="dealNameIsEditable" appearance="fill">
                            <input data-test="deal-name-input"
                                   formControlName="dealName"
                                   matInput
                                   placeholder="Deal Name">
                        </mat-form-field>
                    </div>
                    <div class="name-actions">
                        <button (click)="editMode()" *ngIf="!dealNameIsEditable" [disabled]="(isEditDisabled$ | async)!" color="primary" data-test="edit-button" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="save()" *ngIf="dealNameIsEditable" [disabled]="dealNameForm.invalid" color="primary" data-test="save-button" mat-icon-button>
                            <mat-icon>save</mat-icon>
                        </button>
                        <button (click)="cancel()" *ngIf="dealNameIsEditable" color="primary" data-test="cancel-button" mat-icon-button>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </form>
                <valumize-deal-funds id="deal-funds"></valumize-deal-funds>
                <valumize-deal-companies id="deal-companies"></valumize-deal-companies>
                <valumize-deal-details id="deal-details"></valumize-deal-details>
                <valumize-deal-return-summary id="return-summary"></valumize-deal-return-summary>
                <valumize-deal-cashflows-graph id="deal-cashflows-graph"></valumize-deal-cashflows-graph>
                <valumize-deal-cashflows id="deal-cashflows"></valumize-deal-cashflows>
                <br/>
                <br/>
                <br/>
            </div>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>

