import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiUtil} from "../../utils/api-util";
import {CodeDefinition} from "../../model/code";

@Injectable({
  providedIn: "root"
})
export class CodeService {

  constructor(private readonly httpClient: HttpClient) { }

    getCodeTables = (findByTable: string) =>
        this.httpClient.get<CodeDefinition>(ApiUtil.toApiPath(`codes/search/findByTable?table=${findByTable}`));
}
