<form [formGroup]="exitForm">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">Investment & Exit Dates</mat-card-title>
        </mat-card-header>

        <ng-container *ngIf="assetValuation$ | async as assetValuation">
            <ng-container *ngIf="fundInvestment$ | async as fundInvestment">
                <ng-container *ngIf="fundInvestment?.data?.investmentDate?.date !== ''; else selectFundInvestmentFirst">

                    <mat-card-subtitle>
                        <valumize-info-panel *ngIf="fundInvestment.status === 'ERROR'" [appearance]="'ERROR'" [message]="fundInvestment.errorMessage"></valumize-info-panel>
                        <valumize-info-panel *ngIf="fundInvestment.status === 'SAVED'" [appearance]="'SAVED'"
                                             [message]="'Investment & Exit Date successfully saved'"></valumize-info-panel>
                        <mat-progress-spinner *ngIf="fundInvestment.status === 'LOADING'"></mat-progress-spinner>
                    </mat-card-subtitle>

                    <mat-card-content class="detail-card-content">
                        <div class="notes-split">
                            <div class="notes-left">
                                <div class="key-value-pair-column">
                                    <div class="key-value-pair">
                                        <div class="key-value-item">Investment Date</div>

                                        <div *ngIf="!exitIsEditable" class="key-value-item">
                                            {{ fundInvestment?.data?.investmentDate | traceableFormat }}
                                        </div>

                                        <mat-form-field *ngIf="exitIsEditable">
                                            <input [matDatepicker]="investmentDatePicker"
                                                   data-test="investment-date"
                                                   formControlName="entryDate"
                                                   matInput>
                                            <mat-hint>dd/MM/yyyy</mat-hint>
                                            <mat-datepicker-toggle [for]="investmentDatePicker" matIconSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #investmentDatePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="key-value-pair">
                                        <div class="key-value-item">Exit Date Assumption</div>
                                        <div class="key-value-item">{{assetValuation.data.exitDateAssumption.date | date}}</div>
                                    </div>

                                    <div class="key-value-pair">
                                        <div class="key-value-item">Effective Exit Date</div>

                                        <div *ngIf="!exitIsEditable" class="key-value-item">
                                            {{ fundInvestment?.data?.exitDate?.date | date }}
                                        </div>

                                        <mat-form-field *ngIf="exitIsEditable">
                                            <input [matDatepicker]="exitDatePicker"
                                                   data-test="effective-exit-date"
                                                   formControlName="effectiveExitDate"
                                                   matInput>
                                            <mat-hint>dd/MM/yyyy</mat-hint>
                                            <mat-datepicker-toggle [for]="exitDatePicker" matIconSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #exitDatePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <ng-container *ngIf="assetValuation.data.id">
                                <ng-container *ngIf="notes$ | async as notes">
                                    <valumize-notes
                                        (delete)="deleteNote($event)"
                                        (save)="saveNote(notes.baseContext, $event)"
                                        [notes]="notes.notes"
                                        data-test="notes"></valumize-notes>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-card-content>

                    <mat-card-actions class="detail-card-actions">
                        <div class="last-modified" data-test="modification-date">Last modified: {{ mostRecentModDate | timestamp }}</div>
                        <button (click)="editMode()" *ngIf="!exitIsEditable" [disabled]="(isEditDisabled$ | async)!" color="primary" data-test="edit-button" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <ng-container *ngIf="assetId$ | async as assetId">
                            <ng-container *ngIf="fundId$ | async as fundId">
                                <ng-container *ngIf="fundReportId$ | async as fundReportId">
                                    <button (click)="validateEffectiveExitDate(assetId, fundId, fundReportId, assetValuation.data)"
                                            *ngIf="exitIsEditable"
                                            [disabled]="exitForm.invalid"
                                            color="primary"
                                            data-test="save-button"
                                            mat-icon-button>
                                        <mat-icon>save</mat-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <button (click)="cancel()" *ngIf="exitIsEditable" color="primary" data-test="cancel-button" mat-icon-button>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-card-actions>

                </ng-container>
            </ng-container>
        </ng-container>

        <ng-template #selectFundInvestmentFirst>
            <mat-card-content class="detail-card-content" style="color: grey">
                Investment & Exit Dates are only available after the asset is assigned to a fund.
            </mat-card-content>
        </ng-template>
    </mat-card>
</form>

