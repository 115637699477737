import {Component, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {FormBuilder, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {selectAssetDescription, selectGeneratedAssetDescription, selectGptAssetDescriptionIsEditable, selectIsAnyAssetFormInEditMode} from "../../store/asset/asset.selectors";
import {AssetDescriptionActions} from "../../store/asset/asset.actions";
import {TraceableText} from "../../../shared/model/traceable";
import {ConfirmationComponent} from "../../../shared/components/confirmation/confirmation.component";

@Component({
    selector: "valumize-asset-description",
    templateUrl: "./asset-description.component.html",
    styleUrls: ["./asset-description.component.scss"]
})
export class AssetDescriptionComponent implements OnInit {
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    gptDescription$ = this.store.select(selectGeneratedAssetDescription);
    isGptEditMode = false;
    gptErrorMessage = "";

    description: { description: TraceableText; url: TraceableText; assetDescriptionIsEditable: boolean } = {
        description: {source: "", modDate: undefined, text: ""},
        url: {source: "", modDate: undefined, text: ""},
        assetDescriptionIsEditable: false
    };

    descriptionForm = this.formBuilder.group({
        assetDescription: this.formBuilder.control("", {nonNullable: true}),
        assetUrl: this.formBuilder.control("", {
            nonNullable: true,
            validators: Validators.pattern("[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)")
        }),
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder, public dialog: MatDialog) {
    }

    ngOnInit() {
        this.store.select(selectAssetDescription).pipe(map((description) => {
            this.description = description;
            this.descriptionForm.patchValue({
                assetDescription: description.description?.text,
                assetUrl: description.url?.text
            });
            if (description.assetDescriptionIsEditable) {
                this.descriptionForm.enable();
            } else {
                this.descriptionForm.disable();
            }
        })).subscribe();

        this.store.select(selectGptAssetDescriptionIsEditable).pipe(map(isGptEditable => {
            this.isGptEditMode = isGptEditable;
        })).subscribe();

        this.gptDescription$.pipe(map(gptDescription => {
            if (gptDescription.status === "ERROR") {
                this.gptErrorMessage = gptDescription.errorMessage;
                this.isGptEditMode = false;
            }
        })).subscribe();
    }

    editMode = () => this.store.dispatch(AssetDescriptionActions.edit());

    save() {
        if (this.descriptionForm.valid) {
            this.store.dispatch(AssetDescriptionActions.save(this.descriptionForm.getRawValue()));
        }
    }

    cancel = () => this.store.dispatch(AssetDescriptionActions.cancel());

    validateUrl(address?: string): string {
        return address ? (address.startsWith("http://") || address.startsWith("https://") ? address : "https://" + address) : "";
    }

    gptEditMode = () => this.store.dispatch(AssetDescriptionActions.gptedit());

    gptCancel = () => this.store.dispatch(AssetDescriptionActions.gptcancel());

    openConfirmDialog(copyOrAddGptDescription: () => void) {
        const dialogRef = this.dialog.open(ConfirmationComponent);
        dialogRef.componentInstance.confirmMessage = `GPT generated text can be factually wrong, please check important information.`;

        dialogRef.afterClosed().pipe(map(result => {
            if (result) {
                copyOrAddGptDescription();
            }
        })).subscribe();
    }

    copyGptDescription(gptDescription: TraceableText) {
        this.openConfirmDialog(() => {
            if (gptDescription.text) {
                this.descriptionForm.patchValue({assetDescription: gptDescription.text});
            }
        });
    }

    addGptDescription(gptDescription: TraceableText) {
        this.openConfirmDialog(() => {
            if (gptDescription.text) {
                this.descriptionForm.patchValue({assetDescription: this.descriptionForm.value.assetDescription + "\n\n" + gptDescription.text});
            }
        });
    }
}
