import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {PeriodTypeEnum} from "../../../models/asset/period-type.enum";
import {DateUtil} from "../../../../shared/utils/date-util";

export enum ActionType {
    Add = "ADD",
    Edit = "EDIT",
    Cancel = "CANCEL",
    Delete = "DELETE"
}

@Component({
    selector: "valumize-asset-history-period-dialog",
    templateUrl: "./asset-history-period-dialog.component.html",
    styleUrls: ["./asset-history-period-dialog.component.scss"]
})
export class AssetHistoryPeriodDialogComponent {

    action: ActionType = this.data.action;
    deletePeriod = false;
    existingPeriods = this.data.existingPeriods;
    originPeriodType = this.data.periodType;
    originPeriodKeyDate = this.data.periodKeyDate;
    periodType = new FormControl("");
    periodKeyDate = new FormControl("");
    // Array of available dates for period selection
    pastYears = 20;
    quarterEndDates = this.generateQuarterEndDates("QUARTER");
    semesterEndDates = this.generateQuarterEndDates("SEMESTER");
    fullFiscalYearEndDates = this.generateQuarterEndDates("FULL_FISCAL_YEAR");
    protected readonly ACTION_TYPE = ActionType;
    protected readonly PERIOD_TYPE = PeriodTypeEnum;

    constructor(
        public dialogRef: MatDialogRef<AssetHistoryPeriodDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            action: ActionType;
            existingPeriods: { periodType: string; periodKeyDate: string }[];
            periodType: string;
            periodKeyDate: string;
            originPeriodKeyDate: string;
            originPeriodType: string;
        }
    ) {
        if (this.action === ActionType.Edit) {
            this.periodType.setValue(data.periodType);
            this.periodKeyDate.setValue(data.periodKeyDate);
        } else {
            this.periodType.setValue(PeriodTypeEnum.FullFiscalYear);
        }
    }

    onCancel(): void {
        this.dialogRef.close(ActionType.Cancel);
    }

    onSave(): void {
        this.dialogRef.close({
            action: this.action,
            periodType: this.periodType.value,
            periodKeyDate: this.periodKeyDate.value,
            originPeriodKeyDate: this.originPeriodKeyDate,
            originPeriodType: this.originPeriodType
        });
    }


    onDelete(): void {
        this.deletePeriod = true;
    }

    onDeleteConfirm(): void {
        this.dialogRef.close(
            {
                action: ActionType.Delete,
                originPeriodKeyDate: this.originPeriodKeyDate,
                originPeriodType: this.originPeriodType
            });
    }

    onDeleteCancel(): void {
        this.deletePeriod = false;
    }

    generateQuarterEndDates(periodType: string): string[] {
        const endDateOfQuarters = [
            {month: 12, day: 31}, // Q4
            {month: 9, day: 30}, // Q3
            {month: 6, day: 30}, // Q2
            {month: 3, day: 31} // Q1
        ];
        const endDateOfSemesters = [
            {month: 12, day: 31}, // S2
            {month: 6, day: 30} // S1
        ];
        const endDateOfYear = {month: 12, day: 31};
        const currentYear = new Date().getFullYear();
        const dates: string[] = [];

        for (let year = currentYear; year >= currentYear - this.pastYears + 1; year--) {

            if (periodType === PeriodTypeEnum.Quarter) {
                endDateOfQuarters.forEach(quarter => {
                    const date = DateUtil.toIsoDate(new Date(year, quarter.month - 1, quarter.day));
                    if (!!date) {
                        dates.push(date);
                    }
                });
            } else if (periodType === PeriodTypeEnum.Semester) {
                endDateOfSemesters.forEach(semester => {
                    const date = DateUtil.toIsoDate(new Date(year, semester.month - 1, semester.day));
                    if (!!date) {
                        dates.push(date);
                    }
                });
            } else if (periodType === PeriodTypeEnum.FullFiscalYear) {
                const date = DateUtil.toIsoDate(new Date(year, endDateOfYear.month - 1, endDateOfYear.day));
                if (!!date) {
                    dates.push(date);
                }
            }
        }
        return dates.filter(date => !this.existingPeriods
            .some(period => period.periodKeyDate === date && period.periodType === periodType));
    }
}
