import {Component, Input} from "@angular/core";
import {EMPTY_MONEY, Traceable, TraceableMoney, TraceableMultiple, TraceablePercent} from "../../model/traceable";
import {FormControl} from "@angular/forms";
import {DecimalFormatPipe} from "../../pipes/decimal-format/decimal-format.pipe";

@Component({
    selector: "valumize-mergeable-number-input",
    templateUrl: "./mergeable-number-input.component.html"
})
export class MergeableNumberInputComponent {
    @Input() label = "";
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() value: Traceable = EMPTY_MONEY;
    @Input() baselineValue: Traceable = EMPTY_MONEY;
    @Input() control!: FormControl;
    @Input() type: "currency" | "multiple" | "percent" = "currency";

    overwriteValue() {
        if (this.isEditable) {
            this.control.setValue(this.getValue(this.baselineValue));
        }
    }

    getChipColor() {
        const baselineValue = this.getValue(this.baselineValue);
        const value = this.getValue(this.value);

        if (this.control.value === baselineValue && baselineValue !== value) {
            return "chip-overwritten-value";
        } else if (baselineValue === value) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }

    getValue(value: Traceable) {
        switch (this.type) {
            case "multiple":
                return (value as TraceableMultiple).factor;
            case "currency":
                return DecimalFormatPipe.transformFromMillionsNum((value as TraceableMoney).amount ?? undefined);
            case "percent":
                return DecimalFormatPipe.transformFractionToPercent((value as TraceablePercent).fraction ?? undefined);
        }
    }
}
