<form [formGroup]="swotForm">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">SWOT</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="selectedAsset$ | async as selectedAsset">
            <ng-container *ngIf="swot$ | async as swot; else noContent">

                <mat-card-subtitle>
                    <ng-container *ngIf="gptSwot$ | async as gptSwot">
                        <valumize-info-panel *ngIf="gptSwot.status === 'ERROR'" [appearance]="'ERROR'" [message]="gptErrorMessage"></valumize-info-panel>
                    </ng-container>
                    <valumize-info-panel *ngIf="selectedAsset.status === 'ERROR'" [appearance]="'ERROR'" [message]="selectedAsset.errorMessage"></valumize-info-panel>
                    <valumize-info-panel *ngIf="selectedAsset.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Successfully saved'"></valumize-info-panel>
                    <mat-progress-spinner *ngIf="selectedAsset.status === 'LOADING'"></mat-progress-spinner>
                </mat-card-subtitle>

                <ng-container
                    *ngIf="(assetSwotEditable$ | async) || swot.swotStrengths.text || swot.swotWeaknesses.text || swot.swotOpportunities.text || swot.swotThreats.text; else noContent">
                    <mat-card-content class="detail-card-content">
                        <div class="swot-form">
                            <div class="swot-textarea">
                                <h3 data-test="strengths-label">Strengths</h3>
                                <ng-container *ngTemplateOutlet="swotTemplate; context: { swotControl: 'swotStrengths' }"></ng-container>
                            </div>
                            <div class="swot-textarea">
                                <h3 data-test="strengths-label">Weaknesses</h3>
                                <ng-container *ngTemplateOutlet="swotTemplate; context: { swotControl: 'swotWeaknesses' }"></ng-container>
                            </div>
                            <div class="swot-textarea">
                                <h3 data-test="strengths-label">Opportunities</h3>
                                <ng-container *ngTemplateOutlet="swotTemplate; context: { swotControl: 'swotOpportunities' }"></ng-container>
                            </div>
                            <div class="swot-textarea">
                                <h3 data-test="strengths-label">Threats</h3>
                                <ng-container *ngTemplateOutlet="swotTemplate; context: { swotControl: 'swotThreats' }"></ng-container>
                            </div>
                        </div>
                        <ng-template #swotTemplate let-swotControl="swotControl">
                            <div class="swot-textarea-container">
                                <mat-form-field>
                                    <textarea [formControlName]="swotControl" style="height: 200px;" matInput></textarea>
                                </mat-form-field>
                                <ng-container *ngIf="gptSwot$ | async as swot">
                                    <div *ngIf="isGptEditMode && swot.status !== 'LOADING'" class="gpt-actions">
                                        <button mat-stroked-button color="primary" (click)="copyGptText(swotControl, swot.data)">Replace</button>
                                        <button mat-stroked-button color="primary" (click)="addGptText(swotControl, swot.data)">Append</button>
                                    </div>
                                    <mat-form-field class="gpt-description" *ngIf="isGptEditMode && swot.status !== 'LOADING'">
                                            <textarea class="line-breaks"
                                                      [value]="getSwotText(swotControl, swot.data) || ''"
                                                      matInput
                                                      readonly></textarea>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </ng-template>
                    </mat-card-content>
                </ng-container>
            </ng-container>

            <ng-template #noContent>
                <mat-card-content class="detail-card-content">
                    <div class="no-content" style="color: grey">Please add content to SWOT.</div>
                </mat-card-content>
            </ng-template>

            <mat-card-actions class="detail-card-actions">
                <mat-spinner *ngIf="(gptSwot$ | async)?.status === 'LOADING'" diameter="24"></mat-spinner>
                <button mat-icon-button
                        *ngIf="(assetSwotEditable$ | async)"
                        (click)="isGptEditMode ? gptCancel() : gptEditMode()"
                        class="gpt-button"
                        aria-label="Generate SWOT">
                    <img ngSrc="assets/GPT-Logo.png" alt="GPT Logo" style="width:24px; height:24px;" height="2160" width="2160">
                </button>
                <div class="last-modified" data-test="modification-date">Last modified: {{mostRecentSwotModDate | timestamp}}</div>
                <button mat-icon-button
                        color="primary"
                        data-test="edit-button"
                        (click)="editMode()"
                        *ngIf="(assetSwotEditable$ | async) === false"
                        [disabled]="(isEditDisabled$ | async)!">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="primary" data-test="save-button" (click)="save(); gptCancel()" *ngIf="(assetSwotEditable$ | async)">
                    <mat-icon>save</mat-icon>
                </button>
                <button mat-icon-button color="primary" data-test="cancel-button" (click)="cancel(); gptCancel()" *ngIf="(assetSwotEditable$ | async)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
        </ng-container>
    </mat-card>
</form>
