<h2 mat-dialog-title>Manage Fund Report</h2>
<mat-dialog-content>
    <p>If the report already exists, please select it.</p>
    <form [formGroup]="fundReportForm">
        <div>
            <mat-form-field appearance="fill">
                <input matInput
                       formControlName="reportDate"
                       id="reportDate"
                       [matDatepicker]="reportDatePicker">
                <mat-hint>dd/MM/yyyy</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="reportDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #reportDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="name-actions">
            <button
                mat-icon-button mat-dialog-close
                color="primary"
                title="Add Fund Report"
                aria-label="Add Fund Report"
                data-test="save-button"
                [disabled]="fundReportForm.invalid"
                (click)="createFundReport()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>
    <table mat-table [dataSource]="(fundReportsForFund$ | async) || []">
        <ng-container matColumnDef="id" sticky>
            <th mat-header-cell *matHeaderCellDef>Fund Report ID</th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="reportDate" sticky>
            <th mat-header-cell *matHeaderCellDef>Report Date</th>
            <td mat-cell *matCellDef="let element">{{element.reportDate | traceableFormat}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>

        <tr mat-row
            *matRowDef="let row; columns: columns"
            [routerLink]="'../../funds/' + fundId"
            [queryParams]="{fundReportId: row.id}"
            mat-dialog-close></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-stroked-button
        color="primary"
        mat-dialog-close data-test="save-button-bottom"
        [disabled]="fundReportForm.invalid"
        (click)="createFundReport()">
        Add Fund Report
    </button>
    <button
        mat-stroked-button mat-dialog-close
        data-test="cancel-button">
        Cancel
    </button>
</mat-dialog-actions>
