import {Route} from "@angular/router";
import {Type} from "@angular/core";
import {AccessDeniedComponent} from "./access-denied.component";
import {LoginPageComponent} from "./login-page.component";
import {AuthGuard} from "../../../services/login/auth.guard";

export class AuthRouteBuilder {
    static authRoutes(accessDeniedComponent: Type<any> = AccessDeniedComponent): Route[] {
        return [
            {path: "login", component: LoginPageComponent},
            {path: "accessDenied", component: accessDeniedComponent, canActivate: [AuthGuard]}
        ];
    }
}
