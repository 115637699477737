import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MergeDataset} from "../models/merge-dataset";
import {DatasetApiService} from "../generated-sources/api/dataset";

@Injectable({
    providedIn: "root"
})
export class DatasetService {

    constructor(private readonly datasetService: DatasetApiService) {
    }

    public getDatasets(): Observable<string[]> {
        return this.datasetService.getDatasets();
    }

    public deleteDataset(datasetToDelete: string): Observable<string> {
        return this.datasetService.deleteDataset(datasetToDelete);
    }

    public mergeDatasets(mergeDataset: MergeDataset): Observable<string> {
        return this.datasetService.mergeDatasets(mergeDataset);
    }
}
