import {Component} from "@angular/core";
import {CodeTableEnum} from "../../../shared/model/code";
import {Store} from "@ngrx/store";
import {selectDealReturnSummaryTable} from "../../store/deal/deal.selectors";

@Component({
    selector: "valumize-deal-return-summary",
    templateUrl: "./deal-return-summary.component.html"
})
export class DealReturnSummaryComponent {

    returnSummary$ = this.store.select(selectDealReturnSummaryTable);
    codeTableScenarioType = CodeTableEnum.SHARED_SCENARIOTYPE;

    constructor(private readonly store: Store) {
    }
}
