import {Component, OnInit} from "@angular/core";
import {map, Observable} from "rxjs";
import {Fund} from "../models/fund";
import {selectFunds, selectSelectedFundId} from "../store/fund/fund.selectors";
import {Store} from "@ngrx/store";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {FundActions, FundListActions} from "../store/fund.actions";
import {noWhitespaceValidator} from "../../shared/utils/form-validators";

@Component({
    selector: "valumize-fund-new-dialog",
    templateUrl: "./fund-new-dialog.component.html",
    styleUrls: ["./fund-new-dialog.component.scss"]
})
export class FundNewDialogComponent implements OnInit {

    filtereFund$?: Observable<Fund[]>;
    fund$ = this.store.select(selectFunds);
    columns = ["name"];

    nameForm = this.formBuilder.group({
        fundName: this.formBuilder.control("", {nonNullable: true, validators: [Validators.required, Validators.maxLength(100), noWhitespaceValidator()]}),
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder,
                private readonly router: Router) {
        this.store.dispatch(FundListActions.load());
    }

    ngOnInit(): void {
        this.filtereFund$ = this.fund$;
    }

    onSearch() {
        if (this.nameForm.value.fundName) {
            this.filtereFund$ = this.fund$.pipe(map((funds) =>
                funds.filter((fund) => !!fund.name.text ? fund.name.text.toLowerCase().includes(this.nameForm.getRawValue().fundName.toLowerCase()) : false)));
        } else {
            this.filtereFund$ = this.fund$;
        }
    }

    createFund() {
        if (this.nameForm.valid) {
            this.store.dispatch(FundActions.create(this.nameForm.getRawValue()));
        }
        this.store.select(selectSelectedFundId).pipe(map((id) => {
            if (!!id && id > 0) {
                this.router.navigate(["/funds", id]);
            }
        })).subscribe();
    }

}
