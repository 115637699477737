<form [formGroup]="descriptionForm">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">Company Description</mat-card-title>
        </mat-card-header>
        <mat-card-subtitle>
            <valumize-info-panel *ngIf="(gptDescription$ | async)?.status === 'ERROR'" [appearance]="'ERROR'" [message]="gptErrorMessage"></valumize-info-panel>
        </mat-card-subtitle>
        <ng-container>
            <mat-card-content class="detail-card-content">
                <div class="company-description-form">
                    <div class="description-section">
                        <div *ngIf="!description.assetDescriptionIsEditable && description.description.text; else noDescription" class="description line-breaks">
                            {{description.description.text}}
                        </div>
                        <div class="description-container" *ngIf="description.assetDescriptionIsEditable">
                            <mat-form-field appearance="fill" class="description">
                                <textarea class="line-breaks"
                                          data-test="description"
                                          formControlName="assetDescription"
                                          matInput></textarea>
                            </mat-form-field>

                            <ng-container *ngIf="gptDescription$ | async as gptDescription">
                                <div *ngIf="isGptEditMode && gptDescription.status !== 'LOADING'" class="gpt-actions">
                                    <button mat-stroked-button color="primary" (click)="copyGptDescription(gptDescription.data)">Replace</button>
                                    <button mat-stroked-button color="primary" (click)="addGptDescription(gptDescription.data)">Append</button>
                                </div>
                            </ng-container>

                            <mat-form-field appearance="fill" class="description" *ngIf="isGptEditMode && (gptDescription$ | async)?.status !== 'LOADING'">
                                <textarea class="line-breaks"
                                          [value]="(gptDescription$ | async)?.data?.text || ''"
                                          matInput
                                          readonly></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="logo-and-url">
                        <a *ngIf="!description.assetDescriptionIsEditable" [href]="validateUrl(description.url.text)" class="btn btn-link url" data-test="website" rel="noopener"
                           target="_blank">
                            {{description.url.text}}
                        </a>
                        <mat-form-field *ngIf="description.assetDescriptionIsEditable" appearance="fill" class="url">
                            <input data-test="asset-url"
                                   formControlName="assetUrl"
                                   matInput
                                   placeholder="Website">
                        </mat-form-field>
                    </div>
                </div>
                <ng-template #noDescription>
                    <div *ngIf="!description.assetDescriptionIsEditable" class="no-description" style="color: grey">
                        Please add a company description!
                    </div>
                </ng-template>
            </mat-card-content>
            <mat-card-actions class="detail-card-actions">
                <mat-spinner *ngIf="(gptDescription$ | async)?.status === 'LOADING'" diameter="24"></mat-spinner>
                <button mat-icon-button
                        *ngIf="description.assetDescriptionIsEditable"
                        (click)="isGptEditMode ? gptCancel() : gptEditMode()"
                        class="gpt-button"
                        aria-label="Generate Description">
                    <img ngSrc="assets/GPT-Logo.png" alt="GPT Logo" style="width:24px; height:24px;" height="2160" width="2160">
                </button>
                <div class="last-modified" data-test="modification-date">Last modified: {{description.description.modDate | timestamp}}</div>
                <button (click)="editMode()" *ngIf="!description.assetDescriptionIsEditable"
                        [disabled]="(isEditDisabled$ | async)!"
                        color="primary"
                        data-test="edit-button"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="save(); gptCancel()"
                        *ngIf="description.assetDescriptionIsEditable"
                        [disabled]="descriptionForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <button (click)="cancel(); gptCancel()" *ngIf="description.assetDescriptionIsEditable" color="primary" data-test="cancel-button" mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
        </ng-container>
    </mat-card>
</form>
