<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Deal Overview</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <table mat-table [dataSource]="dealDataSource" matSort #dealSort="matSort">
            <ng-container matColumnDef="dealId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>

            <ng-container matColumnDef="dealName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Deal</th>
                <td mat-cell *matCellDef="let element">{{element.projectName | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="closingDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Closing Date</th>
                <td mat-cell *matCellDef="let element">{{element.closingDate | traceableFormat}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: columns"
                [routerLink]="row.id"></tr>
        </table>

        <mat-paginator
            #dealPaging
            [pageSizeOptions]="[10, 20, 30]"
            showFirstLastButtons>
        </mat-paginator>
    </mat-card-content>
    <mat-card-actions class="overview-actions">
        <button mat-stroked-button color="primary" (click)="openNewDealDialog()" data-test="new-deal">
            <mat-icon>add</mat-icon>
            Deal
        </button>
    </mat-card-actions>
</mat-card>
