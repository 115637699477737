import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectFundSellerPosition, selectIsAnyFundFormInEditMode} from "../../store/fund/fund.selectors";
import {selectSelectedDealId} from "../../../deal/store/deal/deal.selectors";
import {FormBuilder, Validators} from "@angular/forms";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {FundSellerPositionActions} from "../../store/fund.actions";
import {map, Subscription} from "rxjs";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {EMPTY_SELLER_POSITION} from "../../../deal/store/deal.reducer";
import {SellerPositionActions} from "../../../deal/store/deal.actions";
import {selectSelectedFundValuationId} from "../../store/fund-valuation/fund-valuation.selectors";

@Component({
    selector: "valumize-fund-seller-position",
    templateUrl: "./fund-seller-position.component.html"
})
export class FundSellerPositionComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    dealId$ = this.store.select(selectSelectedDealId);
    fundValuationId$ = this.store.select(selectSelectedFundValuationId);
    fundSellerPosition$ = this.store.select(selectFundSellerPosition);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);

    persistedSellerPosition = EMPTY_SELLER_POSITION;
    isEditable = false;

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder
    ) {
    }

    sellerPositionForm = this.formBuilder.group({
        originalCommitment: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        recallableDistribution: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        unfundedCommitments: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]})
    });

    ngOnInit() {
        this.subscription = this.store.select(selectFundSellerPosition).pipe(map((fundSellerPosition => {
            this.isEditable = fundSellerPosition.fundSellerPositionIsEditable;
            this.persistedSellerPosition = fundSellerPosition.sellerPosition.data;

            this.sellerPositionForm.patchValue({
                originalCommitment: DecimalFormatPipe.transformFromMillionsNum(fundSellerPosition.sellerPosition.data.sellerCommitment.amount ?? undefined),
                recallableDistribution: DecimalFormatPipe.transformFromMillionsNum(fundSellerPosition.sellerPosition.data.recallableDistribution.amount ?? undefined),
                unfundedCommitments: DecimalFormatPipe.transformFromMillionsNum(fundSellerPosition.sellerPosition.data.unfundedCommitments.amount ?? undefined)
            });
        }))).subscribe();
    }

    save(dealId: number, fundValuationId: number) {
        if (this.sellerPositionForm.valid) {
            const form = this.sellerPositionForm.getRawValue();
            const sellerPositionToSave = {
                ...this.persistedSellerPosition,
                sellerCommitment: {
                    ...this.persistedSellerPosition.sellerCommitment,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.originalCommitment ?? undefined)
                },
                recallableDistribution: {
                    ...this.persistedSellerPosition.recallableDistribution,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.recallableDistribution ?? undefined)
                },
                unfundedCommitments: {
                    ...this.persistedSellerPosition.unfundedCommitments,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.unfundedCommitments ?? undefined)
                }
            };
            this.store.dispatch(SellerPositionActions.save({dealId, fundValuationId, sellerPosition: sellerPositionToSave}));
        }
    }

    editMode = () => this.store.dispatch(FundSellerPositionActions.edit());

    cancel = () => this.store.dispatch(FundSellerPositionActions.cancel());

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
