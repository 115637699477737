import {TextFieldModule} from "@angular/cdk/text-field";
import {CommonModule} from "@angular/common";
import {APP_INITIALIZER, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatToolbarModule} from "@angular/material/toolbar";
import {AppRoutingModule} from "../app-routing.module";
import {HeaderComponent} from "./components/header/header.component";
import {NotesComponent} from "./components/notes/notes.component";
import {DecimalFormatPipe} from "./pipes/decimal-format/decimal-format.pipe";
import {LoginFormComponent} from "./components/login/default-login/login-form.component";
import {LoginPageComponent} from "./components/login/default-login/login-page.component";
import {AccessDeniedComponent} from "./components/login/default-login/access-denied.component";
import {ChangePasswordComponent} from "./components/login/change-password/change-password.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "./services/login/auth-interceptor";
import {AuthenticationService} from "./services/login/authentication.service";
import {LoginService} from "./services/login/login.service";
import {DefaultLoginService} from "./services/login/impl/default-login.service";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TraceableFormatPipe} from "./pipes/traceable-format/traceable-format.pipe";
import {StoreModule} from "@ngrx/store";
import {sharedReducer, sharedStoreFeatureKey} from "./store/shared.reducer";
import {CodeFormatPipe} from "./pipes/code-format/code-format.pipe";
import {InfoPanelComponent} from "./components/info-panel/info-panel.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {SearchModule} from "../search/search.module";
import {TimestampPipe} from "./pipes/timestamp/timestamp.pipe";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {ConfirmationComponent} from "./components/confirmation/confirmation.component";
import {MergeableInputComponent} from "./custom-form-fields/mergeable-input/mergeable-input.component";
import {MatChipsModule} from "@angular/material/chips";
import {MergeableSelectCodeComponent} from "./custom-form-fields/mergeable-select-code/mergeable-select-code.component";
import {MergeableSelectMultiCodeComponent} from "./custom-form-fields/mergeable-select-multi-code/mergeable-select-multi-code.component";
import {MergeableCheckboxComponent} from "./custom-form-fields/mergeable-checkbox/mergeable-checkbox.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MergeableNumberInputComponent} from "./custom-form-fields/mergeable-number-input/mergeable-number-input.component";
import {MergeableDateComponent} from "./custom-form-fields/mergeable-date/mergeable-date.component";
import {MatDatepickerModule} from "@angular/material/datepicker";

export const verifyAuthentication = (authenticationService: AuthenticationService) =>
    () => authenticationService.verify();

@NgModule({
    declarations: [
        HeaderComponent,
        NotesComponent,
        ChangePasswordComponent,
        LoginFormComponent,
        LoginPageComponent,
        AccessDeniedComponent,
        DecimalFormatPipe,
        TraceableFormatPipe,
        CodeFormatPipe,
        InfoPanelComponent,
        TimestampPipe,
        ConfirmationComponent,
        MergeableInputComponent,
        MergeableSelectCodeComponent,
        MergeableSelectMultiCodeComponent,
        MergeableCheckboxComponent,
        MergeableNumberInputComponent,
        MergeableDateComponent,
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatSnackBarModule,
        TextFieldModule,
        StoreModule.forFeature(sharedStoreFeatureKey, sharedReducer),
        SearchModule,
        MatOptionModule,
        MatSelectModule,
        MatChipsModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatDatepickerModule
    ],
    exports: [
        HeaderComponent,
        NotesComponent,
        LoginFormComponent,
        LoginPageComponent,
        AccessDeniedComponent,
        ChangePasswordComponent,
        DecimalFormatPipe,
        TraceableFormatPipe,
        CodeFormatPipe,
        InfoPanelComponent,
        TimestampPipe,
        ConfirmationComponent,
        MergeableInputComponent,
        MergeableInputComponent,
        MergeableSelectCodeComponent,
        MergeableSelectMultiCodeComponent,
        MergeableCheckboxComponent,
        MergeableNumberInputComponent,
        MergeableDateComponent,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: verifyAuthentication,
            multi: true,
            deps: [AuthenticationService]
        },
        {provide: LoginService, useClass: DefaultLoginService},
        MatSnackBarModule
    ]
})
export class SharedModule {
}
