import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SearchState} from "./search.state";
import {searchStoreFeatureKey} from "./search.reducer";
import {selectSelectedSourceDataset, selectSelectedTargetDataset} from "../../import/store/import.selectors";

export const selectSearchState = createFeatureSelector<SearchState>(searchStoreFeatureKey);

export const selectSearchResults = createSelector(
    selectSearchState,
    (state) => state.searchResults.data
);

export const selectDeals = createSelector(
    selectSearchResults,
    (searchResults) => searchResults.filter(result => result.category === "deals")
);

export const selectFunds = (targetSearch: boolean) => createSelector(
    selectSearchResults,
    selectSelectedSourceDataset,
    selectSelectedTargetDataset,
    (searchResults, source, target) =>
        targetSearch
            ? searchResults.filter(result => result.category === target + "/funds")
            : searchResults.filter(result => result.category === source + "/funds")
);

export const selectAssets = (targetSearch: boolean) => createSelector(
    selectSearchResults,
    selectSelectedSourceDataset,
    selectSelectedTargetDataset,
    (searchResults, source, target) =>
        targetSearch
            ? searchResults.filter(result => result.category === target + "/assets")
            : searchResults.filter(result => result.category === source + "/assets")
);

export const selectQuery = createSelector(
    selectSearchState,
    (state) => state.query.data.query
);
