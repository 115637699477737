import {Component, OnDestroy, OnInit} from "@angular/core";
import {
    selectEndMarketsCustomers,
    selectEndMarketsCustomersEdit,
    selectGeneratedEndMarketsDescription,
    selectGptEndMarketsIsEditable,
    selectIsAnyAssetFormInEditMode,
    selectSelectedAsset
} from "../../store/asset/asset.selectors";
import {Store} from "@ngrx/store";
import {FormBuilder} from "@angular/forms";
import {EndMarketsCustomersActions} from "../../store/asset/asset.actions";
import {map, Subscription} from "rxjs";
import {TraceableText} from "../../../shared/model/traceable";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationComponent} from "../../../shared/components/confirmation/confirmation.component";

@Component({
    selector: "valumize-asset-end-markets-customers",
    templateUrl: "./asset-end-markets-customers.component.html",
    styleUrls: ["./asset-end-markets-customers.component.scss"]
})
export class AssetEndMarketsCustomersComponent implements OnInit, OnDestroy {
    selectedAsset$ = this.store.select(selectSelectedAsset);
    endMarketsCustomers$ = this.store.select(selectEndMarketsCustomers);
    endMarketsCustomersIsEditable$ = this.store.select(selectEndMarketsCustomersEdit);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    gptEndMarkets$ = this.store.select(selectGeneratedEndMarketsDescription);
    isGptEditMode = false;
    gptErrorMessage = "";
    subscriptions: Subscription[] = [];

    endMarketsCustomersForm = this.formBuilder.group({
        text: this.formBuilder.control("", {nonNullable: true}),
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder, public dialog: MatDialog) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(selectEndMarketsCustomers).pipe(map((endMarketsCustomers) => {
                this.endMarketsCustomersForm.patchValue({text: endMarketsCustomers.text});
            })).subscribe()
        );

        this.subscriptions.push(
            this.store.select(selectEndMarketsCustomersEdit).pipe(map((isEditable) =>
                isEditable ? this.endMarketsCustomersForm.enable() : this.endMarketsCustomersForm.disable())
            ).subscribe()
        );

        this.store.select(selectGptEndMarketsIsEditable).pipe(map(isGptEditable => {
            this.isGptEditMode = isGptEditable;
        })).subscribe();

        this.gptEndMarkets$.pipe(map(gptEndMarkets => {
            if (gptEndMarkets.status === "ERROR") {
                this.gptErrorMessage = gptEndMarkets.errorMessage;
                this.isGptEditMode = false;
            }
        })).subscribe();
    }

    editMode = () => this.store.dispatch(EndMarketsCustomersActions.edit());

    save() {
        if (this.endMarketsCustomersForm.valid) {
            this.store.dispatch(EndMarketsCustomersActions.save(this.endMarketsCustomersForm.getRawValue()));
        }
    }

    cancel = () => this.store.dispatch(EndMarketsCustomersActions.cancel());

    gptEditMode = () => this.store.dispatch(EndMarketsCustomersActions.gptedit());

    gptCancel = () => this.store.dispatch(EndMarketsCustomersActions.gptcancel());

    openConfirmDialog(copyOrAddGptText: () => void) {
        const dialogRef = this.dialog.open(ConfirmationComponent);
        dialogRef.componentInstance.confirmMessage = `GPT generated text can be factually wrong, please check important information.`;

        dialogRef.afterClosed().pipe(map(result => {
            if (result) {
                copyOrAddGptText();
            }
        })).subscribe();
    }

    copyGptText(gptText: TraceableText) {
        this.openConfirmDialog(() => {
            if (gptText.text) {
                this.endMarketsCustomersForm.patchValue({text: gptText.text});
            }
        });
    }

    addGptText(gptText: TraceableText) {
        this.openConfirmDialog(() => {
            if (gptText.text) {
                this.endMarketsCustomersForm.patchValue({text: this.endMarketsCustomersForm.value.text + "\n\n" + gptText.text});
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
