import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {from, Observable, of} from "rxjs";
import {tap} from "rxjs/operators";

import {LoginFormComponent} from "../../../components/login/default-login/login-form.component";
import {LoginService} from "../login.service";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class DefaultLoginService extends LoginService {

    constructor(private readonly matDialog: MatDialog,
                private readonly router: Router) {
        super();
    }

    showLoginPage(redirect?: string): Observable<any> {
        // leading `/` are trimmed, only absolute URLs are supported
        const redirectUrl = redirect ? redirect.replace(/^\//g, "") : undefined;
        return from(this.router.navigate(["login"], {queryParams: {redirect: redirectUrl}}));
    }

    showLoginModal(): Observable<string> {
        if (this.matDialog.openDialogs.length === 0) {
            const modalRef = this.matDialog.open(LoginFormComponent,
                {
                    disableClose: true
                }
            );
            return modalRef.componentInstance.loginCallback.pipe(
                tap(() => {
                    modalRef.close();
                    location.reload();
                })
            );
        }
        return of();
    }
}
