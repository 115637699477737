import {SharedState} from "./shared.state";
import {createReducer, on} from "@ngrx/store";
import {SharedCodeActions} from "./shared.actions";

export const sharedStoreFeatureKey = "valumize/shared";

export const INITIAL_SHARED_STATE: SharedState = {
    codes: {
        data: [],
        status: "INIT",
        errorMessage: ""
    }
};

export const sharedReducer = createReducer(
    INITIAL_SHARED_STATE,

    // Code Tables
    on(SharedCodeActions.loadcodetables, (state): SharedState => ({
        ...state,
        codes: {
            ...state.codes,
            ...{
                status: "LOADING",
            }
        }
    })),
    on(SharedCodeActions.loadedcodes, (state, action): SharedState => ({
            ...state,
            codes: {
                data: action.codes,
                status: "LOADING",
                errorMessage: ""
            }
        })
    ),
    on(SharedCodeActions.loaderror, (state, action): SharedState => ({
        ...state,
        codes: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
);
