<mat-toolbar class="{{setHeaderBackgroundColor(sourceDataset$ | async)}}">
    <button class="gap" mat-icon-button>
        <img mat-card-image class="logo" src="assets/logo.png" alt="valumize" data-test="logo"/>
    </button>
    <ng-container *ngIf="(sourceDataset$ | async) === 'master'; else stagingHeader">
        <button [matMenuTriggerFor]="dropdownDeal" class="gap" mat-button>
            <mat-icon>work_history</mat-icon>
            Deal
        </button>
        <mat-menu #dropdownDeal>
            <button mat-menu-item routerLink="deals">
                <mat-icon>format_list_bulleted</mat-icon>
                Deal Overview
            </button>
            <button mat-menu-item (click)="openNewDealDialog()">
                <mat-icon>add</mat-icon>
                New Deal
            </button>
        </mat-menu>
        <button [matMenuTriggerFor]="dropdownFund" class="gap" mat-button>
            <mat-icon>domain</mat-icon>
            Fund
        </button>
        <mat-menu #dropdownFund>
            <button mat-menu-item routerLink="funds">
                <mat-icon>format_list_bulleted</mat-icon>
                Funds Overview
            </button>
            <button mat-menu-item (click)="openNewFundDialog()">
                <mat-icon>add</mat-icon>
                Add Fund
            </button>
        </mat-menu>
        <button [matMenuTriggerFor]="dropdownAsset" class="gap" mat-button>
            <mat-icon>factory</mat-icon>
            Company
        </button>
        <mat-menu #dropdownAsset>
            <button mat-menu-item routerLink="assets">
                <mat-icon>format_list_bulleted</mat-icon>
                Company Overview
            </button>
            <button mat-menu-item (click)="openNewAssetDialog()">
                <mat-icon>add</mat-icon>
                Add Company
            </button>
        </mat-menu>
        <button class="gap" mat-button routerLink="import">
            <mat-icon>input</mat-icon>
            Import
        </button>

        <valumize-search (searchResultSelected)="navigateToSearchResult($event)"></valumize-search>

        <span class="filler"></span>
        <ng-container *ngIf="isAuthenticated">
            <button [matMenuTriggerFor]="dropdownProfile" class="gap" mat-button>
                <mat-icon>person</mat-icon>
                {{userName}}
            </button>
            <mat-menu #dropdownProfile>
                <button
                    data-test="change-password-dropdown-entry"
                    (click)="changePassword()"
                    mat-menu-item>
                    Change password
                </button>
                <button
                    data-test="logout-dropdown-entry"
                    (click)="logout()"
                    mat-menu-item>
                    Logout
                </button>
            </mat-menu>
        </ng-container>
    </ng-container>

    <ng-template #stagingHeader>
        <ng-container *ngIf="sourceDataset$ | async as sourceDataset">
            <button [matMenuTriggerFor]="dropdownFund" class="gap" mat-button>
                <mat-icon>domain</mat-icon>
                Fund
            </button>
            <mat-menu #dropdownFund>
                <button mat-menu-item routerLink="funds">
                    <mat-icon>format_list_bulleted</mat-icon>
                    Funds Overview
                </button>
                <button mat-menu-item (click)="openNewFundDialog()">
                    <mat-icon>add</mat-icon>
                    Add Fund
                </button>
            </mat-menu>
            <button [matMenuTriggerFor]="dropdownAsset" class="gap" mat-button>
                <mat-icon>factory</mat-icon>
                Company
            </button>
            <mat-menu #dropdownAsset>
                <button mat-menu-item routerLink="assets">
                    <mat-icon>format_list_bulleted</mat-icon>
                    Company Overview
                </button>
                <button mat-menu-item (click)="openNewAssetDialog()">
                    <mat-icon>add</mat-icon>
                    Add Company
                </button>
            </mat-menu>

            <valumize-search (searchResultSelected)="navigateToSearchResult($event)"></valumize-search>
            <span class="filler"></span>

            <span class="gap">{{sourceDataset}}</span>
            <mat-icon class="gap">arrow_right_alt</mat-icon>
            <mat-select [value]="targetDataset" class="dropdown gap">
                <mat-option *ngFor="let dataset of filterSourceDataset(((datasets$ | async) || []), sourceDataset)"
                            [value]="dataset" (onSelectionChange)="updateTargetDataset(sourceDataset, $event)">
                    {{dataset}}
                </mat-option>
            </mat-select>
            <button
                mat-stroked-button
                class="button-border gap"
                data-test="merge"
                (click)="merge(sourceDataset, targetDataset)">
                Merge
            </button>
            <button
                mat-button
                (click)="closeMergePage()">
                <mat-icon>close</mat-icon>
            </button>
        </ng-container>
    </ng-template>
</mat-toolbar>
