<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Funds (in Deal Currency)</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <section class="table-container" tabindex="0">
            <ng-container *ngIf="dealFunds$ | async as dealFunds">
                <ng-container *ngIf="dealFundsForm">
                    <form [formGroup]="dealFundsForm">
                        <table mat-table [dataSource]="dealFunds.tableDatasource">
                            <ng-container matColumnDef="fundName" sticky>
                                <th mat-header-cell *matHeaderCellDef>Fund Name</th>
                                <td mat-cell *matCellDef="let element" class="no-wrap">{{element.fundName | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="fundSize">
                                <th mat-header-cell *matHeaderCellDef>Fund Size*</th>
                                <td mat-cell *matCellDef="let element">{{element.fundSize | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="fundCurrency">
                                <th mat-header-cell *matHeaderCellDef>Fund Currency</th>
                                <td mat-cell *matCellDef="let element">{{element.fundCurrency.code | codeFormat: codeTableCurrency | async}}</td>
                            </ng-container>

                            <ng-container matColumnDef="exchangeRate">
                                <th mat-header-cell *matHeaderCellDef>Exchange Rate</th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="!isEditable; else editExchangeRate">
                                        {{element.exchangeRate.fraction | decimalFormat: "number"}}
                                    </ng-container>
                                    <ng-template #editExchangeRate>
                                        <input *ngIf="dealFundsForm.controls[element.fundName.text + 'ExchangeRate']" matInput
                                               type="number"
                                               (click)="$event.stopPropagation()"
                                               formControlName="{{element.fundName | traceableFormat}}ExchangeRate"
                                               id="{{element.fundName | traceableFormat}}ExchangeRate">
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fundVintageYear">
                                <th mat-header-cell *matHeaderCellDef>Fund Vintage Year</th>
                                <td mat-cell *matCellDef="let element">{{element.fundVintageYear | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="fundEndYear">
                                <th mat-header-cell *matHeaderCellDef>Fund End Year</th>
                                <td mat-cell *matCellDef="let element">{{element.fundEndYear | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="percentageHeld">
                                <th mat-header-cell *matHeaderCellDef>% held</th>
                                <td mat-cell *matCellDef="let element">{{element.percentageHeld | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="reportDate">
                                <th mat-header-cell *matHeaderCellDef>Report Date</th>
                                <td mat-cell *matCellDef="let element">{{element.reportDate | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="closingDate">
                                <th mat-header-cell *matHeaderCellDef>Closing Date</th>
                                <td mat-cell *matCellDef="let element">{{element.closingDate | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="sellerStake">
                                <th mat-header-cell *matHeaderCellDef>Seller Stake</th>
                                <td mat-cell *matCellDef="let element">{{element.sellerPosition.sellerStake | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="sellerCommitment">
                                <th mat-header-cell *matHeaderCellDef>Seller Commitment*</th>
                                <td mat-cell *matCellDef="let element">{{element.sellerPosition.sellerCommitment | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="sellerCommitmentDealCurrency">
                                <th mat-header-cell *matHeaderCellDef>Seller Commitment</th>
                                <td mat-cell *matCellDef="let element">{{element.sellerCommitmentDealCurrency | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="buyerStake">
                                <th mat-header-cell *matHeaderCellDef>Buyer Stake</th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="!isEditable; else editBuyerStake">
                                        {{element.sellerPosition.buyerStake | traceableFormat}}
                                    </ng-container>
                                    <ng-template #editBuyerStake>
                                        <input *ngIf="dealFundsForm.controls[element.fundName.text + 'BuyerStake']" matInput
                                               type="number"
                                               (click)="$event.stopPropagation()"
                                               formControlName="{{element.fundName | traceableFormat}}BuyerStake"
                                               id="{{element.fundName | traceableFormat}}BuyerStake">
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="buyerCommitment">
                                <th mat-header-cell *matHeaderCellDef>Buyer Commitment</th>
                                <td mat-cell *matCellDef="let element">{{element.sellerPosition.buyerCommitment | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="discountToNav">
                                <th mat-header-cell *matHeaderCellDef>Discount to NAV</th>
                                <td mat-cell *matCellDef="let element">{{element.discountToNav | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="bidPriceBuyerStake">
                                <th mat-header-cell *matHeaderCellDef>Bid Price (Buyer Stake)</th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="!isEditable; else editBidPriceAdjustment">
                                        {{element.bidPriceBuyerStake | traceableFormat}}
                                    </ng-container>
                                    <ng-template #editBidPriceAdjustment>
                                        <ng-container *ngIf="isEditableBidColumnCell(element.fundName.text) && !!( dealId$| async)">
                                            <input matInput
                                                   type="number"
                                                   (click)="$event.stopPropagation()"
                                                   formControlName="bidPriceAdjustment"
                                                   id="bidPriceAdjustment">
                                        </ng-container>
                                        <ng-container *ngIf="!isEditableBidColumnCell(element.fundName.text)">
                                            {{element.bidPriceBuyerStake | traceableFormat}}
                                        </ng-container>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="navBuyerStake">
                                <th mat-header-cell *matHeaderCellDef>NAV (Buyer Stake)</th>
                                <td mat-cell *matCellDef="let element">{{element.navBuyerStake | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="unfundedBuyerStake">
                                <th mat-header-cell *matHeaderCellDef>Unfunded (Buyer Stake)</th>
                                <td mat-cell *matCellDef="let element">{{element.unfundedBuyerStake | traceableFormat}}</td>
                            </ng-container>

                            <ng-container matColumnDef="adjustedCommitment">
                                <th mat-header-cell *matHeaderCellDef>Adjusted Commitment</th>
                                <td mat-cell *matCellDef="let element">{{element.adjustedCommitment | traceableFormat}}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="dealFunds.tableColumns"></tr>
                            <tr mat-row
                                *matRowDef=" let row; columns: dealFunds.tableColumns"
                                class="{{styleRowImportant(row)}} {{styleRowBorderTop(row)}} {{styleRowNotClickable(row)}} {{notClickableInEditMode()}}"
                                [routerLink]="'../../funds/' + row.fundId"
                                [queryParams]="{
                                    fundReportId: row.fundReportId,
                                    fundValuationId: row.fundValuationId,
                                    dealId: (dealId$ | async)
                                }"
                            ></tr>
                        </table>
                    </form>
                </ng-container>
            </ng-container>
        </section>
        <br>
        <section>* Fund Currency</section>
    </mat-card-content>
    <mat-card-actions class="detail-card-actions">
        <ng-container *ngIf="dealId$ | async as dealId">
            <button mat-stroked-button
                    color="primary"
                    (click)="openManageFundsDialog(dealId)"
                    data-test="manage-funds">
                <mat-icon>settings</mat-icon>
                Manage Funds
            </button>
            <button (click)="editMode()"
                    *ngIf="!isEditable"
                    [disabled]="(isEditDisabled$ | async)!"
                    color="primary"
                    data-test="edit-button"
                    mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <ng-container *ngIf="dealFunds$ | async as dealFunds">
                <button (click)="save(dealId, dealFunds.tableDatasource)"
                        *ngIf="isEditable"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
            </ng-container>
            <button (click)="cancel()"
                    *ngIf="isEditable"
                    color="primary"
                    data-test="cancel-button"
                    mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
        </ng-container>
    </mat-card-actions>
</mat-card>


