import {Component, Input} from "@angular/core";
import {EMPTY_MODEL_DATE, TraceableDate} from "../../model/traceable";
import {FormControl} from "@angular/forms";
import {DateUtil} from "../../utils/date-util";

@Component({
    selector: "valumize-mergeable-date",
    templateUrl: "./mergeable-date.component.html"
})
export class MergeableDateComponent {
    @Input() label = "";
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() value: TraceableDate = EMPTY_MODEL_DATE;
    @Input() baselineValue: TraceableDate = EMPTY_MODEL_DATE;
    @Input() control!: FormControl;

    overwriteValue() {
        if (this.isEditable) {
            this.control.setValue(DateUtil.toJsDate(this.baselineValue.date));
        }
    }

    getChipColor() {
        if (DateUtil.toIsoDate(this.control.value) === this.baselineValue.date && this.baselineValue.date !== this.value.date) {
            return "chip-overwritten-value";
        } else if (this.baselineValue.date === this.value.date) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }
}
