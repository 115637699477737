import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectSelectedDeal} from "../../store/deal/deal.selectors";

@Component({
    selector: "valumize-deal-cashflows",
    templateUrl: "./deal-cashflows.component.html"
})
export class DealCashflowsComponent {

    displaySecondaryColumns = false;
    selectedDeal$ = this.store.select(selectSelectedDeal);

    constructor(private readonly store: Store) {
    }

    toggleSecondaryColumns() {
        this.displaySecondaryColumns = !this.displaySecondaryColumns;
    }
}
