/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {FundService} from "../../services/fund.service";
import {FundActions, FundDetailActions, FundListActions, FundNameActions, FundNoteActions, FundReportActions} from "../fund.actions";
import {NoteService} from "../../../shared/services/note/note.service";
import {Store} from "@ngrx/store";
import {selectHasBaselineFund, selectSelectedFund, selectSelectedFundNotes} from "./fund.selectors";
import {Fund} from "../../models/fund";
import {EMPTY_FUND} from "../fund.reducer";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {DealActions} from "../../../deal/store/deal.actions";
import {selectSelectedSourceDataset} from "../../../import/store/import.selectors";
import {tap} from "rxjs/operators";

export const initFundOverview = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundListActions.open),
            switchMap(() => of(
                FundActions.clearfundstate(),
                DealActions.cleardealstate(),
                FundListActions.load()
            ))
        );
    },
    {functional: true}
);

export const initFundDetailsPage = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.open),
            switchMap((action) =>
                !!action.dealId
                    ? of(DealActions.load({dealId: action.dealId}), FundActions.load({fundId: action.fundId}))
                    : of(DealActions.cleardealstate(), FundActions.load({fundId: action.fundId})))
        );
    },
    {functional: true}
);

export const loadFundList = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundListActions.load),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) =>
                fundService.getFunds(dataset).pipe(
                    map((funds) => FundListActions.loaded({funds})),
                    catchError((error: { message: string }) =>
                        of(FundListActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadFundListOnManageFundsDialog = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundListActions.loadfordeal),
            switchMap(() => of(
                FundActions.clearfundstate(),
                FundListActions.load()
            ))
        );
    },
    {functional: true}
);

export const loadFund = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.load),
            withLatestFrom(store$.select(selectHasBaselineFund)),
            exhaustMap(([action, hasBaselineFund]) =>
                fundService.getFund(action.fundId).pipe(
                    switchMap((fund) => {
                        return (fund.mergeTargetId && !hasBaselineFund)
                            ? of(FundActions.loaded({fund}), FundActions.loadbaseline({fundId: fund.mergeTargetId}))
                            : of(FundActions.loaded({fund}));
                    }),
                    catchError((error: { message: string }) =>
                        of(FundActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadBaselineFund = createEffect(
    (actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.loadbaseline),
            exhaustMap((action) =>
                fundService.getFund(action.fundId).pipe(
                    map((fund) => FundActions.loadedbaseline({fund})),
                    catchError((error: { message: string }) =>
                        of(FundActions.loaderror({errorMsg: "Baseline Fund" + error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadFundNotes = createEffect(
    (actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(FundActions.load, FundNoteActions.load),
            exhaustMap((action) => {
                const inContext = "inContext" in action
                    ? action.inContext
                    : `/funds/fund_id=${action.fundId}/`;
                return noteService.getNotes(inContext).pipe(
                    map((notes) => FundNoteActions.loaded({notes})),
                    catchError((error: { message: string }) =>
                        of(FundNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveFundNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(FundNoteActions.save),
            withLatestFrom(store$.select(selectSelectedFundNotes)),
            exhaustMap(([action, state]) => {
                return noteService.save(action.note, action.context).pipe(
                    map(() => FundNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) =>
                        of(FundNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const deleteFundNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(FundNoteActions.delete),
            withLatestFrom(store$.select(selectSelectedFundNotes)),
            exhaustMap(([action, state]) => {
                if (action.note.id === undefined) {
                    return of();
                }
                return noteService.delete(action.note.id).pipe(
                    map(() => FundNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) =>
                        of(FundNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveFundName = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundNameActions.save),
            withLatestFrom(store$.select(selectSelectedFund)),
            exhaustMap(([action, state]) => {
                const selectedFund: Fund = state.fund;
                if (selectedFund.id) {
                    const fundId = selectedFund.id;
                    return fundService.saveFund({
                        ...selectedFund,
                        ...{
                            name: {
                                ...selectedFund.name,
                                text: action.fundName
                            }
                        }
                    }, fundId).pipe(
                        map((fund) => FundActions.loaded({fund})),
                        catchError((error: { message: string }) =>
                            of(FundActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const createFund = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.create),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) => {
                return fundService.saveFund({
                    ...EMPTY_FUND,
                    ...{
                        name: {
                            ...EMPTY_FUND.name,
                            text: action.fundName
                        }
                    }
                }, undefined, dataset).pipe(
                    switchMap((fund) => of(
                        DealActions.cleardealstate(),
                        FundActions.clearfundstate(),
                        FundActions.loaded({fund})
                    )),
                    catchError((error: { message: string }) =>
                        of(FundActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveFundDetails = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundDetailActions.save),
            withLatestFrom(store$.select(selectSelectedFund)),
            exhaustMap(([action, state]) => {
                const selectedFund: Fund = state.fund;
                if (selectedFund.id) {
                    const fundId = selectedFund.id;
                    return fundService.saveFund({
                        ...selectedFund,
                        ...{
                            vintageYear: {
                                ...selectedFund.vintageYear,
                                date: action.vintageYear
                            },
                            purchaseYear: {
                                ...selectedFund.purchaseYear,
                                date: action.purchaseYear
                            },
                            endYear: {
                                ...selectedFund.endYear,
                                date: action.endYear
                            },
                            managementFee: {
                                ...selectedFund.managementFee,
                                fraction: (action.managementFee) ? DecimalFormatPipe.transformPercentToFraction(action.managementFee) : undefined
                            },
                            carry: {
                                ...selectedFund.carry,
                                fraction: (action.carry) ? DecimalFormatPipe.transformPercentToFraction(action.carry) : undefined
                            },
                            hurdleRate: {
                                ...selectedFund.hurdleRate,
                                fraction: (action.hurdleRate) ? DecimalFormatPipe.transformPercentToFraction(action.hurdleRate) : undefined
                            },
                            stage: {
                                ...selectedFund.stage,
                                code: action.stage
                            },
                            managementFeeCalculationMethod: {
                                ...selectedFund.managementFeeCalculationMethod,
                                code: action.managementFeeCalculation
                            },
                            regions: action.regions,
                            currencyIso: {
                                ...selectedFund.currencyIso,
                                code: action.currencyIso
                            },
                            country: {
                                ...selectedFund.country,
                                code: action.country
                            },
                            size: {
                                ...selectedFund.size,
                                amount: (action.size) ? DecimalFormatPipe.transformToMillionsNum(action.size) : undefined
                            },
                            mainFundSize: {
                                ...selectedFund.mainFundSize,
                                amount: (action.mainFundSize) ? DecimalFormatPipe.transformToMillionsNum(action.mainFundSize) : undefined
                            }
                        }
                    }, fundId).pipe(
                        map((fund) => FundActions.loaded({fund})),
                        catchError((error: { message: string }) =>
                            of(FundActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const setMergeTargetId = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.setmergetargetid),
            withLatestFrom(store$.select(selectSelectedFund)),
            exhaustMap(([action, selectedFundData]) => {
                const selectedFund: Fund = selectedFundData.fund;
                const fundId = selectedFund.id;
                if (fundId) {
                    return fundService.saveFund({
                        ...selectedFund,
                        ...{
                            mergeTargetId: action.mergeTargetId
                        }
                    }, fundId).pipe(
                        switchMap((fund) => of(
                            FundActions.savedloaded({fund}),
                            FundReportActions.setmergetargetid({fundId, mergeTargetId: undefined}))
                        ),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) =>
                            of(FundActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);
