export class ApiUtil {

    /**
     * Converts an api relative path to the full api path
     *
     * @param path the path that is relative to 'api'
     */
    public static toApiPath(path: string): string {
        let apiPath = "../api";
        if (path.startsWith("/")) {
            path = path.substr(1);
        }

        if (!path.endsWith("/")) {
            apiPath += "/";
        }
        return `${apiPath}${path}`;
    }
}
