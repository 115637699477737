<h2 mat-dialog-title>Manage Fund Valuation</h2>
<mat-dialog-content>
    <p>If the valuation already exists, please select it.</p>
    <form [formGroup]="fundValuationForm">
        <div class="d-flex">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Closing Date</mat-label>
                    <input matInput
                           formControlName="closingDate"
                           id="closingDate"
                           [matDatepicker]="closingDatePicker">
                    <mat-hint>dd/MM/yyyy</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="closingDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #closingDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
    <ng-container *ngIf="fundValuationsForFundOnReportDate$ | async as valuations">
        <table mat-table [dataSource]="valuations">
            <ng-container matColumnDef="id" sticky>
                <th mat-header-cell *matHeaderCellDef>Fund Valuation ID</th>
                <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>

            <ng-container matColumnDef="reportDate">
                <th mat-header-cell *matHeaderCellDef>Report Date</th>
                <td mat-cell *matCellDef="let element">{{element.reportDate | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="closingDate">
                <th mat-header-cell *matHeaderCellDef>Closing Date</th>
                <td mat-cell *matCellDef="let element">{{element.closingDate | traceableFormat}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>

            <tr mat-row
                *matRowDef="let row; columns: columns"
                [routerLink]="'../../funds/' + fundId"
                [queryParams]="{fundReportId: (fundReportId$ | async), fundValuationId: row.id}"
                mat-dialog-close></tr>
        </table>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <ng-container *ngIf="fundReportDate$ | async as reportDate">
        <ng-container *ngIf="fundReportId$ | async as fundReportId">
            <button
                mat-stroked-button
                color="primary"
                mat-dialog-close
                data-test="save-button-bottom"
                [disabled]="fundValuationForm.invalid"
                (click)="createFundValuation(reportDate.date, fundReportId)">
                Add Fund Valuation
            </button>
        </ng-container>
    </ng-container>
    <button
        mat-stroked-button mat-dialog-close
        data-test="cancel-button">
        Cancel
    </button>
</mat-dialog-actions>
