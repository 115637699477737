/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {FundValuationActions, FundValuationAggregatedCashflowAction, FundValuationScenarioActions, ReturnSummaryActions, UndrawnValuationActions} from "../fund.actions";
import {catchError, concatMap, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {FundValuationService} from "../../services/fund-valuation.service";
import {Store} from "@ngrx/store";
import {EMPTY_FUND_VALUATION} from "../fund.reducer";
import {selectSelectedFundValuationId} from "./fund-valuation.selectors";
import {selectSelectedFundId} from "../fund/fund.selectors";
import {UndrawnValuation} from "../../models/undrawn-valuation";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";

export const loadFundValuation = createEffect(
    (actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationActions.load),
            exhaustMap((action) => {
                return fundValuationService.getFundValuation(action.fundId, action.fundValuationId).pipe(
                    switchMap((fundValuation) => of(
                            FundValuationActions.loaded({fundValuation}),
                            FundValuationScenarioActions.loadall({fundId: action.fundId, fundValuationId: action.fundValuationId}),
                            ReturnSummaryActions.calc({fundId: action.fundId, fundValuationId: action.fundValuationId})
                        )
                    ),
                    catchError((error: { message: string }) =>
                        of(FundValuationActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadFundValuations = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationActions.loadall),
            exhaustMap((action) =>
                fundValuationService.getValuationsForFund(action.fundId).pipe(
                    map((valuations) => FundValuationActions.loadedall({fundId: action.fundId, valuations})),
                    catchError((error: { message: string }) =>
                        of(FundValuationActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const createFundValuation = createEffect(
    (actions$ = inject(Actions), router = inject(Router), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationActions.create),
            exhaustMap((action) => {
                if (!!action.fundId) {
                    return fundValuationService.save(
                        action.fundId,
                        {
                            ...EMPTY_FUND_VALUATION,
                            ...{
                                reportDate: {
                                    ...EMPTY_FUND_VALUATION.reportDate,
                                    date: action.reportDate
                                }
                            },
                            ...{
                                closingDate: {
                                    ...EMPTY_FUND_VALUATION.closingDate,
                                    date: action.closingDate
                                }
                            },
                            fundAssetValuations: []
                        }
                    ).pipe(
                        map((fundValuation) => FundValuationActions.loaded({fundValuation})),
                        tap((loadedAction) => {
                            if (loadedAction.type === FundValuationActions.loaded.type) {
                                router.navigate([], {queryParams: {fundValuationId: loadedAction.fundValuation.id}});
                            }
                        }),
                        catchError((error: { message: string }) =>
                            of(FundValuationActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundValuationActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadFundValuationScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationScenarioActions.loadall),
            exhaustMap((action) => {
                if (!!action.fundId && !!action.fundValuationId) {
                    return fundValuationService.getScenariosForFundValuation(action.fundId, action.fundValuationId).pipe(
                        map((scenarios) => FundValuationScenarioActions.loadedall({scenarios})),
                        catchError((error: { message: string }) =>
                            of(FundValuationScenarioActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundValuationScenarioActions.loaderror({errorMsg: "Fund valuation id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveFundValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.save),
            withLatestFrom(store$.select(selectSelectedFundId)),
            exhaustMap(([action, fundId]) => {
                if (fundId === undefined) {
                    return of(FundValuationActions.loaderror({errorMsg: "Fund Id is required"}));
                }
                return fundValuationService.save(fundId, action.fundValuation).pipe(
                    switchMap((fundValuation) => of(
                        FundValuationActions.savedloaded({fundId, fundValuation}),
                        !!fundValuation.id
                            ? ReturnSummaryActions.calc({fundId, fundValuationId: fundValuation.id})
                            : ReturnSummaryActions.calcerror({errorMsg: "Fund Valuation could no be loaded"})
                    )),
                    catchError((error: { message: string }) =>
                        of(FundValuationActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveFundValuationScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundValuationId)),
            exhaustMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(FundValuationScenarioActions.loaderror({errorMsg: "Fund Id and Fund Valuation Id are required"}));
                }
                return fundValuationService.saveScenariosForFundValuation(fundId, fundValuationId, action.scenarios).pipe(
                    switchMap((scenarios) => of(
                        FundValuationScenarioActions.loadedall({scenarios}),
                        ReturnSummaryActions.calc({fundId, fundValuationId})
                    )),
                    catchError((error: { message: string }) =>
                        of(FundValuationScenarioActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const calcUndrawnValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            exhaustMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(UndrawnValuationActions.calcerror({errorMsg: "Fund Id and Fund Valuation Id are required"}));
                }
                const undrawnValuation: UndrawnValuation = {
                    fundValuation: action.fundValuation,
                    scenarios: action.scenarios
                };
                return fundValuationService.calcUndrawnValuation(fundId, fundValuationId, undrawnValuation).pipe(
                    map((undrawn) => UndrawnValuationActions.calcsuccess({fundValuation: undrawn.fundValuation, scenarios: undrawn.scenarios})),
                    catchError((error: any) =>
                        of(UndrawnValuationActions.calcerror({errorMsg: error.error.expectedException?.message ?? error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadFundValuationAggregatedCashflow = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationAggregatedCashflowAction.load),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            concatMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(FundValuationAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: "Fund id and Fund valuation id is required"}));
                }
                return fundValuationService.getAggregatedFundCashflows(fundId, fundValuationId, action.scenarioId).pipe(
                    map((aggregatedCashflows) => FundValuationAggregatedCashflowAction.loaded({aggregatedCashFlows: aggregatedCashflows, scenarioType: action.scenarioType})),
                    catchError((error: any) =>
                        of(FundValuationAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: error.error.expectedException?.message ?? error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const calcReturnSummary = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(ReturnSummaryActions.calc),
            exhaustMap((action) => {
                if (action.fundId === undefined || action.fundValuationId === undefined) {
                    return of(ReturnSummaryActions.calcerror({errorMsg: "Fund Id and Fund Valuation Id are required"}));
                }
                return fundValuationService.calcReturnSummary(action.fundId, action.fundValuationId).pipe(
                    map((returnSummary) => ReturnSummaryActions.calcsuccess({returnSummary})),
                    catchError((error: { message: string }) =>
                        of(ReturnSummaryActions.calcerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);
