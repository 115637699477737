import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AssetActions, AssetNameActions, AssetValuationActions} from "../store/asset/asset.actions";
import {
    selectAssetName,
    selectBaselineAsset,
    selectIsAnyAssetFormInEditMode,
    selectSelectedAsset,
    selectSelectedFirstAssetValuationIdForAsset,
} from "../store/asset/asset.selectors";
import {selectSelectedFund, selectSortedInvestments} from "../../fund/store/fund/fund.selectors";
import {map, Subscription} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {EMPTY_TEXT, TraceableText} from "../../shared/model/traceable";
import {MatDialog} from "@angular/material/dialog";
import {AssetValuationNewDialogComponent} from "./asset-valuation-new-dialog/asset-valuation-new-dialog.component";
import {FundInvestmentActions, FundReportActions} from "../../fund/store/fund.actions";
import {selectSelectedFundReportReportDate} from "../../fund/store/fund-report/fund-report.selectors";
import {selectSelectedAssetValuation} from "../store/asset-valuation/asset-valuation.selectors";
import {selectSelectedFundValuation, selectSelectedFundValuationClosingDate} from "../../fund/store/fund-valuation/fund-valuation.selectors";
import {selectSelectedDeal} from "../../deal/store/deal/deal.selectors";
import {selectSelectedFundInvestment} from "../../fund/store/fund-investment/fund-investment.selectors";
import {selectSelectedSourceDataset} from "../../import/store/import.selectors";
import {FundValuation} from "../../fund/models/fund-valuation";
import {PartnershipInvestmentRecord} from "../../fund/models/partnership-investment-record";
import {CurrentOrRealizedInvestmentRecord} from "../../fund/models/current-or-realized-investment-record";

@Component({
    selector: "valumize-asset-details-page",
    templateUrl: "./asset-details-page.component.html",
    styleUrls: ["./asset-details-page.component.scss"]
})
export class AssetDetailsPageComponent implements OnInit, OnDestroy {

    assetId?: number;
    fundId?: number;
    fundReportId?: number;
    fundValuationId?: number;
    assetValuationId?: number;
    name: TraceableText = EMPTY_TEXT;
    assetNameIsEditable = false;
    searchEnabled = false;

    selectedAsset$ = this.store.select(selectSelectedAsset);
    baselineAssset$ = this.store.select(selectBaselineAsset);
    selectedAssetValuation$ = this.store.select(selectSelectedAssetValuation);
    selectedFund$ = this.store.select(selectSelectedFund);
    fundInvestment$ = this.store.select(selectSelectedFundInvestment);
    selectedFundValuation$ = this.store.select(selectSelectedFundValuation);
    sortedInvestments$ = this.store.select(selectSortedInvestments);
    deal$ = this.store.select(selectSelectedDeal);
    reportDate$ = this.store.select(selectSelectedFundReportReportDate);
    closingDate$ = this.store.select(selectSelectedFundValuationClosingDate);
    sourceDataset$ = this.store.select(selectSelectedSourceDataset);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    subscriptions: Subscription[] = [];

    nameForm = this.formBuilder.group({
        assetName: this.formBuilder.control("", {nonNullable: true, validators: Validators.maxLength(100)}),
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        public dialog: MatDialog
    ) {
        this.subscriptions.push(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.reloadPage();
            }
        }));
    }

    ngOnInit() {
        this.assetId = parseInt(this.route.snapshot.paramMap.get("assetId") ?? "", 10);

        this.subscriptions.push(
            this.store.select(selectAssetName).pipe(map((name) => {
                this.name = name.name;
                this.assetNameIsEditable = name.assetNameIsEditable;
                this.nameForm.patchValue({
                    assetName: name.name?.text
                });
                if (name.assetNameIsEditable) {
                    this.nameForm.enable();
                } else {
                    this.nameForm.disable();
                }
            })).subscribe());
    }

    reloadPage(): void {
        this.assetId = parseInt(this.route.snapshot.paramMap.get("assetId") ?? "", 10);
        this.fundId = this.getQueryParam("fundId");
        this.fundReportId = this.getQueryParam("fundReportId");
        this.fundValuationId = this.getQueryParam("fundValuationId");
        this.assetValuationId = this.getQueryParam("assetValuationId");
        const fundInvestmentId = this.getQueryParam("fundInvestmentId");

        this.store.dispatch(AssetActions.open({assetId: this.assetId, fundId: this.fundId}));
        if (!!this.fundId) {
            if (!!this.fundReportId) {
                this.store.dispatch(FundReportActions.load({fundId: this.fundId, fundReportId: this.fundReportId, fundValuationId: this.fundValuationId}));

                if (!!fundInvestmentId) {
                    this.store.dispatch(FundInvestmentActions.load({fundId: this.fundId, fundReportId: this.fundReportId, fundInvestmentId}));

                    if (!!this.assetValuationId) {
                        this.store.dispatch(AssetValuationActions.load({assetId: this.assetId, assetValuationId: this.assetValuationId}));
                    } else {
                        this.store.dispatch(AssetValuationActions.loadall({assetId: this.assetId}));
                        this.checkAndLoadAssetValuation();
                    }
                }
            }
        }
    }

    getQueryParam(queryParamToFind: string): number | undefined {
        const queryParam = this.route.snapshot.queryParamMap.get(queryParamToFind);
        return !!queryParam ? parseInt(queryParam, 10) : undefined;
    }

    scrollToComponent(componentId: string) {
        document.getElementById(componentId)?.scrollIntoView({behavior: "auto"});
    }

    navigateTo(target: any, partnershipInvestmentRecords: (PartnershipInvestmentRecord | CurrentOrRealizedInvestmentRecord)[]) {
        const selectedInvestment = partnershipInvestmentRecords.find(investment => investment.assetId === +target.value);
        if (selectedInvestment) {
            this.router.navigate(["../../assets/" + selectedInvestment.assetId], {
                queryParams: {
                    fundId: this.fundId,
                    fundReportId: this.fundReportId,
                    fundValuationId: this.fundValuationId,
                    fundInvestmentId: selectedInvestment.fundInvestmentId,
                    assetValuationId: this.isPartnershipInvestmentRecord(selectedInvestment) ? selectedInvestment.assetValuationId : null
                }
            });
        }
    }

    private readonly isPartnershipInvestmentRecord = (object: any): object is PartnershipInvestmentRecord => object.hasOwnProperty("assetValuationId");

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    editMode = () => this.store.dispatch(AssetNameActions.edit());

    save() {
        if (this.nameForm.valid) {
            this.store.dispatch(AssetNameActions.save(this.nameForm.getRawValue()));
        }
    }

    cancel = () => this.store.dispatch(AssetNameActions.cancel());

    checkAndLoadAssetValuation() {
        if (this.assetValuationId === undefined) {
            this.subscriptions.push(this.store.select(selectSelectedFirstAssetValuationIdForAsset).pipe(
                map((assetValuation) => {
                    if (assetValuation) {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: {assetValuationId: assetValuation.id},
                            queryParamsHandling: "merge",
                            skipLocationChange: false,
                            replaceUrl: false
                        });
                    }
                })
            ).subscribe());
        }
    }

    openNewAssetValuationDialog(fundValuation: FundValuation) {
        const dialogRef = this.dialog.open(AssetValuationNewDialogComponent);
        dialogRef.componentInstance.assetId = this.assetId;
        dialogRef.componentInstance.fundValuation = fundValuation;
    }

    selectMergeTarget(searchResultReference: string) {
        const regex = /^\/assets\/(\d+)$/;
        const match = searchResultReference.match(regex);
        if (match) {
            const mergeTargetId = Number(match[1]);
            this.store.dispatch(AssetActions.setmergetargetid({mergeTargetId}));
        }
    }

    deselectAssetMergeTarget() {
        this.store.dispatch(AssetActions.setmergetargetid({mergeTargetId: undefined}));
    }

    displaySearch() {
        this.searchEnabled = !this.searchEnabled;
    }
}
