import {sharedStoreFeatureKey} from "./shared.reducer";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SharedState} from "./shared.state";
import {CodeUtil} from "../utils/code-util";

export const selectSharedState = createFeatureSelector<SharedState>(sharedStoreFeatureKey);

export const selectCodes = createSelector(
    selectSharedState,
    (state) =>
        state.codes.data
);

export const selectCodesFromTable = (codeTable: string) => createSelector(
    selectCodes,
    (codes) => {
        const codesForCodeTable = codes.filter((code) => code.table === codeTable);
        return CodeUtil.sortBySortIndex(codesForCodeTable);
    }
);

export const selectCodeTranslation = (code: string, codeTable: string) => createSelector(
    selectCodes,
    (codes) =>
        codes.find((codeObject) => codeObject.code === code && codeObject.table === codeTable)?.translations.en
);
