<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Partnership Investments</mat-card-title>
    </mat-card-header>
    <mat-card-subtitle>
        <ng-container *ngIf="(selectedFundInvestmentsForFundReport$ | async) as fundInvestmentsForFundReport">
            <valumize-info-panel *ngIf="fundInvestmentsForFundReport.status === 'ERROR'" [appearance]="'ERROR'"
                                 [message]="fundInvestmentsForFundReport.errorMessage"></valumize-info-panel>
            <valumize-info-panel *ngIf="fundInvestmentsForFundReport.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Company successfully added'"></valumize-info-panel>
            <mat-progress-spinner *ngIf="fundInvestmentsForFundReport.status === 'LOADING'"></mat-progress-spinner>
        </ng-container>
    </mat-card-subtitle>
    <ng-container *ngIf="fundId$ | async as fundId">
        <ng-container *ngIf="(selectedFundReport$ | async)?.fundReport?.data?.id as fundReportId; else selectReportFirst">
            <mat-card-content class="detail-card-content">
                <ng-container *ngIf="partnershipInvestments$ | async as partnershipInvestments">
                    <section class="table-container" tabindex="0">
                        <form [formGroup]="partnershipInvestmentForm">
                            <table mat-table [dataSource]="partnershipInvestments.tableDatasource">
                                <ng-container matColumnDef="company" sticky>
                                    <th mat-header-cell *matHeaderCellDef>Company</th>
                                    <td mat-cell *matCellDef="let element" class="no-wrap">{{element.company | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="activityIndustry">
                                    <th mat-header-cell *matHeaderCellDef>Activity/Industry</th>
                                    <td mat-cell *matCellDef="let element">{{element.activityIndustry | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="country">
                                    <th mat-header-cell *matHeaderCellDef>Country</th>
                                    <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
                                </ng-container>

                                <ng-container matColumnDef="gpIndication">
                                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">GP Indication</th>
                                    <td mat-cell *matCellDef="let element">{{element.gpIndication.code | codeFormat: codeTableGpIndication | async}}</td>
                                </ng-container>

                                <ng-container matColumnDef="investmentDate">
                                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">Inv. Date</th>
                                    <td mat-cell *matCellDef="let element">{{element.investmentDate | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="percentageHeld">
                                    <th mat-header-cell *matHeaderCellDef>% held</th>
                                    <td mat-cell *matCellDef="let element">{{element.percentageHeld | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="remainingCost">
                                    <th mat-header-cell *matHeaderCellDef>Remaining Cost</th>
                                    <td mat-cell *matCellDef="let element">{{element.remainingCost | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="gpNAV">
                                    <th mat-header-cell *matHeaderCellDef>GP NAV</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="!isEditable; else editNAV">
                                            {{element.gpNAV | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editNAV>
                                            <ng-container *ngIf="isEditableNavColumnCell(element.company.text)">
                                                <input matInput
                                                       type="number"
                                                       (click)="$event.stopPropagation()"
                                                       formControlName="{{createFormControlName(element.company.text, 'GpNAV')}}"
                                                       id="{{createFormControlName(element.company.text, 'GpNAV')}}">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditableNavColumnCell(element.company.text)">
                                                {{element.gpNAV | traceableFormat}}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="unrealizedTVPI">
                                    <th mat-header-cell *matHeaderCellDef>Unrealized TVPI</th>
                                    <td mat-cell *matCellDef="let element">{{element.unrealizedTVPI | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="totalTVPI">
                                    <th mat-header-cell *matHeaderCellDef>Total TVPI</th>
                                    <td mat-cell *matCellDef="let element">{{element.totalTVPI | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="exitDateAssumption">
                                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">Exit Date <br/> Assumption</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="!isEditable; else editExitDateAssumption">
                                            {{element.exitDateAssumption | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editExitDateAssumption>
                                            <ng-container *ngIf="isEditableCell(element.company.text)">
                                                <input matInput
                                                       (click)="$event.stopPropagation()"
                                                       formControlName="{{createFormControlName(element.company.text, 'ExitDateAssumption')}}"
                                                       id="{{createFormControlName(element.company.text, 'ExitDateAssumption')}}"
                                                       [matDatepicker]="ExitDateAssumptionDatePicker">
                                                <mat-datepicker-toggle matIconSuffix [for]="ExitDateAssumptionDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #ExitDateAssumptionDatePicker></mat-datepicker>
                                            </ng-container>
                                            <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                {{element.exitDateAssumption | traceableFormat}}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="discountRate">
                                    <th mat-header-cell *matHeaderCellDef>Discount Rate</th>
                                    <td mat-cell *matCellDef="let element">{{element.discountRate | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="selectedBid">
                                    <th mat-header-cell *matHeaderCellDef>Selected Bid</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="!isEditable; else editBidPriceAdjustment">
                                            {{element.selectedBid | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editBidPriceAdjustment>
                                            <ng-container *ngIf="isEditableBidColumnCell(element.company.text) && !!fundValuationId">
                                                <input matInput
                                                       type="number"
                                                       (click)="$event.stopPropagation()"
                                                       formControlName="{{createFormControlName(element.company.text, 'SelectedBid')}}"
                                                       id="{{createFormControlName(element.company.text, 'SelectedBid')}}">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditableBidColumnCell(element.company.text)">
                                                {{element.selectedBid | traceableFormat}}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="discountToNAV">
                                    <th mat-header-cell *matHeaderCellDef>Discount to NAV</th>
                                    <td mat-cell *matCellDef="let element">{{element.discountToNAV | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="percentageOfTotalBid">
                                    <th mat-header-cell *matHeaderCellDef>% of total Bid</th>
                                    <td mat-cell *matCellDef="let element">{{element.percentageOfTotalBid | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="percentageOfTotalNAV">
                                    <th mat-header-cell *matHeaderCellDef>% of total NAV</th>
                                    <td mat-cell *matCellDef="let element">{{element.percentageOfTotalNAV | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="stabilityOfCFs">
                                    <th mat-header-cell *matHeaderCellDef>Stability of CFs</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="{{setBackgroundColor(element.stabilityOfCFs.code)}}">{{element.stabilityOfCFs.code | codeFormat: codeTableAssessment | async}}</td>
                                </ng-container>

                                <ng-container matColumnDef="cashflowLow">
                                    <th mat-header-cell *matHeaderCellDef>Low Case</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="!isEditable; else editCashflowLow">
                                            {{getDisplayScenarioValue(element.cashflowLow, partnershipInvestments.partnershipInvestment.netCurrentAssets) | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editCashflowLow>
                                            <ng-container *ngIf="isEditableCell(element.company.text)">
                                                <input matInput
                                                       type="number"
                                                       (click)="$event.stopPropagation()"
                                                       formControlName="{{createFormControlName(element.company.text, 'CashflowLow')}}"
                                                       id="{{createFormControlName(element.company.text, 'CashflowLow')}}">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                {{element.cashflowLow | traceableFormat}}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="cashflowBase">
                                    <th mat-header-cell *matHeaderCellDef>Base Case</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="!isEditable; else editCashflowBase">
                                            {{getDisplayScenarioValue(element.cashflowBase, partnershipInvestments.partnershipInvestment.netCurrentAssets) | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editCashflowBase>
                                            <ng-container *ngIf="isEditableCell(element.company.text)">
                                                <input matInput
                                                       type="number"
                                                       (click)="$event.stopPropagation()"
                                                       formControlName="{{createFormControlName(element.company.text, 'CashflowBase')}}"
                                                       id="{{createFormControlName(element.company.text, 'CashflowBase')}}">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                {{element.cashflowBase | traceableFormat}}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="cashflowHigh">
                                    <th mat-header-cell *matHeaderCellDef>High Case</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="!isEditable; else editCashflowHigh">
                                            {{getDisplayScenarioValue(element.cashflowHigh, partnershipInvestments.partnershipInvestment.netCurrentAssets) | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editCashflowHigh>
                                            <ng-container *ngIf="isEditableCell(element.company.text)">
                                                <input matInput
                                                       type="number"
                                                       (click)="$event.stopPropagation()"
                                                       formControlName="{{createFormControlName(element.company.text, 'CashflowHigh')}}"
                                                       id="{{createFormControlName(element.company.text, 'CashflowHigh')}}">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                {{element.cashflowHigh | traceableFormat}}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="partnershipInvestments.tableColumns"></tr>
                                <tr mat-row
                                    *matRowDef=" let row; columns: partnershipInvestments.tableColumns"
                                    class="{{styleRowImportant(row)}} {{styleRowBorderTop(row)}} {{styleRowNotClickable(row)}} {{styleRowExited(row)}}"
                                    [routerLink]="'../../assets/' + row.assetId"
                                    [queryParams]="{
                                                    fundId: fundId,
                                                    fundReportId: fundReportId,
                                                    fundValuationId: fundValuationId,
                                                    fundInvestmentId: row.fundInvestmentId,
                                                    assetValuationId: row.assetValuationId}"
                                ></tr>
                            </table>
                        </form>
                    </section>
                    <mat-expansion-panel [expanded]="panelOpenState" class="mat-expansion-panel">
                        <mat-expansion-panel-header class="mat-expansion-panel-header">
                            <mat-card-title data-test="title">Current and Realized Investments</mat-card-title>
                        </mat-expansion-panel-header>
                        <section class="table-container" tabindex="0">
                            <table mat-table [dataSource]="partnershipInvestments.performanceTotals">
                                <ng-container matColumnDef="company" sticky>
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element" class="no-wrap">{{element.company | traceableFormat}}</td>
                                    <td mat-footer-cell *matFooterCellDef>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.company | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="realizedCost">
                                    <th mat-header-cell *matHeaderCellDef>Realized Cost</th>
                                    <td mat-cell *matCellDef="let element">{{element.realizedCost | traceableFormat}}</td>
                                    <td mat-footer-cell
                                        *matFooterCellDef>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.realizedCost | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="realizedGains">
                                    <th mat-header-cell *matHeaderCellDef>Realized Gains</th>
                                    <td mat-cell *matCellDef="let element">{{element.realizedGains | traceableFormat}}</td>
                                    <td mat-footer-cell
                                        *matFooterCellDef>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.realizedGains | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="totalCost">
                                    <th mat-header-cell *matHeaderCellDef>Total Cost</th>
                                    <td mat-cell *matCellDef="let element">{{element.totalCost | traceableFormat}}</td>
                                    <td mat-footer-cell *matFooterCellDef>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.totalCost | traceableFormat}}</td>
                                </ng-container>

                                <ng-container matColumnDef="totalRealized">
                                    <th mat-header-cell *matHeaderCellDef>Total Realized</th>
                                    <td mat-cell *matCellDef="let element">{{element.totalRealized | traceableFormat}}</td>
                                    <td mat-footer-cell
                                        *matFooterCellDef>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.totalRealized | traceableFormat}}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="partnershipInvestments.performanceTotalColumns"></tr>
                                <tr mat-row *matRowDef=" let row; columns: partnershipInvestments.performanceTotalColumns" class="not-clickable"></tr>
                                <tr mat-footer-row *matFooterRowDef="partnershipInvestments.performanceTotalColumns" class="row-important row-top-border"></tr>
                            </table>
                        </section>
                        <valumize-fund-current-and-realized-investments
                            [fundId]="fundId"
                            [fundReportId]="fundReportId"
                            [fundValuationId]="fundValuationId"
                            [dataSource]="partnershipInvestments.currentInvestments"
                            [columnDefinition]="partnershipInvestments.currentAndRealizedInvestmentColumns"
                            [title]="'Current Investments'">
                        </valumize-fund-current-and-realized-investments>
                        <valumize-fund-current-and-realized-investments
                            [fundId]="fundId"
                            [fundReportId]="fundReportId"
                            [fundValuationId]="fundValuationId"
                            [dataSource]="partnershipInvestments.realizedInvestments"
                            [columnDefinition]="partnershipInvestments.currentAndRealizedInvestmentColumns"
                            [title]="'Realized Investments'">
                        </valumize-fund-current-and-realized-investments>
                    </mat-expansion-panel>
                </ng-container>
            </mat-card-content>
            <mat-card-actions class="detail-card-actions">
                <button mat-stroked-button
                        color="primary"
                        (click)="openManageFundInvestmentsDialog(fundId, fundReportId)"
                        data-test="manage-fund-investments">
                    <mat-icon>settings</mat-icon>
                    Manage Investments
                </button>
                <button (click)="editMode()"
                        *ngIf="!isEditable"
                        [disabled]="(isEditDisabled$ | async)!"
                        color="primary"
                        data-test="edit-button"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="save()"
                        *ngIf="isEditable"
                        [disabled]="partnershipInvestmentForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <button (click)="cancel()"
                        *ngIf="isEditable"
                        color="primary"
                        data-test="cancel-button"
                        mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
        </ng-container>
    </ng-container>
    <ng-template #selectReportFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select fund report first!
        </mat-card-content>
    </ng-template>
</mat-card>
