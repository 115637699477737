import {ActionReducer, INIT, UPDATE} from "@ngrx/store";
import {ImportState} from "./import.state";
import {INITIAL_STATE} from "./import.reducer";

export const importHydrationMetaReducer =
    (reducer: ActionReducer<ImportState>): ActionReducer<ImportState> => (state, action) => {
        if (action.type === INIT || action.type === UPDATE) {
            const storageValue = localStorage.getItem("state");
            if (storageValue) {
                try {
                    return JSON.parse(storageValue);
                } catch {
                    localStorage.removeItem("state");
                }
            }
        }
        const nextState: ImportState = reducer(state, action);
        const nextStateToSave: ImportState = {
            ...INITIAL_STATE,
            ...{
                selectedSourceDataset: nextState.selectedSourceDataset,
                selectedTargetDataset: nextState.selectedTargetDataset,
                datasets: nextState.datasets
            }
        };
        localStorage.setItem("state", JSON.stringify(nextStateToSave));
        return nextState;
    };
