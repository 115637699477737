import {AfterViewChecked, AfterViewInit, Component, OnDestroy} from "@angular/core";
import {Chart, ChartDataset, registerables} from "chart.js";
import {Store} from "@ngrx/store";
import {
    selectBaseScenarioCashflowsGraph,
    selectHighScenarioCashflowsGraph,
    selectLowScenarioCashflowsGraph,
    selectSelectedFundValuationCashflowsGraph
} from "../../store/fund-valuation/fund-valuation.selectors";
import {map, Observable, Subscription} from "rxjs";
import {DateTime} from "luxon";

@Component({
    selector: "valumize-fund-cashflows-graph",
    templateUrl: "./fund-cashflows-graph.component.html",
    styleUrls: ["./fund-cashflows-graph.component.scss"]
})
export class FundCashflowsGraphComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    fundValuationCashflowsGraph$ = this.store.select(selectSelectedFundValuationCashflowsGraph);
    lowScenarioCashflowsGraph$ = this.store.select(selectLowScenarioCashflowsGraph);
    baseScenarioCashflowsGraph$ = this.store.select(selectBaseScenarioCashflowsGraph);
    highScenarioCashflowsGraph$ = this.store.select(selectHighScenarioCashflowsGraph);

    subscriptions: Subscription[] = [];
    graphPanelOpenState = true;
    scenarioGraphPanelOpenState = true;

    private chart: Chart | undefined;
    private lowChart: Chart | undefined;
    private baseChart: Chart | undefined;
    private highChart: Chart | undefined;

    constructor(
        private readonly store: Store,
    ) {
    }

    ngAfterViewInit() {
        Chart.register(...registerables);
        const ctx = document.getElementById("FundCashFlowsGraph") as HTMLCanvasElement;

        this.subscriptions.push(
            this.fundValuationCashflowsGraph$.pipe(map(graph => {
                if (this.chart) {
                    this.chart.destroy();
                }
                if (graph.labels.length > 0) {
                    this.chart = new Chart(ctx, {
                        type: "line",
                        data: graph,
                        options: {
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    grid: {
                                        color: (context) => {
                                            if (context.tick.value === 0) {
                                                return "rgba(0,0,0,0.2)";
                                            }
                                            return "rgba(0,0,0,0.1)";
                                        },
                                        lineWidth: (context) => context.tick.value === 0 ? 3 : 1
                                    }
                                }
                            }
                        }
                    });
                }
            })).subscribe()
        );
    }

    ngAfterViewChecked() {
        const lowCtx = document.getElementById("LowScenarioCashFlowsGraph") as HTMLCanvasElement;
        const baseCtx = document.getElementById("BaseScenarioCashFlowsGraph") as HTMLCanvasElement;
        const highCtx = document.getElementById("HighScenarioCashFlowsGraph") as HTMLCanvasElement;

        if (lowCtx && !this.lowChart) {
            this.setupBarChart(this.lowScenarioCashflowsGraph$, lowCtx, chart => this.lowChart = chart);
        }
        if (baseCtx && !this.baseChart) {
            this.setupBarChart(this.baseScenarioCashflowsGraph$, baseCtx, chart => this.baseChart = chart);
        }
        if (highCtx && !this.highChart) {
            this.setupBarChart(this.highScenarioCashflowsGraph$, highCtx, chart => this.highChart = chart);
        }
    }

    private setupBarChart(dataObservable$: Observable<any>, canvas: HTMLCanvasElement, setChartInstance: (chart: Chart) => void) {
        this.subscriptions.push(
            dataObservable$.pipe(map(graph => {
                if (canvas?.getContext("2d")) {
                    if (canvas.id === "LowScenarioCashFlowsGraph" && this.lowChart) {
                        this.lowChart.destroy();
                    }
                    if (canvas.id === "BaseScenarioCashFlowsGraph" && this.baseChart) {
                        this.baseChart.destroy();
                    }
                    if (canvas.id === "HighScenarioCashFlowsGraph" && this.highChart) {
                        this.highChart.destroy();
                    }

                    if (graph.labels.length > 0) {
                        const newChart = new Chart(canvas, {
                            type: "bar",
                            data: {
                                labels: graph.labels,
                                datasets: graph.datasets as ChartDataset<"bar" | "line", number[]>[]
                            },
                            options: {
                                maintainAspectRatio: false,
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        stacked: true,
                                        grid: {
                                            color: (context) => context.tick.value === 0 ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)",
                                            lineWidth: (context) => context.tick.value === 0 ? 3 : 1
                                        }
                                    },
                                    x: {
                                        stacked: true
                                    }
                                },
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: (context) => {
                                                const label = context.dataset.label ?? "";
                                                const value = context.raw;
                                                const lines = [`${label}: ${value}`];
                                                if (context.dataset.label === "Asset Distribution") {
                                                    const quarterIndex = context.dataIndex;
                                                    const quarterLabelFormatted = graph.labels[quarterIndex];
                                                    const quarterLabel = DateTime.fromFormat(quarterLabelFormatted, "MMM yyyy").endOf("month").toFormat("yyyy-MM-dd");

                                                    const assetDetails = graph.assetDistribution[quarterLabel];

                                                    if (assetDetails && Object.keys(assetDetails).length > 0) {
                                                        Object.entries(assetDetails).forEach(([company, amount]) => {
                                                            lines.push(`${company}: ${amount}`);
                                                        });
                                                    } else {
                                                        lines.push("No details available");
                                                    }
                                                }
                                                return lines;
                                            }
                                        }
                                    },
                                    legend: {
                                        display: true
                                    }
                                }
                            }
                        });
                        setChartInstance(newChart);
                    }
                }
            })).subscribe()
        );
    }

    private destroyCharts() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = undefined;
        }
        if (this.lowChart) {
            this.lowChart.destroy();
            this.lowChart = undefined;
        }
        if (this.baseChart) {
            this.baseChart.destroy();
            this.baseChart = undefined;
        }
        if (this.highChart) {
            this.highChart.destroy();
            this.highChart = undefined;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.destroyCharts();
    }
}
