<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Datasets</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <ng-container *ngIf="datasets$ | async as datasets">
            <table mat-table [dataSource]="filterMasterDataset(datasets)">
                <ng-container matColumnDef="source" sticky>
                    <th mat-header-cell *matHeaderCellDef>Source</th>
                    <td mat-cell *matCellDef="let element">{{element}}</td>
                </ng-container>

                <ng-container matColumnDef="arrow">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>arrow_right_alt</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="target">
                    <th mat-header-cell *matHeaderCellDef>Target</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-form-field appearance="outline">
                            <mat-select [value]="defaultTargetDataset">
                                <mat-option *ngFor="let targetDataset of filterSourceDataset(datasets, element)"
                                            [value]="targetDataset" (onSelectionChange)="updateSourceTargetMap(element, $event)">
                                    {{targetDataset}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <ng-container matColumnDef="prepareForMerge">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            mat-stroked-button
                            class="black-button"
                            data-test="prepare-for-merge"
                            (click)="routeToMergePage(element)">
                            Prepare for merge
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            class="overview-actions"
                            mat-icon-button
                            color="black"
                            data-test="delete"
                            (click)="deleteDataset(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>
            <mat-expansion-panel [expanded]="panelOpenState" class="mat-expansion-panel">
                <mat-expansion-panel-header class="mat-expansion-panel-header">
                    <mat-card-title data-test="title">Previous Accelex Imports</mat-card-title>
                </mat-expansion-panel-header>
                <valumize-accelex-import-logs></valumize-accelex-import-logs>
            </mat-expansion-panel>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="overview-actions">
        <button mat-stroked-button
                color="primary"
                data-test="import-dataset"
                (click)="openImportDatasetDialog()">
            <mat-icon>add</mat-icon>
            Import Dataset
        </button>
    </mat-card-actions>
</mat-card>
