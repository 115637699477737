export interface PrincipalResponse {
    id: number;
    login: string;
    roleNames: string[];
    permissions: string[];
    email?: string;
    languageCode?: string;
    lastName?: string;
    firstName?: string;
    activationKey?: string;
    branch?: string;
    externalAuthentication: boolean;
    locked: boolean;
}

export class Principal {
    readonly id: number;
    readonly userName: string;
    private readonly grantedRoles: string[];
    readonly permissions: string[];
    readonly branch: string | null;
    readonly email: string | null = null;
    readonly languageCode: string | null = null;
    readonly lastName: string | null = null;
    readonly firstName: string | null = null;
    readonly externalAuthentication: boolean;
    readonly locked: boolean;

    private constructor(principalResponse: PrincipalResponse) {
        this.userName = principalResponse.login;
        this.grantedRoles = principalResponse.roleNames.map(name => name.toLowerCase());
        this.permissions = principalResponse.permissions;
        this.id = principalResponse.id;
        this.branch = principalResponse.branch || null;
        this.languageCode = principalResponse.languageCode || null;
        this.lastName = principalResponse.lastName || null;
        this.firstName = principalResponse.firstName || null;
        this.email = principalResponse.email || null;
        this.externalAuthentication = principalResponse.externalAuthentication;
        this.locked = principalResponse.locked;
    }

    get roles(): string[] {
        return this.grantedRoles;
    }

    static fromPrincipalResponse(principalResponse: PrincipalResponse): Principal {
        return new Principal(principalResponse);
    }
}
