<mat-card class="detail-card">
    <mat-expansion-panel [expanded]="panelOpenState" class="mat-expansion-panel">
        <mat-expansion-panel-header class="mat-expansion-panel-header">
            <mat-card-title data-test="title">Companies (in Deal Currency)</mat-card-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="(dealCompany$ | async) as dealCompany">
            <section class="table-container" tabindex="0">
                <table mat-table [dataSource]="dealCompaniesDataSource" matSort #dealCompaniesSort="matSort" multiTemplateDataRows>
                    <ng-container matColumnDef="company" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                        <td mat-cell *matCellDef="let element" class="no-wrap">{{element.company.text}}</td>
                    </ng-container>
                    <ng-container matColumnDef="netCurrentAssets" sticky>
                        <td mat-footer-cell class="no-wrap" *matFooterCellDef>{{dealCompany.netCurrentAssetsRow.company | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="gpCarryReserve" sticky>
                        <td mat-footer-cell class="no-wrap" *matFooterCellDef>{{dealCompany.gpCarryReserveRow.company | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="bidPriceAdjustment" sticky>
                        <td mat-footer-cell class="no-wrap" *matFooterCellDef>{{dealCompany.bidPriceAdjustmentRow.company | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="total" sticky>
                        <td mat-footer-cell class="no-wrap" *matFooterCellDef>{{dealCompany.totalRow.company | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="fundName" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fund Name</th>
                        <td mat-cell *matCellDef="let element" class="no-wrap">{{element.fundName.text}}</td>
                    </ng-container>

                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                        <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
                    </ng-container>

                    <ng-container matColumnDef="gpIndication">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>GP Indication</th>
                        <td mat-cell *matCellDef="let element">{{element.gpIndication.code | codeFormat: codeTableGpIndication | async}}</td>
                    </ng-container>

                    <ng-container matColumnDef="investmentDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Investment Date</th>
                        <td mat-cell *matCellDef="let element">{{element.investmentDate | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="exitDateAssumption">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Exit Date Assumption</th>
                        <td mat-cell *matCellDef="let element">{{element.exitDateAssumption | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="remainingCost">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Remaining Cost</th>
                        <td mat-cell *matCellDef="let element">{{element.remainingCost | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.totalRow.remainingCost | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="gpNAV">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>GP NAV</th>
                        <td mat-cell *matCellDef="let element">{{element.gpNAV | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="netCurrentAssetsGpNAV" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.netCurrentAssetsRow.gpNAV | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="gpCarryReserveGpNAV" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.gpCarryReserveRow.gpNAV | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalGpNAV" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.totalRow.gpNAV | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentageOfTotalNAV">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Percentage Of Total NAV</th>
                        <td mat-cell *matCellDef="let element">{{element.percentageOfTotalNAV | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="selectedBid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Selected Bid</th>
                        <td mat-cell *matCellDef="let element">{{element.selectedBid | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="bidPriceAdjustmentSelectedBid" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.bidPriceAdjustmentRow.selectedBid | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalSelectedBid" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.totalRow.selectedBid | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentageOfTotalBid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Percentage Of Total Bid</th>
                        <td mat-cell *matCellDef="let element">{{element.percentageOfTotalBid | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cashflowLow">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Low Case</th>
                        <td mat-cell *matCellDef="let element">{{element.cashflowLow | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="netCurrentAssetsLow" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.netCurrentAssetsRow.cashflowLow | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalCashflowLow" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.totalRow.cashflowLow | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentageOfTotalCfLow">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Percentage Of Total CF Low</th>
                        <td mat-cell *matCellDef="let element">{{element.percentageOfTotalCfLow | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cashflowBase">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Base Case</th>
                        <td mat-cell *matCellDef="let element">{{element.cashflowBase | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="netCurrentAssetsBase" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.netCurrentAssetsRow.cashflowBase | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalCashflowBase" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.totalRow.cashflowBase | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentageOfTotalCfBase">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Percentage Of Total CF Base</th>
                        <td mat-cell *matCellDef="let element">{{element.percentageOfTotalCfBase | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cashflowHigh">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>High Case</th>
                        <td mat-cell *matCellDef="let element">{{element.cashflowHigh | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="netCurrentAssetsHigh" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.netCurrentAssetsRow.cashflowHigh | traceableFormat}}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalCashflowHigh" sticky>
                        <td mat-footer-cell *matFooterCellDef>{{dealCompany.totalRow.cashflowHigh | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentageOfTotalCfHigh">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Percentage Of Total CF High</th>
                        <td mat-cell *matCellDef="let element">{{element.percentageOfTotalCfHigh | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="empty">
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="dealCompaniesColumns"></tr>
                    <tr mat-row
                        *matRowDef=" let row; columns: dealCompaniesColumns"
                        [routerLink]="'../../assets/' + row.assetId"
                        [queryParams]="{
                    fundId: row.fundId,
                    fundReportId: row.fundReportId,
                    fundValuationId: row.fundValuationId,
                    fundInvestmentId: row.fundInvestmentId,
                    assetValuationId: row.assetValuationId,
                    dealId: (dealId$ | async)}"
                    ></tr>
                    <tr mat-footer-row *matFooterRowDef="dealCompany.netCurrentAssetsColumns" class="row-top-border not-clickable"></tr>
                    <tr mat-footer-row *matFooterRowDef="dealCompany.gpCarryReserveColumns" class="not-clickable"></tr>
                    <tr mat-footer-row *matFooterRowDef="dealCompany.bidPriceAdjustmentColumns" class="not-clickable"></tr>
                    <tr mat-footer-row *matFooterRowDef="dealCompany.totalRowColumns" class="row-important row-top-border not-clickable"></tr>
                </table>
                <mat-paginator
                    #dealCompaniesPaging
                    [pageSizeOptions]="[10, 20, 30]"
                    showFirstLastButtons>
                </mat-paginator>
            </section>
        </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="dealId$ | async as dealId" [expanded]="treemapPanelOpenState" class="mat-expansion-panel">
        <mat-expansion-panel-header class="mat-expansion-panel-header">
            <mat-card-title data-test="title">Companies Treemap</mat-card-title>
        </mat-expansion-panel-header>
        <valumize-deals-companies-treemap [groupedDealCompanies$]="groupedDealCompanies$" [dealId]="dealId"></valumize-deals-companies-treemap>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="bubbleChartPanelOpenState" class="mat-expansion-panel">
        <mat-expansion-panel-header class="mat-expansion-panel-header">
            <mat-card-title data-test="title">Exit Timing by Fund</mat-card-title>
        </mat-expansion-panel-header>
        <valumize-deal-bubble-chart id="deal-bubble-chart"></valumize-deal-bubble-chart>
    </mat-expansion-panel>
</mat-card>
