import {Component, Input} from "@angular/core";
import {EMPTY_TEXT, TraceableText} from "../../model/traceable";
import {FormControl} from "@angular/forms";

@Component({
    selector: "valumize-mergeable-input",
    templateUrl: "./mergeable-input.component.html"
})
export class MergeableInputComponent {

    @Input() label = "";
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() value: TraceableText = EMPTY_TEXT;
    @Input() baselineValue: TraceableText = EMPTY_TEXT;
    @Input() control!: FormControl;

    overwriteValue() {
        if (this.isEditable) {
            this.control.setValue(this.baselineValue.text);
        }
    }

    getChipColor() {
        if (this.control.value === this.baselineValue.text && this.baselineValue.text !== this.value.text) {
            return "chip-overwritten-value";
        } else if (this.baselineValue.text === this.value.text) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }

}
