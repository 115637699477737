import {createFeatureSelector, createSelector} from "@ngrx/store";
import {FundState} from "../fund.state";
import {fundStoreFeatureKey} from "../fund.reducer";

export const selectFundState = createFeatureSelector<FundState>(fundStoreFeatureKey);

export const selectSelectedFundReport = createSelector(
    selectFundState,
    (state) => ({
        fundReport: state.selectedFundReport,
        fundReportDetailsIsEditable: state.fundForms.fundReportDetailsIsEditable
    })
);

export const selectBaselineFundReport = createSelector(
    selectFundState,
    (state) => state.baselineFundReport
);

export const selectHasBaselineFundReport = createSelector(
    selectBaselineFundReport,
    (state) => state.status === "LOADED"
);

export const selectSelectedFundReportsForFund = createSelector(
    selectFundState,
    (state) => state.selectedFundReportsForFund.data
);

export const selectBaselineFundReportsForBaselineFund = createSelector(
    selectFundState,
    (state) => state.baselineFundReportsForBaselineFund
);

export const selectSelectedFundReportReportDate = createSelector(
    selectSelectedFundReport,
    (state) => state.fundReport.data.reportDate
);

export const selectSelectedFundReportId = createSelector(
    selectSelectedFundReport,
    (state) => state.fundReport.data.id
);
