import {ModuleWithProviders, NgModule, Optional, SkipSelf} from "@angular/core";
import {AuthHeaderName} from "../../model/login/auth-header-name";
import {AUTH_CONFIGURATION_TOKEN} from "../../model/login/auth-configuration-token";
import {AuthModuleConfiguration} from "../../model/login/auth-module-configuration";
import {ApiUtil} from "../../utils/api-util";


export const DEFAULT_AUTH_CONFIGURATION: AuthModuleConfiguration = {
    headerName: AuthHeaderName.AUTHORIZATION,
    sendVerificationRequestWithoutAccessToken: false,
    kwAccessTokenUrls: [ApiUtil.toApiPath(""), "/api"]
};

@NgModule()
export class AuthConfigurationModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthConfigurationModule) {
        if (parentModule) {
            throw new Error("AuthConfigurationModule is already loaded. Import it in the AppModule only");
        }
    }

    static forRoot(givenConfiguration: Partial<AuthModuleConfiguration> = {}): ModuleWithProviders<AuthConfigurationModule> {
        const configuration = {...DEFAULT_AUTH_CONFIGURATION, ...givenConfiguration};
        return {
            ngModule: AuthConfigurationModule,
            providers: [
                {provide: AUTH_CONFIGURATION_TOKEN, useValue: configuration}
            ]
        };
    }
}
