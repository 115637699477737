import {Seller} from "../models/seller";
import {EMPTY_CODE, EMPTY_MODEL_DATE, EMPTY_MONEY, EMPTY_MULTIPLE, EMPTY_PERCENT, EMPTY_TEXT} from "../../shared/model/traceable";
import {Deal} from "../models/deal";
import {DealState} from "./deal.state";
import {createReducer, on} from "@ngrx/store";
import {
    DealActions,
    DealAggregatedCashflowAction,
    DealCompanyActions,
    DealDetailsAction,
    DealFundActions,
    DealListActions,
    DealNameActions,
    DealReturnSummaryActions,
    DealScenarioActions,
    SellerActions,
    SellerListActions,
    SellerPositionActions
} from "./deal.actions";
import {SellerPosition} from "../models/seller-position";
import {DealFund} from "../models/deal-fund";
import {SellerPositionCalc} from "../models/seller-position-calc";
import {AggregatedDealCashflows, DealScenarioCashFlows} from "../models/aggregated-deal-cashflows";
import {DealCashflowWeightedCase} from "../models/deal-cashflow-weighted-case";
import {DealReturnSummary} from "../models/deal-return-summary";
import {DealFundRecord} from "../models/deal-fund-record";
import {DealCompany} from "../models/deal-company";
import {DealCompanyRecord} from "../models/deal-company-record";

export const dealStoreFeatureKey = "valumize/deal";

export const EMPTY_SELLER: Seller = {
    id: undefined,
    name: EMPTY_TEXT
};

export const EMPTY_DEAL: Deal = {
    id: undefined,
    reportDate: EMPTY_MODEL_DATE,
    projectName: EMPTY_TEXT,
    stage: EMPTY_CODE,
    regions: [],
    currencyIso: EMPTY_CODE,
    closingDate: EMPTY_MODEL_DATE,
    managementFee: EMPTY_PERCENT,
    managementFeeCalculationMethod: EMPTY_CODE,
    carry: EMPTY_PERCENT,
    hurdleRate: EMPTY_PERCENT,
    secondaryManagementFee: EMPTY_PERCENT,
    secondaryCarry: EMPTY_PERCENT,
    secondaryHurdle: EMPTY_PERCENT,
    bidPriceAdjustment: EMPTY_MONEY,
    source: EMPTY_TEXT,
    processType: EMPTY_CODE,
    dealCaptain: EMPTY_TEXT,
};

export const EMPTY_SELLER_POSITION: SellerPosition = {
    id: undefined,
    fundValuationId: -1,
    sellerStake: EMPTY_PERCENT,
    sellerCommitment: EMPTY_MONEY,
    buyerStake: EMPTY_PERCENT,
    buyerCommitment: EMPTY_MONEY,
    finalBidPrice: EMPTY_MONEY,
    drawnCommitments: EMPTY_MONEY,
    distributions: EMPTY_MONEY,
    gpValuation: EMPTY_MONEY,
    recallableDistribution: EMPTY_MONEY,
    fxRateDeal: EMPTY_PERCENT,
    unfundedCommitments: EMPTY_MONEY,
    expiredReleasedCommitments: EMPTY_MONEY,
    accountBalanceBeforeCarry: EMPTY_MONEY,
    realizedCarriedInterest: EMPTY_MONEY,
    totalCarriedInterest: EMPTY_MONEY
};

export const EMPTY_SELLER_POSITION_CALC: SellerPositionCalc = {
    adjustedCommitment: EMPTY_MONEY,
    finalAdjustedCommitment: EMPTY_MONEY,
    totalUndrawn: EMPTY_MONEY,
    grossBidPrice: EMPTY_MONEY,
    netBidPrice: EMPTY_MONEY,
    percentageOfFundHeld: EMPTY_PERCENT,
    drawdown: EMPTY_PERCENT,
    dpi: EMPTY_PERCENT,
    tvpi: EMPTY_MULTIPLE,
    sellerMultipleTVPI: EMPTY_MULTIPLE,
    sellerPosition: EMPTY_SELLER_POSITION
};

export const EMPTY_DEAL_FUND: DealFund = {
    dealFundRecords: [],
    totalFundSize: EMPTY_MONEY,
    totalSellerCommitment: EMPTY_MONEY,
    totalSellerCommitmentDealCurrency: EMPTY_MONEY,
    totalBuyerCommitment: EMPTY_MONEY,
    totalDiscountToNav: EMPTY_PERCENT,
    totalBidPriceBuyerStake: EMPTY_MONEY,
    totalNavBuyerStake: EMPTY_MONEY,
    totalUnfundedBuyerStake: EMPTY_MONEY,
    totalAdjustedCommitment: EMPTY_MONEY,
};

export const EMPTY_DEAL_FUND_RECORD: DealFundRecord = {
    fundId: -1,
    fundReportId: -1,
    fundValuationId: -1,
    fundName: EMPTY_TEXT,
    fundSize: EMPTY_MONEY,
    fundCurrency: EMPTY_CODE,
    exchangeRate: EMPTY_PERCENT,
    fundVintageYear: EMPTY_MODEL_DATE,
    fundEndYear: EMPTY_MODEL_DATE,
    adjustedCommitment: EMPTY_MONEY,
    reportDate: EMPTY_MODEL_DATE,
    closingDate: EMPTY_MODEL_DATE,
    sellerPosition: EMPTY_SELLER_POSITION,
    sellerCommitmentDealCurrency: EMPTY_MONEY,
    discountToNav: EMPTY_PERCENT,
    bidPriceBuyerStake: EMPTY_MONEY,
    navBuyerStake: EMPTY_MONEY,
    unfundedBuyerStake: EMPTY_MONEY,
};

export const EMPTY_DEAL_COMPANY: DealCompany = {
    totalRemainingCost: EMPTY_MONEY,
    totalGpNAV: EMPTY_MONEY,
    totalBid: EMPTY_MONEY,
    totalExitCashflowsLow: EMPTY_MONEY,
    totalExitCashflowsBase: EMPTY_MONEY,
    totalExitCashflowsHigh: EMPTY_MONEY,
    totalBidPriceAdjustments: EMPTY_MONEY,
    totalGpCarryReserve: EMPTY_MONEY,
    totalNetCurrentAssets: EMPTY_MONEY,
    dealCompanyRecords: []
};

export const EMPTY_DEAL_COMPANY_RECORD: DealCompanyRecord = {
    fundId: -1,
    fundReportId: -1,
    fundValuationId: -1,
    assetId: -1,
    fundInvestmentId: -1,
    assetValuationId: -1,
    fundName: EMPTY_TEXT,
    company: EMPTY_TEXT,
    country: EMPTY_CODE,
    gpIndication: EMPTY_CODE,
    investmentDate: EMPTY_MODEL_DATE,
    exitDateAssumption: EMPTY_MODEL_DATE,
    remainingCost: EMPTY_MONEY,
    gpNAV: EMPTY_MONEY,
    selectedBid: EMPTY_MONEY,
    cashflowLow: EMPTY_MONEY,
    cashflowBase: EMPTY_MONEY,
    cashflowHigh: EMPTY_MONEY,
    percentageOfTotalNAV: EMPTY_PERCENT,
    percentageOfTotalBid: EMPTY_PERCENT,
    percentageOfTotalCfLow: EMPTY_PERCENT,
    percentageOfTotalCfBase: EMPTY_PERCENT,
    percentageOfTotalCfHigh: EMPTY_PERCENT,
    navMultipleLow: EMPTY_MULTIPLE,
    navMultipleBase: EMPTY_MULTIPLE,
    navMultipleHigh: EMPTY_MULTIPLE
};

export const EMPTY_AGGREGATED_DEAL_CASHFLOWS: AggregatedDealCashflows = {
    totalAssetDistribution: undefined,
    totalAssetDrawdown: undefined,
    totalGpManagementFee: undefined,
    totalGpUnfundedPayment: undefined,
    totalGpUnfundedDistribution: undefined,
    totalGpUnfundedNet: undefined,
    totalPurchasePrice: undefined,
    totalGpCarryNet: undefined,
    totalGpGross: undefined,
    totalSecondaryCashflow: undefined,
    totalSecondaryManagementFee: undefined,
    totalSecondaryCarryPayment: undefined,
    totalSecondaryNetCashflow: undefined,
    records: []
};

export const EMPTY_DEAL_CASHFLOW_WEIGHTED_CASE: DealCashflowWeightedCase = {
    probability: EMPTY_PERCENT,
    netPerformanceTvpi: EMPTY_MULTIPLE,
    netPerformanceIrr: EMPTY_PERCENT,
    grossPerformanceTvpi: EMPTY_MULTIPLE,
    grossPerformanceIrr: EMPTY_PERCENT,
    navUplift: EMPTY_PERCENT,
};

export const EMPTY_DEAL_RETURN_SUMMARY: DealReturnSummary = {
    dealCashflowWeightedCase: EMPTY_DEAL_CASHFLOW_WEIGHTED_CASE,
    scenarios: []
};

export const EMPTY_DEAL_CASHFLOWS: DealScenarioCashFlows[] = [
    {
        scenarioType: "LOW",
        aggregatedDealCashFlows: {
            data: EMPTY_AGGREGATED_DEAL_CASHFLOWS,
            status: "INIT",
            errorMessage: ""
        }
    },
    {
        scenarioType: "BASE",
        aggregatedDealCashFlows: {
            data: EMPTY_AGGREGATED_DEAL_CASHFLOWS,
            status: "INIT",
            errorMessage: ""
        }
    },
    {
        scenarioType: "HIGH",
        aggregatedDealCashFlows: {
            data: EMPTY_AGGREGATED_DEAL_CASHFLOWS,
            status: "INIT",
            errorMessage: ""
        }
    }
];

export const INITIAL_STATE: DealState = {
    sellers: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedSeller: {
        data: EMPTY_SELLER,
        status: "INIT",
        errorMessage: ""
    },
    deals: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedDeal: {
        data: EMPTY_DEAL,
        status: "INIT",
        errorMessage: ""
    },
    selectedSellerPositionsForDeal: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedSellerPosition: {
        data: EMPTY_SELLER_POSITION,
        status: "INIT",
        errorMessage: ""
    },
    selectedSellerPositionCalc: {
        data: EMPTY_SELLER_POSITION_CALC,
        status: "INIT",
        errorMessage: ""
    },
    dealFunds: {
        data: EMPTY_DEAL_FUND,
        status: "INIT",
        errorMessage: ""
    },
    dealCompanies: {
        data: EMPTY_DEAL_COMPANY,
        status: "INIT",
        errorMessage: ""
    },
    selectedDealScenarios: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedDealCashFlows: EMPTY_DEAL_CASHFLOWS,
    dealForms: {
        dealNameIsEditable: false,
        dealFundsIsEditable: false,
        dealDetailsIsEditable: false
    },
    returnSummary: {
        data: EMPTY_DEAL_RETURN_SUMMARY,
        status: "INIT",
        errorMessage: ""
    }
};

export const dealReducer = createReducer(
    INITIAL_STATE,
    on(DealActions.cleardealstate, (): DealState => INITIAL_STATE),

    // Deal List
    on(DealListActions.load, (state): DealState => ({
        ...state,
        deals: {
            ...state.deals,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealListActions.loaded, (state, action): DealState => ({
        ...state,
        deals: {
            data: action.deals,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DealListActions.loaderror, (state, action): DealState => ({
        ...state,
        deals: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Deal
    on(DealActions.create, (state): DealState => ({
        ...state,
        selectedDeal: {
            ...INITIAL_STATE.selectedDeal,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealActions.load, (state): DealState => ({
        ...state,
        selectedDeal: {
            ...state.selectedDeal,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealActions.loaded, (state, action): DealState => ({
        ...state,
        selectedDeal: {
            data: action.deal,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DealActions.loaderror, (state, action): DealState => ({
        ...state,
        selectedDeal: {
            data: EMPTY_DEAL,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Deal Name
    on(DealNameActions.edit, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealNameIsEditable: true
        }
    })),
    on(DealNameActions.save, (state): DealState => ({
        ...state,
        selectedDeal: {
            ...state.selectedDeal,
            status: "LOADING"
        },
        dealForms: {
            ...state.dealForms,
            dealNameIsEditable: false
        }
    })),
    on(DealNameActions.cancel, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealNameIsEditable: false
        }
    })),

    // Seller List
    on(SellerListActions.load, (state): DealState => ({
        ...state,
        sellers: {
            ...state.sellers,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerListActions.loaded, (state, action): DealState => ({
        ...state,
        sellers: {
            data: action.sellers,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(SellerListActions.loaderror, (state, action): DealState => ({
        ...state,
        sellers: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Seller
    on(SellerActions.create, (state): DealState => ({
        ...state,
        selectedSeller: {
            ...INITIAL_STATE.selectedSeller,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerActions.load, (state): DealState => ({
        ...state,
        selectedSeller: {
            ...state.selectedSeller,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerActions.loaded, (state, action): DealState => ({
        ...state,
        selectedSeller: {
            data: action.seller,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(SellerActions.loaderror, (state, action): DealState => ({
        ...state,
        selectedSeller: {
            data: EMPTY_SELLER,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Seller Position
    on(SellerPositionActions.save, (state): DealState => ({
        ...state,
        selectedSellerPosition: {
            ...state.selectedSellerPosition,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerPositionActions.savedloaded, (state, action): DealState => ({
        ...state,
        selectedSellerPosition: {
            data: action.sellerPosition,
            status: "SAVED",
            errorMessage: ""
        }
    })),
    on(SellerPositionActions.load, (state): DealState => ({
        ...state,
        selectedSellerPosition: {
            ...state.selectedSellerPosition,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerPositionActions.loaded, (state, action): DealState => ({
        ...state,
        selectedSellerPosition: {
            data: action.sellerPosition,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(SellerPositionActions.saveall, (state): DealState => ({
        ...state,
        selectedSellerPositionsForDeal: {
            ...state.selectedSellerPositionsForDeal,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerPositionActions.loadall, (state): DealState => ({
        ...state,
        selectedSellerPositionsForDeal: {
            ...state.selectedSellerPositionsForDeal,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerPositionActions.loadedall, (state, action): DealState => ({
        ...state,
        selectedSellerPositionsForDeal: {
            data: action.sellerPositions,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(SellerPositionActions.loaderror, (state, action): DealState => ({
        ...state,
        selectedSellerPositionsForDeal: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(SellerPositionActions.calc, (state): DealState => ({
        ...state,
        selectedSellerPositionCalc: {
            ...state.selectedSellerPositionCalc,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(SellerPositionActions.calcsuccess, (state, action): DealState => ({
        ...state,
        selectedSellerPositionCalc: {
            data: action.sellerPositionCalc,
            status: "LOADED",
            errorMessage: ""
        },
        selectedSellerPosition: {
            data: action.sellerPositionCalc.sellerPosition,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(SellerPositionActions.calcerror, (state, action): DealState => ({
        ...state,
        selectedSellerPositionCalc: {
            data: EMPTY_SELLER_POSITION_CALC,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Deal Funds
    on(DealFundActions.load, (state): DealState => ({
        ...state,
        dealFunds: {
            ...state.dealFunds,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealFundActions.loaded, (state, action): DealState => ({
        ...state,
        dealFunds: {
            data: action.dealFund,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DealFundActions.loaderror, (state, action): DealState => ({
        ...state,
        dealFunds: {
            data: EMPTY_DEAL_FUND,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(DealFundActions.save, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealFundsIsEditable: false
        }
    })),
    on(DealFundActions.edit, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealFundsIsEditable: true
        }
    })),
    on(DealFundActions.cancel, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealFundsIsEditable: false
        }
    })),

    // Deal Companies
    on(DealCompanyActions.load, (state): DealState => ({
        ...state,
        dealCompanies: {
            ...state.dealCompanies,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealCompanyActions.loaded, (state, action): DealState => ({
        ...state,
        dealCompanies: {
            data: action.dealCompany,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DealCompanyActions.loaderror, (state, action): DealState => ({
        ...state,
        dealCompanies: {
            data: EMPTY_DEAL_COMPANY,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Deal Scenario
    on(DealScenarioActions.loadall, (state): DealState => ({
        ...state,
        selectedDealScenarios: {
            ...state.selectedDealScenarios,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealScenarioActions.loadedall, (state, action): DealState => ({
        ...state,
        selectedDealScenarios: {
            data: action.scenarios,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DealScenarioActions.loaderror, (state, action): DealState => ({
        ...state,
        selectedDealScenarios: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Deal Aggregated Cashflows
    on(DealAggregatedCashflowAction.load, (state, action): DealState => ({
        ...state,
        selectedDealCashFlows:
            state.selectedDealCashFlows.map(scenarioCashFlows =>
                scenarioCashFlows.scenarioType === action.scenarioType ? {
                    scenarioType: scenarioCashFlows.scenarioType,
                    aggregatedDealCashFlows: {
                        data: EMPTY_AGGREGATED_DEAL_CASHFLOWS,
                        status: "LOADING",
                        errorMessage: ""
                    }
                } : scenarioCashFlows)
    })),
    on(DealAggregatedCashflowAction.loaded, (state, action): DealState => ({
        ...state,
        selectedDealCashFlows:
            state.selectedDealCashFlows.map(scenarioCashFlows =>
                scenarioCashFlows.scenarioType === action.scenarioType ? {
                    scenarioType: scenarioCashFlows.scenarioType,
                    aggregatedDealCashFlows: {
                        data: action.aggregatedCashFlows,
                        status: "LOADED",
                        errorMessage: ""
                    }
                } : scenarioCashFlows)
    })),
    on(DealAggregatedCashflowAction.loaderror, (state, action): DealState => ({
        ...state,
        selectedDealCashFlows:
            state.selectedDealCashFlows.map(scenarioCashFlows =>
                scenarioCashFlows.scenarioType === action.scenarioType ? {
                    scenarioType: action.scenarioType,
                    aggregatedDealCashFlows: {
                        data: EMPTY_AGGREGATED_DEAL_CASHFLOWS,
                        status: "ERROR",
                        errorMessage: action.errorMsg
                    }
                } : scenarioCashFlows)
    })),

    // Deal Details
    on(DealDetailsAction.edit, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealDetailsIsEditable: true
        }
    })),
    on(DealDetailsAction.save, (state): DealState => ({
        ...state,
        selectedDeal: {
            ...state.selectedDeal,
            status: "LOADING"
        },
        dealForms: {
            ...state.dealForms,
            dealDetailsIsEditable: false
        }
    })),
    on(DealDetailsAction.cancel, (state): DealState => ({
        ...state,
        dealForms: {
            ...state.dealForms,
            dealDetailsIsEditable: false
        }
    })),

    // Return Summary
    on(DealReturnSummaryActions.calc, (state): DealState => ({
        ...state,
        returnSummary: {
            ...state.returnSummary,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(DealReturnSummaryActions.calcsuccess, (state, action): DealState => ({
        ...state,
        returnSummary: {
            data: action.returnSummary,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(DealReturnSummaryActions.calcerror, (state, action): DealState => ({
        ...state,
        returnSummary: {
            data: EMPTY_DEAL_RETURN_SUMMARY,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    }))
);
