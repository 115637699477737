import {Inject, Injectable, Optional} from "@angular/core";
import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map, switchMap, take} from "rxjs/operators";
import {LoginService} from "./login.service";
import {LocalStorageAccessTokenService} from "./local-storage-access-token.service";
import {DEFAULT_AUTH_CONFIGURATION} from "./auth-configuration.module";
import {AuthHeaderName} from "../../model/login/auth-header-name";
import {AUTH_CONFIGURATION_TOKEN} from "../../model/login/auth-configuration-token";
import {AuthModuleConfiguration} from "../../model/login/auth-module-configuration";
import {ApiUtil} from "../../utils/api-util";
import {ACCESS_TOKEN_IN_RESPONSE_HEADER_NAME} from "../../model/login/auth.constants";

/**
 * Http Context token for controlling the automatic handling of HTTP status 403 "Forbidden" API responses, which automatically shows the modal login screen.
 * This is enabled by default, use 'false' for disabling the handler - which also means the caller must handle any HTTP status 403 responses itself.
 */
export const KW_FORBIDDEN_HANDLING_ENABLED = new HttpContextToken<boolean>(() => true);

/**
 * Http Context token for controlling the automatic handling of HTTP status 401 "Unauthorized" API responses, which automatically shows the modal login screen.
 * This is enabled by default, use 'false' for disabling the handler - which also means the caller must handle any HTTP status 401 responses itself.
 */
export const KW_UNAUTHORIZED_HANDLING_ENABLED = new HttpContextToken<boolean>(() => true);


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private readonly headerName: AuthHeaderName;
    private readonly kwAccessTokenUrls: string[];

    constructor(private readonly loginService: LoginService,
                private readonly accessTokenService: LocalStorageAccessTokenService,
                @Optional() @Inject(AUTH_CONFIGURATION_TOKEN) readonly authModuleConfiguration: AuthModuleConfiguration) {
        const config = {...DEFAULT_AUTH_CONFIGURATION, ...authModuleConfiguration};
        this.headerName = config.headerName;
        this.kwAccessTokenUrls = config.kwAccessTokenUrls;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        request = this.addBearerToken(request);

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.extractAccessToken(event);
                }
                return event;
            }),
            catchError(err => {
                if (err.status === 401) {
                    this.accessTokenService.clearAccessToken();
                }
                if (!this.isAuthenticationRequest(request) && this.mustShowLoginForError(err, request)) {
                    return this.loginService.showLoginModal().pipe(
                        take(1),
                        switchMap(() => {
                            request = this.addBearerToken(request);
                            return next.handle(request);
                        })
                    );
                }
                return throwError(() => err);
            }));
    }

    private mustShowLoginForError(err: HttpErrorResponse, request: HttpRequest<any>) {
        return (err.status === 401 && request.context.get(KW_UNAUTHORIZED_HANDLING_ENABLED))
            || ((err.status === 403) && request.context.get(KW_FORBIDDEN_HANDLING_ENABLED));
    }

    private isAuthenticationRequest(request: HttpRequest<any>) {
        return request.url === ApiUtil.toApiPath("authentication");
    }

    private addBearerToken<T>(request: HttpRequest<T>): HttpRequest<T> {
        if (this.kwAccessTokenUrls.some(kwAccessTokenUrl => request.url.startsWith(kwAccessTokenUrl))) {
            const accessToken = this.accessTokenService.getAccessToken();
            if (!!accessToken) {
                request = request.clone({
                    setHeaders: {
                        [this.headerName]: `Bearer ${accessToken}`
                    }
                });
            }
        }
        return request;
    }

    private extractAccessToken<T>(response: HttpResponse<T>): void {
        if (response.headers.has(ACCESS_TOKEN_IN_RESPONSE_HEADER_NAME)) {
            this.accessTokenService.storeAccessToken(response.headers.get(ACCESS_TOKEN_IN_RESPONSE_HEADER_NAME) as string);
        }
    }
}
