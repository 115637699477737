<mat-dialog-content *ngIf="!deletePeriod">
    <h2 mat-card-title>Select Period</h2>
    <div>
        <label>
            <input [formControl]="periodType" [value]="'QUARTER'" type="radio"> Quarter
        </label>
        <label>
            <input [formControl]="periodType" [value]="'SEMESTER'" type="radio"> Semester
        </label>
        <label>
            <input [formControl]="periodType" [value]="'FULL_FISCAL_YEAR'" type="radio"> Full Fiscal Year
        </label>
    </div>

    <div>
        <mat-form-field>
            <mat-label>Date</mat-label>
            <mat-select [formControl]="periodKeyDate">
                <div *ngIf="periodType.getRawValue() === PERIOD_TYPE.Quarter">
                    <mat-option *ngFor="let dates of quarterEndDates" [value]="dates">{{ dates | date }}</mat-option>
                </div>
                <div *ngIf="periodType.getRawValue() === PERIOD_TYPE.Semester">
                    <mat-option *ngFor="let dates of semesterEndDates" [value]="dates">{{ dates  | date }}</mat-option>
                </div>
                <div *ngIf="periodType.getRawValue() === PERIOD_TYPE.FullFiscalYear">
                    <mat-option *ngFor="let dates of fullFiscalYearEndDates" [value]="dates">{{ dates  | date }}</mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!deletePeriod" align="end" mat-dialog-actions>
    <button (click)="onDelete()" *ngIf="action === ACTION_TYPE.Edit" mat-button>Delete</button>
    <button (click)="onCancel()" mat-button>Cancel</button>
    <button (click)="onSave()" [disabled]="originPeriodType === periodType.getRawValue() && originPeriodKeyDate === periodKeyDate.getRawValue()"
            color="primary"
            mat-button>Save
    </button>
</mat-dialog-actions>

<mat-dialog-content *ngIf="deletePeriod">
    <h2 mat-card-title>Delete Period</h2>
    <p>Are you sure you want to delete this period?</p>
    <p>{{originPeriodType}}: {{originPeriodKeyDate}}</p>
</mat-dialog-content>
<mat-dialog-actions *ngIf="deletePeriod" align="end" mat-dialog-actions>
    <button (click)="onDeleteConfirm()" mat-button>Yes</button>
    <button (click)="onDeleteCancel()" color="primary" mat-button>No</button>
</mat-dialog-actions>
