import {Injectable} from "@angular/core";
import {DealApiService} from "../generated-sources/api";
import {SellerPosition} from "../models/seller-position";
import {map, Observable} from "rxjs";
import {SellerPositionCalc} from "../models/seller-position-calc";

@Injectable({
    providedIn: "root"
})
export class SellerPositionService {

    constructor(private readonly dealApiService: DealApiService) {
    }

    public getSellerPositionsForDealAndFundValuation(dealId: number, fundValuationId?: number) {
        return this.dealApiService.getSellerPositionsForDealAndFundValuation(dealId, fundValuationId);
    }

    public getSellerPosition(dealId: number, sellerPositionId: number, fundValuationId: number) {
        return this.dealApiService.getSellerPosition(dealId, sellerPositionId, fundValuationId);
    }

    public save(dealId: number, fundValuationId: number, sellerPosition: SellerPosition): Observable<SellerPosition> {
        const result: Observable<SellerPosition> = !!dealId && !!fundValuationId && !!sellerPosition.id
            ? this.dealApiService.updateSellerPosition(dealId, sellerPosition.id, fundValuationId, sellerPosition)
            : this.dealApiService.createSellerPosition(dealId, fundValuationId, sellerPosition);
        return result.pipe(map((sellerPositionDto: SellerPosition) => sellerPositionDto));
    }

    public updateSellerPositions(dealId: number, sellerPositions: SellerPosition[]): Observable<SellerPosition[]> {
        return this.dealApiService.updateSellerPositions(dealId, sellerPositions);
    }

    public calculateSellerPosition(dealId: number, fundValuationId: number, sellerPositionId: number, sellerPosition: SellerPosition): Observable<SellerPositionCalc> {
        return this.dealApiService.calculateSellerPosition(dealId, sellerPositionId, fundValuationId, sellerPosition);
    }
}
