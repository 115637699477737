import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";

@Component({
    selector: "valumize-mergeable-checkbox",
    templateUrl: "./mergeable-checkbox.component.html"
})
export class MergeableCheckboxComponent {

    @Input() label = "";
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() value = false;
    @Input() baselineValue = false;
    @Input() control!: FormControl;

    overwriteValue() {
        if (this.isEditable) {
            this.control.setValue(this.baselineValue);
        }
    }

    getChipColor() {
        if (this.control.value === this.baselineValue && this.baselineValue !== this.value) {
            return "chip-overwritten-value";
        } else if (this.baselineValue === this.value) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }
}
