import {Injectable} from "@angular/core";
import {SearchQuery, SearchResult} from "../models/search";
import {Observable} from "rxjs";
import {SearchApiService} from "../generated-sources/api";


@Injectable({
    providedIn: "root"
})
export class SearchService {

    constructor(private readonly searchApiService: SearchApiService) { }

    search(searchQuery: SearchQuery): Observable<SearchResult[]> {
        return this.searchApiService.search(searchQuery);
    }
}
