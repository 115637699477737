<h2 mat-dialog-title>Select valuation</h2>
<mat-dialog-content>
    <ng-container *ngIf="assetValuationsForAsset$ | async as assetValuations">
        <ng-container *ngIf="filterByReportDate(assetValuations) as assetValuationsByReportDate">
            <ng-container *ngIf="assetValuations.length === 0">
                <p>No asset valuations exist for this asset</p>
            </ng-container>
            <table mat-table [dataSource]="assetValuationsByReportDate">
                <ng-container matColumnDef="id" sticky>
                    <th mat-header-cell *matHeaderCellDef>Asset Valuation</th>
                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                </ng-container>

                <ng-container matColumnDef="reportDate" sticky>
                    <th mat-header-cell *matHeaderCellDef>Report Date</th>
                    <td mat-cell *matCellDef="let element">{{element.reportDate | traceableFormat}}</td>
                </ng-container>

                <ng-container matColumnDef="bidPrice" sticky>
                    <th mat-header-cell *matHeaderCellDef>Final Bid Price</th>
                    <td mat-cell *matCellDef="let element">{{element.finalBidPrice | traceableFormat}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>

                <tr mat-row
                    *matRowDef="let row; columns: columns"
                    [routerLink]="'../../assets/' + assetId"
                    [queryParams]="{
                    fundId: (fundId$ | async),
                    fundReportId: (fundReportId$ | async),
                    fundValuationId: (fundValuationId$ | async),
                    fundInvestmentId: (fundInvestmentId$ | async),
                    assetValuationId: row.id}"
                    mat-dialog-close></tr>
            </table>
        </ng-container>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-stroked-button mat-dialog-close
        data-test="cancel-button">
        Cancel
    </button>
</mat-dialog-actions>
