export interface Code {
    code: string;
    table: string;
    active: boolean;
    id: number;
    translations: {
        de: string;
        en: string;
    };
    parameters: any;
}

export enum CodeTableEnum {
    ASSET_CASHFLOWTYPE = "valumize.asset.CashflowType",
    ASSET_ESG = "valumize.asset.ESG",
    ASSET_GPINDICATION = "valumize.asset.GpIndication",
    FUND_FEECALCULATIONMETHOD = "valumize.fund.FeeCalculationMethod",
    FUND_GENERALPARTNERQUALITY = "valumize.fund.GeneralPartnerQuality",
    SHARED_ASSESSMENT = "valumize.shared.Assessment",
    SHARED_COUNTRY = "valumize.shared.countryCode",
    SHARED_CURRENCY = "valumize.shared.Currency",
    SHARED_FISCALYEAREND = "valumize.shared.FiscalYearEnd",
    SHARED_REGIONS = "valumize.shared.Region",
    SHARED_SCENARIOTYPE = "valumize.shared.ScenarioType",
    SHARED_STAGES = "valumize.shared.Stage",
    PROCESS_TYPE = "valumize.deal.ProcessType",
    ACCELEX_TASK_INFO_STATUS = "valumize.accelex.taskInfoStatus"
}

export interface CodeDefinition {
    _embedded: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "ads:codes": adsCode[];
    };
}

export interface adsCode {
    creationDate: string;
    modificationDate: string;
    deletionDate: string;
    createdBy: number;
    modifiedBy: number;
    deletedBy: number;
    code: string;
    table: string;
    description: string;
    parameters: any;
    locked: boolean;
    active: boolean;
    id: number;
    translations: {
        de: string;
        en: string;
    };
    _links: {
        self: {
            href: string;
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "ads:code": {
            href: string;
        };
    };
}
