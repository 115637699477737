<h2 mat-dialog-title>Import Dataset</h2>
<mat-dialog-content>
    <h3>Selected files</h3>
    <ng-container *ngIf="selectedFiles.length !== 0; else selectFilesToDisplay">
        <table mat-table [dataSource]="selectedFiles">
            <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef>File</th>
                <td mat-cell *matCellDef="let element">{{element}}</td>
            </ng-container>

            <ng-container matColumnDef="remove">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button
                        mat-icon-button
                        color="primary"
                        data-test="remove-file"
                        (click)="removeFileFromSelection(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="selectedFilesColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedFilesColumns"></tr>
        </table>
    </ng-container>
    <ng-template #selectFilesToDisplay style="color: grey">
        <div>Select files to import first!</div>
    </ng-template>

    <h3 style="margin-top: 1rem">Select files to import</h3>
    <ng-container *ngIf="(fileNames$ | async)?.status === 'LOADING'; else displayFilesToImport">
        <mat-spinner style="margin-top: 1rem" diameter="24"></mat-spinner>
    </ng-container>
    <ng-template #displayFilesToImport>
        <ng-container *ngIf="filterNotSelected((fileNames$ | async)?.data || []).length !== 0; else noFilesToImport">
            <table mat-table [dataSource]="filterNotSelected((fileNames$ | async)?.data || [])">
                <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef>File</th>
                    <td mat-cell *matCellDef="let element">{{element}}</td>
                </ng-container>

                <ng-container matColumnDef="add" sticky>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            mat-icon-button
                            color="primary"
                            data-test="add-file"
                            (click)="addFileToSelection(element)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="selectableFilesColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: selectableFilesColumns"></tr>
            </table>
        </ng-container>
    </ng-template>
    <ng-template #noFilesToImport style="color: grey">
        <div>No files found for import!</div>
    </ng-template>

    <ng-container *ngIf="!selectedDeal; else displayDeal">
        <mat-expansion-panel *ngIf="deals$ | async as deals" [expanded]="dealsTablePanelOpenState" class="mat-expansion-panel">
            <mat-expansion-panel-header class="mat-expansion-panel-header">
                <mat-card-title data-test="title">
                    <h3>Select associated Deal</h3>
                </mat-card-title>
            </mat-expansion-panel-header>
            <div class="warning-container">
                <mat-icon class="warning-icon">warning</mat-icon>
                <p>Select a deal if the file to be imported<br>contains deal-dependent data (e.g. seller position)</p>
            </div>
            <table mat-table [dataSource]="deals">
                <ng-container matColumnDef="id" sticky>
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Deal</th>
                    <td mat-cell *matCellDef="let element">{{element.projectName | traceableFormat}}</td>
                </ng-container>

                <ng-container matColumnDef="closingDate">
                    <th mat-header-cell *matHeaderCellDef>Closing Date</th>
                    <td mat-cell *matCellDef="let element">{{element.closingDate | traceableFormat}}</td>
                </ng-container>

                <ng-container matColumnDef="add" sticky>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            mat-icon-button
                            color="primary"
                            data-test="select-deal"
                            (click)="selectDeal(element)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="selectableDealsColumns"></tr>
                <tr mat-row *matRowDef=" let row; columns: selectableDealsColumns"></tr>
            </table>
        </mat-expansion-panel>
    </ng-container>
    <ng-template #displayDeal>
        <div style="display: flex">
            <h3>Selected Deal: {{selectedDeal?.projectName | traceableFormat}}</h3>
            <button
                mat-icon-button
                color="primary"
                data-test="edit-selected deal"
                (click)="removeSelectedDeal()">
                <mat-icon>manage_history</mat-icon>
            </button>
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close data-test="import-button" (click)="importFiles()">Import</button>
    <button mat-stroked-button mat-dialog-close data-test="cancel-button">Cancel</button>
</mat-dialog-actions>
