/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {catchError, exhaustMap, map, of, switchMap} from "rxjs";
import {SellerPositionService} from "../../services/seller-position.service";
import {DealFundActions, SellerPositionActions} from "../deal.actions";
import {SellerPosition} from "../../models/seller-position";

export const loadSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loadall),
            exhaustMap((action) =>
                sellerPositionService.getSellerPositionsForDealAndFundValuation(action.dealId).pipe(
                    map((sellerPositions) => SellerPositionActions.loadedall({sellerPositions})),
                    catchError((error: { message: string }) =>
                        of(SellerPositionActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const saveSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.saveall),
            exhaustMap((action) => {
                if (!!action.dealId) {
                    return sellerPositionService.updateSellerPositions(
                        action.dealId,
                        action.sellerPositions
                    ).pipe(
                        switchMap((sellerPositions) => of(
                            SellerPositionActions.loadedall({sellerPositions}),
                            DealFundActions.load({dealId: action.dealId})
                        )),
                        catchError((error: { message: string }) =>
                            of(SellerPositionActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Deal id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.load),
            exhaustMap((action) => {
                return sellerPositionService.getSellerPositionsForDealAndFundValuation(action.dealId, action.fundValuationId).pipe(
                    switchMap((sellerPositions) => of(
                        sellerPositions.length !== 0
                            ? SellerPositionActions.loaded({sellerPosition: sellerPositions.at(0) as SellerPosition})
                            : SellerPositionActions.loaderror({
                                errorMsg: `Seller position does not exist for deal with id = ${action.dealId} and fund valuation with id = ${action.fundValuationId}`
                            }),
                        sellerPositions.length !== 0
                            ? SellerPositionActions.calc({dealId: action.dealId, fundValuationId: action.fundValuationId, sellerPosition: sellerPositions.at(0) as SellerPosition})
                            : SellerPositionActions.calcerror({
                                errorMsg: `Seller position does not exist for deal with id = ${action.dealId} and fund valuation with id = ${action.fundValuationId}`
                            })
                    )),
                    catchError((error: { message: string }) =>
                        of(SellerPositionActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.save),
            exhaustMap((action) => {
                if (!!action.dealId && !!action.fundValuationId) {
                    return sellerPositionService.save(
                        action.dealId,
                        action.fundValuationId,
                        action.sellerPosition
                    ).pipe(
                        switchMap((sellerPosition) => of(
                            SellerPositionActions.savedloaded({sellerPosition}),
                            SellerPositionActions.calc({dealId: action.dealId, fundValuationId: action.fundValuationId, sellerPosition})
                        )),
                        catchError((error: { message: string }) =>
                            of(SellerPositionActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Deal Id and Fund Valuation Id are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.calc),
            exhaustMap((action) => {
                if (!!action.dealId && !!action.fundValuationId && !!action.sellerPosition.id) {
                    return sellerPositionService.calculateSellerPosition(
                        action.dealId,
                        action.fundValuationId,
                        action.sellerPosition.id,
                        action.sellerPosition
                    ).pipe(
                        map((sellerPositionCalc) => SellerPositionActions.calcsuccess({sellerPositionCalc})),
                        catchError((error: { message: string }) =>
                            of(SellerPositionActions.calcerror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Deal Id, Fund Valuation Id and Seller Position Id are required"}));
                }
            })
        );
    },
    {functional: true}
);
