import {Component, Input} from "@angular/core";
import {CodeTableEnum} from "../../../../shared/model/code";
import {CurrentOrRealizedInvestmentRecord} from "../../../models/current-or-realized-investment-record";

@Component({
    selector: "valumize-fund-current-and-realized-investments",
    templateUrl: "./fund-current-and-realized-investments.component.html",
    styleUrls: ["./fund-current-and-realized-investments.component.scss"]
})
export class FundCurrentAndRealizedInvestmentsComponent {

    @Input() fundId?: number;
    @Input() fundReportId?: number;
    @Input() fundValuationId?: number;
    @Input() dataSource: CurrentOrRealizedInvestmentRecord[] = [];
    @Input() columnDefinition: string[] = [];
    @Input() title?: string;

    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableGpIndication = CodeTableEnum.ASSET_GPINDICATION;
}
