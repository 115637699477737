<form [formGroup]="generalInformationForm">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">General Information</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="asset$ | async as asset">
            <mat-card-content class="detail-card-content">
                <div class="general-information-form">
                    <div class="key-value-pair-section">
                        <div class="key-value-pair-column">
                            <valumize-mergeable-select-multi-code
                                [label]="'Region'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableRegion
                                [codes]=asset.data.regions
                                [comparativeCodes]=baselineAsset.regions
                                [control]=generalInformationForm.controls.regions>
                            </valumize-mergeable-select-multi-code>
                            <valumize-mergeable-select-code
                                [label]="'Country'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableCountry
                                [code]=asset.data.country
                                [baselineCode]=baselineAsset.country
                                [control]=generalInformationForm.controls.country
                                [searchable]=true>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-input
                                [label]="'Activity Description'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [value]=asset.data.activityDescription
                                [baselineValue]=baselineAsset.activityDescription
                                [control]=generalInformationForm.controls.activityDescription>
                            </valumize-mergeable-input>
                            <valumize-mergeable-select-code
                                [label]="'Stage'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableStage
                                [code]=asset.data.stage
                                [baselineCode]=baselineAsset.stage
                                [control]=generalInformationForm.controls.stage>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-input
                                [label]="'GIC'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [value]=asset.data.gic
                                [baselineValue]=baselineAsset.gic
                                [control]=generalInformationForm.controls.gic>
                            </valumize-mergeable-input>
                        </div>
                        <div class="key-value-pair-column">
                            <valumize-mergeable-select-code
                                [label]="'GP Indication'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableGpIndication
                                [code]=asset.data.gpIndication
                                [baselineCode]=baselineAsset.gpIndication
                                [control]=generalInformationForm.controls.gpIndication>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-select-code
                                [label]="'Currency'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableCurrency
                                [code]=asset.data.currencyIso
                                [baselineCode]=baselineAsset.currencyIso
                                [control]=generalInformationForm.controls.currency
                                [searchable]=true>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-select-code
                                [label]="'ESG'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableESG
                                [code]=asset.data.esg
                                [baselineCode]=baselineAsset.esg
                                [control]=generalInformationForm.controls.esg>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-select-code
                                [label]="'Assessment'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableAssessment
                                [code]=asset.data.assessment
                                [baselineCode]=baselineAsset.assessment
                                [control]=generalInformationForm.controls.assessment>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-select-code
                                [label]="'FY End'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [codeTable]=codeTableFiscalYearEnd
                                [code]=asset.data.fiscalYearEnd
                                [baselineCode]=baselineAsset.fiscalYearEnd
                                [control]=generalInformationForm.controls.fiscalYearEnd>
                            </valumize-mergeable-select-code>
                            <valumize-mergeable-checkbox
                                [label]="'Publicly Listed'"
                                [isEditable]=isEditable
                                [isDataset]=isDataset
                                [value]=asset.data.publiclyListed
                                [baselineValue]=baselineAsset.publiclyListed
                                [control]=generalInformationForm.controls.publiclyListed>
                            </valumize-mergeable-checkbox>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="detail-card-actions">
                <button (click)="editMode()"
                        *ngIf="!isEditable"
                        [disabled]="(isEditDisabled$ | async)!"
                        color="primary"
                        data-test="edit-button"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="save()"
                        *ngIf="isEditable"
                        [disabled]="generalInformationForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <button (click)="cancel()"
                        *ngIf="isEditable"
                        color="primary"
                        data-test="cancel-button"
                        mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
        </ng-container>
    </mat-card>
</form>
