import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppRoutingModule} from "../app-routing.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";
import {DealOverviewPageComponent} from "./deal-overview-page/deal-overview-page.component";
import {SharedModule} from "../shared/shared.module";
import {StoreModule} from "@ngrx/store";
import {dealReducer, dealStoreFeatureKey} from "./store/deal.reducer";
import {SellerNewDialogComponent} from "./seller-new-dialog/seller-new-dialog.component";
import {DealDetailsPageComponent} from "./deal-details-page/deal-details-page.component";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {DealNewDialogComponent} from "./deal-new-dialog/deal-new-dialog.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {DealFundsComponent} from "./deal-details-page/deal-funds/deal-funds.component";
import {DealFundsManageDialogComponent} from "./deal-details-page/deal-funds-manage-dialog/deal-funds-manage-dialog.component";
import {DealCashflowsComponent} from "./deal-details-page/deal-cashflows/deal-cashflows.component";
import {DealCashflowTableComponent} from "./deal-details-page/deal-cashflows/deal-cashflow-table/deal-cashflow-table.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DealDetailsComponent} from "./deal-details-page/deal-details/deal-details.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {DealCashflowsGraphComponent} from "./deal-details-page/deal-cashflows-graph/deal-cashflows-graph.component";
import {DealReturnSummaryComponent} from "./deal-details-page/deal-return-summary/deal-return-summary.component";
import {DealCompaniesComponent} from "./deal-details-page/deal-companies/deal-companies.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {BASE_PATH} from "./generated-sources/api";
import {DealBubbleChartComponent} from "./deal-details-page/deal-companies/deal-bubble-chart/deal-bubble-chart.component";
import {DealsCompaniesTreemapComponent} from "./deal-details-page/deal-companies/deals-companies-treemap/deals-companies-treemap.component";
import {MatStepperModule} from "@angular/material/stepper";

@NgModule({
    declarations: [
        DealOverviewPageComponent,
        SellerNewDialogComponent,
        DealDetailsPageComponent,
        DealNewDialogComponent,
        DealFundsComponent,
        DealFundsManageDialogComponent,
        DealCashflowsComponent,
        DealCashflowTableComponent,
        DealDetailsComponent,
        DealCashflowsGraphComponent,
        DealReturnSummaryComponent,
        DealCompaniesComponent,
        DealBubbleChartComponent,
        DealsCompaniesTreemapComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature(dealStoreFeatureKey, dealReducer),
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatDividerModule,
        MatListModule,
        MatSidenavModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatSelectModule,
        MatExpansionModule,
        MatSortModule,
        MatPaginatorModule,
        MatStepperModule
    ],
    providers: [
        {provide: BASE_PATH, useValue: ".."}
    ]
})
export class DealModule {
}
