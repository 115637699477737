<div class="search-container">
    <form>
        <span matPrefix> </span>
        <input type="text" placeholder="Search" style="height: 30px" [formControl]="searchControl">
        <button matSuffix mat-button type="button" *ngIf="showSearchIcon">
            <mat-icon>search</mat-icon>
        </button>
    </form>

    <div *ngIf="isSearchActive">
        <div class="search-results-box" cdkScrollable>
            <mat-tab-group>

                <mat-tab label="All">
                    <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                        <ng-container *ngIf="searchCategories.includes('deals')">
                            <h3 class="tab-header">Deals</h3>
                            <mat-divider></mat-divider>
                            <mat-nav-list>
                                <a mat-list-item *ngFor="let result of deals$ | async" (click)="selectSearchResult(result.reference)">
                                    <span style="font-weight: bold">{{ result.title }}</span>
                                </a>
                            </mat-nav-list>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="searchCategories.includes('funds')">
                        <h3 class="tab-header">Funds</h3>
                        <mat-divider></mat-divider>
                        <mat-nav-list>
                            <a mat-list-item *ngFor="let result of funds$ | async" (click)="selectSearchResult(result.reference)">
                                <span style="font-weight: bold">{{ result.title }}</span>
                            </a>
                        </mat-nav-list>
                    </ng-container>

                    <ng-container *ngIf="searchCategories.includes('assets')">
                        <h3 class="tab-header">Assets</h3>
                        <mat-divider></mat-divider>
                        <mat-nav-list>
                            <a mat-list-item *ngFor="let result of assets$ | async" (click)="selectSearchResult(result.reference)">
                                <span style="font-weight: bold">{{ result.title }}</span> - <span style="font-size: small;">{{ result.teaser }}</span>
                            </a>
                        </mat-nav-list>
                    </ng-container>
                </mat-tab>

                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <mat-tab label="Deals">
                        <mat-nav-list>
                            <a mat-list-item *ngFor="let result of deals$ | async" (click)="selectSearchResult(result.reference)">
                                <span style="font-weight: bold">{{ result.title }}</span>
                            </a>
                        </mat-nav-list>
                    </mat-tab>
                </ng-container>
                <ng-container *ngIf="!targetSearch">
                    <mat-tab label="Funds">
                        <mat-nav-list>
                            <a mat-list-item *ngFor="let result of funds$ | async" (click)="selectSearchResult(result.reference)">
                                <span style="font-weight: bold">{{ result.title }}</span>
                            </a>
                        </mat-nav-list>
                    </mat-tab>

                    <mat-tab label="Assets">
                        <mat-nav-list>
                            <a mat-list-item *ngFor="let result of assets$ | async" (click)="selectSearchResult(result.reference)">
                                <span style="font-weight: bold">{{ result.title }}</span> - <span style="font-size: small;">{{ result.teaser }}</span>
                            </a>
                        </mat-nav-list>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </div>
    </div>
</div>



