import {Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {selectDealFundsOverview, selectIsAnyDealFormInEditMode, selectSelectedDealId} from "../../store/deal/deal.selectors";
import {CodeTableEnum} from "../../../shared/model/code";
import {DealFundsManageDialogComponent} from "../deal-funds-manage-dialog/deal-funds-manage-dialog.component";
import {map} from "rxjs";
import {DealFundActions} from "../../store/deal.actions";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {SellerPosition} from "../../models/seller-position";
import {DealFundRecord} from "../../models/deal-fund-record";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";

@Component({
    selector: "valumize-deal-funds",
    templateUrl: "./deal-funds.component.html",
    styleUrls: ["./deal-funds.component.scss"]
})
export class DealFundsComponent implements OnInit {

    dealId$ = this.store.select(selectSelectedDealId);
    dealFunds$ = this.store.select(selectDealFundsOverview);
    isEditDisabled$ = this.store.select(selectIsAnyDealFormInEditMode);

    isEditable = false;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;
    persistedSellerPositions: SellerPosition[] = [];

    constructor(
        private readonly store: Store,
        public dialog: MatDialog,
        private readonly formBuilder: FormBuilder
    ) {
    }

    dealFundsForm: FormGroup = this.formBuilder.group({
        bidPriceAdjustment: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]})
    });

    ngOnInit() {
        this.store.select(selectDealFundsOverview).pipe(map((dealFund) => {
            this.isEditable = dealFund.dealFundsIsEditable;
            this.persistedSellerPositions = dealFund.sellerPositions;

            const bidPriceAdjustmentRow = dealFund.tableDatasource.find(row => row.fundName.text === "Bid Price Adjustment");
            this.dealFundsForm.controls["bidPriceAdjustment"].patchValue(
                DecimalFormatPipe.transformFromMillionsNum(bidPriceAdjustmentRow?.bidPriceBuyerStake?.amount ?? undefined)
            );

            dealFund.tableDatasource
                .filter(dealFundRecord => dealFundRecord.fundName.text != null && !["Bid Price Adjustment", "Total"].includes(dealFundRecord.fundName.text))
                .forEach(dealFundRecord => {
                    this.dealFundsForm.addControl(
                        dealFundRecord.fundName.text + "BuyerStake",
                        this.formBuilder.control<number | null>(null, {validators: numberNotBiggerThan190000m()})
                    );
                    this.dealFundsForm.addControl(
                        dealFundRecord.fundName.text + "ExchangeRate",
                        this.formBuilder.control<number>(1, {nonNullable: true, validators: [Validators.required, numberNotBiggerThan190000m()]})
                    );
                    this.dealFundsForm.controls[dealFundRecord.fundName.text + "BuyerStake"]?.patchValue(
                        DecimalFormatPipe.transformFractionToPercent(dealFundRecord.sellerPosition.buyerStake.fraction ?? undefined)
                    );
                    this.dealFundsForm.controls[dealFundRecord.fundName.text + "ExchangeRate"]?.patchValue(dealFundRecord.exchangeRate.fraction);
                });
        })).subscribe();
    }

    openManageFundsDialog(dealId: number) {
        const dialogRef = this.dialog.open(DealFundsManageDialogComponent);
        dialogRef.componentInstance.dealId = dealId;
    }

    isEditableBidColumnCell(name: string): boolean {
        const editableRows = ["Bid Price Adjustment"];
        return editableRows.includes(name) && this.isEditable;
    }

    save(dealId: number, dealFundRecords: DealFundRecord[]) {
        if (this.dealFundsForm?.valid) {
            const form = this.dealFundsForm;
            const updatedSellerPositions: SellerPosition[] = [];

            const bidPriceAdjustmentValue = form.controls["bidPriceAdjustment"].value;
            const bidPriceAdjustment = DecimalFormatPipe.transformToMillionsNum(bidPriceAdjustmentValue);

            this.persistedSellerPositions.forEach(sp => {
                const dealFundRecord = dealFundRecords.find(df => df.fundValuationId === sp.fundValuationId);
                const buyerStake = form.controls[dealFundRecord?.fundName.text + "BuyerStake"]?.value;
                const exchangeRate = form.controls[dealFundRecord?.fundName.text + "ExchangeRate"]?.value;

                const updatedSellerPosition = {
                    ...sp,
                    buyerStake: {
                        ...sp.buyerStake,
                        fraction: DecimalFormatPipe.transformPercentToFraction(buyerStake)
                    },
                    fxRateDeal: {
                        ...sp.fxRateDeal,
                        fraction: exchangeRate
                    }
                };
                updatedSellerPositions.push(updatedSellerPosition);
            });

            this.store.dispatch(DealFundActions.save({dealId, sellerPositions: updatedSellerPositions, bidPriceAdjustment}));
        }
    }

    editMode = () => this.store.dispatch(DealFundActions.edit());

    cancel = () => this.store.dispatch(DealFundActions.cancel());

    notClickableInEditMode(): string {
        return this.isEditable ? "not-clickable" : "";
    }

    styleRowImportant(row: any): string {
        const importantRowTypes = ["Total"];
        return (importantRowTypes.includes(row.fundName.text)) ? "row-important" : "";
    }

    styleRowNotClickable(row: any): string {
        const notClickableRowTypes = ["Bid Price Adjustment", "Total"];
        return (notClickableRowTypes.includes(row.fundName.text)) ? "not-clickable" : "";
    }

    styleRowBorderTop(row: any): string {
        const borderTopRowTypes = ["Bid Price Adjustment"];
        return (borderTopRowTypes.includes(row.fundName.text)) ? "row-top-border" : "";
    }
}
