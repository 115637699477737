import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AccelexApiService} from "../generated-sources/api/accelex";
import {ImportInfo} from "../models/import-info";
import {ImportLog} from "../models/import-log";

@Injectable({
    providedIn: "root"
})
export class AccelexImportService {

    constructor(private readonly accelexImportService: AccelexApiService) {
    }

    public getImportFiles(): Observable<string[]> {
        return this.accelexImportService.getImportFiles();
    }

    public startImportJob(importInfo: ImportInfo): Observable<number> {
        return this.accelexImportService.startImportJob(importInfo);
    }

    public getImportLogs(): Observable<ImportLog[]> {
        return this.accelexImportService.getImportLogs();
    }
}
