import {Injectable} from "@angular/core";
import {DealApiService} from "../generated-sources/api";
import {Observable} from "rxjs";
import {Seller} from "../models/seller";

@Injectable({
    providedIn: "root"
})
export class SellerService {

    constructor(private readonly dealApiService: DealApiService) {
    }

    getSellers(): Observable<Seller[]> {
        return this.dealApiService.getSellers();
    }

    getSeller(id: number): Observable<Seller> {
        return this.dealApiService.getSeller(id);
    }

    saveSeller(seller: Seller) {
        return !!seller.id ? this.dealApiService.updateSeller(seller.id, seller) : this.dealApiService.createSeller(seller);
    }
}
