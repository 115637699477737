import {LOCALE_ID, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {AssetModule} from "./asset/asset.module";
import {FundModule} from "./fund/fund.module";
import {SharedModule} from "./shared/shared.module";

import {environment} from "src/environments/environment";

import * as assetValuationEffects from "./asset/store/asset-valuation/asset-valuation.effects";
import * as assetEffects from "./asset/store/asset/asset.effects";
import * as fundInvestmentEffects from "./fund/store/fund-investment/fund-investment.effects";
import * as fundReportEffects from "./fund/store/fund-report/fund-report.effects";
import * as fundValuationEffects from "./fund/store/fund-valuation/fund-valuation.effects";
import * as fundEffects from "./fund/store/fund/fund.effects";
import * as sharedEffects from "./shared/store/shared.effects";
import * as searchEffects from "./search/store/search.effects";
import * as dealEffects from "./deal/store/deal/deal.effects";
import * as sellerEffects from "./deal/store/seller/seller.effects";
import * as sellerPositionEffects from "./deal/store/seller-position/seller-position.effects";
import * as importEffects from "./import/store/import.effects";
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";
import {DecimalFormatPipe} from "./shared/pipes/decimal-format/decimal-format.pipe";
import {SearchModule} from "./search/search.module";
import {DealModule} from "./deal/deal.module";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {TimestampPipe} from "./shared/pipes/timestamp/timestamp.pipe";
import {ImportModule} from "./import/import.module";
import {CustomDateAdapter} from "./shared/adapter/custom-date-adapter";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        AssetModule,
        FundModule,
        SearchModule,
        DealModule,
        ImportModule,
        StoreModule.forRoot({}, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true
            }
        }),
        EffectsModule.forRoot(
            sharedEffects,
            assetEffects,
            assetValuationEffects,
            fundEffects,
            fundInvestmentEffects,
            fundValuationEffects,
            fundReportEffects,
            dealEffects,
            sellerEffects,
            sellerPositionEffects,
            searchEffects,
            importEffects
        ),
        environment.production ? [] : StoreDevtoolsModule.instrument()
    ],
    providers: [
        {provide: LOCALE_ID, useValue: "en-US"},
        {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: "dd/MM/yyyy"}},
        {provide: DecimalFormatPipe},
        {provide: TimestampPipe},
        {provide: MAT_DATE_LOCALE, useValue: "en-GB"},
        {provide: DateAdapter, useClass: CustomDateAdapter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
