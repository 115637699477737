<form [formGroup]="loginForm" (ngSubmit)="login()">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>

        <mat-card-content class="detail-card-content">
            <mat-form-field>
                <input type="text" matInput placeholder="Username" formControlName="username">
            </mat-form-field>

            <mat-form-field>
                <input type="password" matInput placeholder="Password" formControlName="password">
            </mat-form-field>

            <div class="error-message" *ngIf="!!errorMessage" data-test="login-warning">{{errorMessage}}</div>
        </mat-card-content>

        <div class="button">
            <button type="submit" mat-button>Login</button>
        </div>
    </mat-card>
</form>
