<div class="key-value-pair">
    <div class="key-value-item">{{label}}</div>
    <ng-container *ngIf="isDataset">
        <mat-chip (click)="overwriteValue()" [ngClass]="getChipColor()">{{baselineValue | traceableFormat}}</mat-chip>
    </ng-container>
    <div *ngIf="!isEditable" class="key-value-item">{{value | traceableFormat}}</div>
    <mat-form-field *ngIf="isEditable">
        <input matInput [formControl]="control" [matDatepicker]="datePicker">
        <mat-hint>dd/MM/yyyy</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
</div>
