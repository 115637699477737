<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Fund Cash Flows</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="selectedFundValuationIsInit$ | async as fundValuationIsInit; else selectFundValuationFirst">
        <mat-card-content class="detail-card-content">
            <mat-tab-group selectedIndex="1" *ngIf="fundValuationIsInit">
                <mat-tab label="Low" data-test="tab-low">
                    <valumize-fund-cashflow-table [scenarioType]="'LOW'" [displaySecondaryColumns]="displaySecondaryColumns">
                    </valumize-fund-cashflow-table>
                </mat-tab>
                <mat-tab label="Base" data-test="tab-base">
                    <valumize-fund-cashflow-table [scenarioType]="'BASE'" [displaySecondaryColumns]="displaySecondaryColumns">
                    </valumize-fund-cashflow-table>
                </mat-tab>
                <mat-tab label="High" data-test="tab-high">
                    <valumize-fund-cashflow-table [scenarioType]="'HIGH'" [displaySecondaryColumns]="displaySecondaryColumns">
                    </valumize-fund-cashflow-table>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions class="detail-card-actions" *ngIf="fundValuationIsInit">
            <ng-container *ngIf="displaySecondaryColumns; else showSecondaryColumns">
                <button mat-stroked-button
                        color="primary"
                        (click)="toggleSecondaryColumns()"
                        data-test="toggle-secondary-columns">
                    <mat-icon>visibility_off</mat-icon>
                    Hide Secondary Cash Flows
                </button>
            </ng-container>
            <ng-template #showSecondaryColumns>
                <button mat-stroked-button
                        color="primary"
                        (click)="toggleSecondaryColumns()"
                        data-test="toggle-secondary-columns">
                    <mat-icon>visibility</mat-icon>
                    Show Secondary Cash Flows
                </button>
            </ng-template>
        </mat-card-actions>
    </ng-container>
    <ng-template #selectFundValuationFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select or add a fund valuation to view fund cash flows.
        </mat-card-content>
    </ng-template>
</mat-card>
