import {Component, OnInit, ViewChild} from "@angular/core";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {selectDeals} from "../store/deal/deal.selectors";
import {DealListActions} from "../store/deal.actions";
import {DealNewDialogComponent} from "../deal-new-dialog/deal-new-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {map} from "rxjs/operators";

@Component({
    selector: "valumize-deal-overview-page",
    templateUrl: "./deal-overview-page.component.html",
    styleUrls: ["./deal-overview-page.component.scss"]
})
export class DealOverviewPageComponent implements OnInit {
    columns = ["dealId", "dealName", "closingDate"];
    dealDataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild("dealPaging") dealPaging!: MatPaginator;
    @ViewChild("dealSort") dealSort = new MatSort();

    constructor(private readonly store: Store, public dialog: MatDialog) {
        this.store.dispatch(DealListActions.open());
    }

    ngOnInit() {
        this.store.select(selectDeals).pipe(
            map(deals => {
                this.dealDataSource.data = deals;
                this.dealDataSource.paginator = this.dealPaging;
                this.dealDataSource.sort = this.dealSort;
                this.dealDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case "dealId":
                            return item.id;
                        case "dealName":
                            return item.projectName.text;
                        case "closingDate":
                            return item.closingDate;
                        default:
                            return item[property];
                    }
                };
                this.dealDataSource.sort.sort({
                    id: "dealName",
                    start: "asc",
                    disableClear: true
                });
            })
        ).subscribe();
    }

    openNewDealDialog() {
        this.dialog.open(DealNewDialogComponent);
    }
}
