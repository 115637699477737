import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AssetDetailsPageComponent} from "./asset/asset-details-page/asset-details-page.component";
import {AssetOverviewPageComponent} from "./asset/asset-overview-page/asset-overview-page.component";
import {FundDetailsPageComponent} from "./fund/fund-details-page/fund-details-page.component";
import {FundOverviewPageComponent} from "./fund/fund-overview-page/fund-overview-page.component";
import {AuthRouteBuilder} from "./shared/components/login/default-login/auth-route-builder";
import {DealOverviewPageComponent} from "./deal/deal-overview-page/deal-overview-page.component";
import {DealDetailsPageComponent} from "./deal/deal-details-page/deal-details-page.component";
import {ImportOverviewPageComponent} from "./import/import-overview-page/import-overview-page.component";

const routes: Routes = [
    {
        path: "deals/:dealId",
        component: DealDetailsPageComponent,
    },
    {
        path: "deals",
        component: DealOverviewPageComponent,
    },
    {
        path: "funds/:fundId",
        component: FundDetailsPageComponent
    },
    {
        path: "funds",
        component: FundOverviewPageComponent,
    },
    {
        path: "assets/:assetId",
        component: AssetDetailsPageComponent
    },
    {
        path: "assets",
        component: AssetOverviewPageComponent
    },
    {
        path: "import",
        component: ImportOverviewPageComponent
    },
    {
        path: "",
        redirectTo: "funds",
        pathMatch: "full"
    },
    ...AuthRouteBuilder.authRoutes()
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // set 'paramsInheritanceStrategy' to be able to access parent-route params and data in child routes
        paramsInheritanceStrategy: "always"
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
